import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from 'react-router-dom';

function MunicipalityDemoNavBar({display}) {
    const expand = "lg";
    const navigate = useNavigate();

    // it is for url
    const currentUrl = "/demo/municipalities?tab=";

    return (
        <div className={display} style={{width:"100%"}}>
            <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3">
                <Container fluid>
                    <Navbar.Brand href="/"><img src='/iq_ic.png' alt='IQLAND Logo' style={{ width: "32px" }} /></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-${expand}`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end flex-grow-1 pe-3">
                                <Nav.Link  disabled style={{color:"gray"}}>
                                Parcel Subdivision
                                </Nav.Link>
                                <Nav.Link onClick={()=> navigate(currentUrl + "zoning")}>
                                Check Zoning Parameters
                                </Nav.Link>
                                <Nav.Link onClick={()=> navigate(currentUrl + "plumbing")}>
                                Analyze Plumbing
                                </Nav.Link>
                                <Nav.Link onClick={()=> navigate(currentUrl + "electrical")}>
                                Analyze Electrical
                                </Nav.Link>
                                <Nav.Link onClick={()=> navigate(currentUrl + "mechanical")}>
                                Analyze Mechanical
                                </Nav.Link>
                                <Nav.Link onClick={()=> navigate(currentUrl + "arborist")}>
                                Analyze Arborist
                                </Nav.Link>
                                <Nav.Link disabled style={{color:"gray"}}>
                                Analyze Fire Code
                                </Nav.Link>
                                <Nav.Link disabled style={{color:"gray"}}>
                                Analyze Commercial Code
                                </Nav.Link>
                                <Nav.Link onClick={()=> navigate(currentUrl + "residential")}>
                                Analyze Residential Code
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </div>
    )
}

export default MunicipalityDemoNavBar