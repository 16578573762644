import React, { useEffect, useState } from 'react'
import UserNav from '../../components/user_nav/UserNav'
import './UserHome.css'
import { AutoGraphOutlined, BookmarkBorderOutlined, DonutSmallOutlined, HelpOutlineOutlined, PersonOutlineOutlined, PriceChangeOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { getFavoritePlans } from '../../components/user_service/UserService'
import PlanNew from '../dashboard_new/component/PlanNew'
import { LoadingSkeleton } from '../../components/skeleton_loading/SkeletonLoading'
import OtherNav from '../../components/other_nav/OtherNav'

function UserHome() {

  const navigate = useNavigate();

  // favoirte plans
  const [favoirtPlans, setFavoritePlans] = useState();

  function isFavorite(plan_id) {
    return favoirtPlans.some(favPlan => favPlan.id === plan_id);
  }

  // select plan state
  const [selectedPlan, setSelectedPlan] = useState({});

  async function getFavoritePlansOfUser() {
    const response = await getFavoritePlans();
    setFavoritePlans(response.data);
  }

  const [mobileSideBar, setMobileSidebar] = useState(false);

  useEffect(() => {
    try {
      const response = getFavoritePlansOfUser();
      // console.log(response);
      setFavoritePlans(response.data);
    }
    catch (error) {
      // console.log(error);
    }
  }, [])

  //hide mobile other nav bar out side click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (mobileSideBar && !event.target.closest('.other_nav')) {
        setMobileSidebar(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [mobileSideBar]);

  return (
    <div className='user_home'>
      <UserNav tab="h" handleBurgerClick={() => setMobileSidebar(!mobileSideBar)} />
      <div className='user_home__hero'>
        <div className='user_home__title'>Let's get Started</div>
        <div className='user_home__cards'>
          <div className='user_home__card'>
            <AutoGraphOutlined className='user_home__card_icon' />
            <div className='user_home__card_contents'>
              <div className='user_home__card_title'>Ask a question to Copilot</div>
              <p className='user_home__card_para'>Interact with our Copilot to seemlessly get answers to your questions about zoning, parcels, cost estimation and more!</p>
              <div className='user_home__card_button' onClick={() => navigate("/copilot")}>Explore Copilot</div>
            </div>
          </div>
          <div className='user_home__card'>
            <DonutSmallOutlined className='user_home__card_icon' />
            <div className='user_home__card_contents'>
              <div className='user_home__card_title'>Analyze parcels on dashboard</div>
              <p className='user_home__card_para'>Get details, equity estimations and suitable architectural plan recommendations for your parcels of interest in no time.</p>
              <div className='user_home__card_button' onClick={() => navigate("/dashboard")}>Explore Dashboard</div>
            </div>
          </div>
        </div>
      </div>

      <div className='user_home__recent'>
        <div className='user_home__recent_title'>Latest Favorites</div>
        <div className='user_home__recent_plans'>
          {
            favoirtPlans ? favoirtPlans.slice(0, 3).map((plan, index) => (
              <PlanNew key={index} plan={plan} fav={isFavorite(plan.id)} selectedPlan={false} selectPlan={setSelectedPlan} handleClick={() => { }} />
            ))
              :
              <div style={{ display: "flex", gap: "1rem", justifyContent: "space-between" }}>
                <LoadingSkeleton />
                <LoadingSkeleton />
                <LoadingSkeleton />
                <LoadingSkeleton />
              </div>
          }
          {
            favoirtPlans && favoirtPlans.length === 0 && <div>Favorite plans empty</div>
          }
        </div>
      </div>

      <div className='user_home__features'>
        <div className='user_home__features_title'>Features</div>
        <div className='user_home__cards' style={{ marginBottom: "2rem" }}>
          <div className='user_home__card' style={{ border: "1px solid var(--primary-clr)" }}>
            <PriceChangeOutlined className='user_home__card_icon' />
            <div className='user_home__card_contents' style={{ color: "black" }}>
              <div className='user_home__card_title'>Subscription</div>
              <p className='user_home__card_para' style={{ color: "rgba(0, 0, 0, 0.8)" }}>Subscribe to IQLAND to access powerful AI-driven urban planning tools, streamlining zoning, permits, and development processes. Gain real-time insights and ensure faster approvals for your projects.</p>
              <div className='user_home__card_button' style={{ border: "1px solid var(--primary-clr)" }} onClick={() => navigate("/settings/billing")}>Subscription</div>
            </div>
          </div>
          <div className='user_home__card' style={{ border: "1px solid var(--primary-clr)" }}>
            <BookmarkBorderOutlined className='user_home__card_icon' />
            <div className='user_home__card_contents' style={{ color: "black" }}>
              <div className='user_home__card_title'>Saved Plans (Favorites)</div>
              <p className='user_home__card_para' style={{ color: "rgba(0, 0, 0, 0.8)" }}>Easily access and manage your favorite pre-screened building plans for faster project initiation. Save time by selecting compliant designs tailored to your specific needs.</p>
              <div className='user_home__card_button' style={{ border: "1px solid var(--primary-clr)" }} onClick={() => navigate("/favorite")}>Favorites</div>
            </div>
          </div>
        </div>
        <div className='user_home__cards'>
          <div className='user_home__card' style={{ border: "1px solid var(--primary-clr)" }}>
            <PersonOutlineOutlined className='user_home__card_icon' />
            <div className='user_home__card_contents' style={{ color: "black" }}>
              <div className='user_home__card_title'>Profile</div>
              <p className='user_home__card_para' style={{ color: "rgba(0, 0, 0, 0.8)" }}>Manage your profile to track your projects, saved plans, and stay updated on platform features. Keep your information current for a seamless planning experience.</p>
              <div className='user_home__card_button' style={{ border: "1px solid var(--primary-clr)" }} onClick={() => navigate("/settings/personal")}>Profile</div>
            </div>
          </div>
          <div className='user_home__card' style={{ border: "1px solid var(--primary-clr)" }}>
            <HelpOutlineOutlined className='user_home__card_icon' />
            <div className='user_home__card_contents' style={{ color: "black" }}>
              <div className='user_home__card_title'>Support</div>
              <p className='user_home__card_para' style={{ color: "rgba(0, 0, 0, 0.8)" }}>For assistance, contact us at <a href="mailto:support@iqland.ai" style={{ color: "var(--primary-clr)" }}>support@iqland.ai</a>. We're here to help with any questions regarding your account or platform use.</p>
              <div className='user_home__card_button' style={{ border: "1px solid var(--primary-clr)" }} onClick={() => navigate("/contact")}>Contact us</div>
            </div>
          </div>
        </div>
      </div>
      <OtherNav isActive={mobileSideBar} setIsActive={setMobileSidebar}/>

    </div>
  )
}

export default UserHome