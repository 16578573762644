import axios from "axios";
import { getCsrfToken } from "../user_service/UserService";

const API_URL = process.env.REACT_APP_BACK_END_END_POINT;

// Helper function to handle errors
function handleError(error) {
  // Handle server error
  if (error.response) {
    // console.error("Server responded with an error:", error.response);
    throw new Error(error.response.data.error);
  }
  // handle network error
  else if (error.request) {
    // console.error("No response received:", error.request);
    throw new Error("Check your internet connection");
  }
  // handle any other error
  else {
    // console.error("Error in setting up the request:", error.message);
    throw new Error(error.message);
  }
}

export async function getSession() {
  try {
    const response = await axios.get(`${API_URL}/session/`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function login(username, password) {
  try {
    const response = await axios.post(
      `${API_URL}/login/`,
      {
        username: username.trim().toLowerCase(),
        password: password,
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function verifyOtp(username, otp) {
  try {
    const response = await axios.post(
      `${API_URL}/verify-otp/`,
      {
        otp: otp,
        username: username.trim().toLowerCase(),
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function registerWithEmail(
  first_name,
  last_name,
  email,
  password
) {
  try {
    const response = await axios.post(`${API_URL}/register/`, {
      first_name: first_name,
      last_name: last_name,
      username: email.trim().toLowerCase(),
      password: password,
      role: "Commercial",
      is_verified: false,
    });

    if (response.status === 409) {
      // 409 Conflict
      throw new Error("Email is already registered.");
    }
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function verifyEmail(email, otp) {
  try {
    const response = await axios.post(
      `${API_URL}/verify-email/`,
      {
        username: email.trim().toLowerCase(),
        otp: otp,
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function logout() {
  try {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
    await axios.post(`${API_URL}/logout/`, {}, { withCredentials: true });

    // Remove all cookies
    document.cookie.split(";").forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
    sessionStorage.removeItem("chatID");
    sessionStorage.removeItem("address");
  } catch (error) {
    handleError(error);
  }
}

export async function getSubscriptionPlans() {
  try {
    const response = await axios.get(`${API_URL}/subscription/packages/`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getSubscriptionDetails(subscriptionId) {
  try {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
    axios.defaults.withCredentials = true;
    const response = await axios.post(`${API_URL}/subscription/details/`, {
      subscription_id: subscriptionId,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function cancelSubscription(subscriptionId) {
  try {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
    axios.defaults.withCredentials = true;
    const response = await axios.post(`${API_URL}/cancel/subscription/`, {
      subscription_id: subscriptionId,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function checkSubscriptionNavigationStatus() {
  try {
    const response = await axios.get(`${API_URL}/check/active-subscription/`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function createPaymentIntent(price) {
  try {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
    axios.defaults.withCredentials = true;
    const response = axios.post(`${API_URL}/checkout/payment-intent/`, {
      stripe_price_id: price,
    });

    // console.log(response);

    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function subscribePlan(plan) {
  try {
    const response = await axios.post(`${API_URL}/event/checkout/session/`, {
      plan_type: plan,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

export async function getUserSetting() {
  try {
    const response = await axios.get(`${API_URL}/profile-info/`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// update profile setting /profile-info/
export async function updateProfileSetting(first_name, last_name, phone) {
  try {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.patch["X-CSRFToken"] = csrfToken;
    const response = await axios.patch(
      `${API_URL}/profile-info/`,
      {
        first_name: first_name,
        last_name: last_name,
        phone: phone,
      },
      { withCredentials: true }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// get other profile setting details
export async function getOtherProfileSetting() {
  try {
    const response = await axios.get(`${API_URL}/profile-settings/`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}

// patch other profile setting details
export async function patchOtherProfileSetting(data) {
  try {
    const csrfToken = await getCsrfToken();
    axios.defaults.headers.patch["X-CSRFToken"] = csrfToken;
    const response = await axios.patch(`${API_URL}/profile-settings/`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
}
