import React from 'react'

function TableColumn({columnName}) {

    //store column type
    let column = columnName;

    //it can be status or image or normal
    //image or status column needs information on top right corner
    if(columnName.toLowerCase() === "status"){
        column = <th className='info_column_q'><span className='q_mark' data-tooltip="Access the reference to the specific zoning code by clicking on the Pass/Fail result">?</span>Pass/Fail</th>
    }
    else if (columnName.toLowerCase() === "image"){
        column = <th className='info_column_q'><span className='q_mark' data-tooltip="View extracts of the site plan justifying the pass/fail result by clicking on the image icon">?</span>Image</th>
    }
    else{
        column = <th>{columnName}</th>
    }


  return column;
}

export default TableColumn