import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./SubscriptionPlans.css";
import { DoneOutlined, InfoOutlined } from "@mui/icons-material";
import { Switch, Tooltip } from "@mui/material";
import NavBar from "../../components/navbar/NavBar";
import {
  checkSubscriptionNavigationStatus,
  getSubscriptionPlans,
} from "../../components/auth/AuthService";
import { AuthContext } from "../../components/auth/AuthenticationContext";
import { LoadingSkeleton } from "../../components/skeleton_loading/SkeletonLoading";

const comparison = {
  headings: ["RESIDENTIAL"],
  details: [
    {
      item: "Copilot",
      col1: "Yes",
      tooltip:
        "Intuitively interact with a copilot on any question relative to your parcels of interest (zoning, cost, design recommendations, etc.)",

    },
    {
      item: "Interactive dashboards",
      col1: "Yes",
    },
    {
      item: "Residential appraisal estimations",
      col1: "Yes",
    },
    {
      item: "Parcel specific residential zoning interpretations",
      col1: "Yes",
    },
    // {
    //   item: "GIS enabled lot subdivision",
    //   col1: "Coming Soon",
    //   tooltip:
    //     "Get optimal lot subdivision proposals on your parcel of interest.",
    // },
    // {
    //   item: "Check the conformity of your plans",
    //   col1: "Coming Soon",
    //   tooltip:
    //     "Get feedback on your permit applications to ensure they get approved faster.",
    // },
    {
      item: "Parcel specific construction-ready plan recommendations",
      col1: "Yes",
    },
    {
      item: "Feasibility reports for new construction projects",
      col1: "Yes",
    },
    {
      item: "Share reports with stakeholders",
      col1: "Yes",
    },
    // {
    //   item: "Residential site plan and construction plan compliance checks",
    //   col1: "Coming Soon",
    // },
  ],
};

function SubscriptionPlans() {
  const navigate = useNavigate();

  const [checked, setChecked] = useState(true);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);



  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const getCellContent = (content) => {
    return content === "Yes" ? (
      <DoneOutlined
        sx={{
          fontSize: "24px",
          color: "#239B0D",
        }}
      />
    ) : (
      content
    );
  };

  const getMonthlyOrYearly = (prices) => {
    return prices.find(
      (item) => item.interval === (checked ? "year" : "month")
    );
  };

  const getPrice = (prices) => {
    const price = getMonthlyOrYearly(prices)?.unit_amount;
    return price;
  };

  const targetDate = "2024-07-15T14:00:00Z";

  const [plansLoading, setPlansLoading] = useState("true");
  async function getPlans() {
    try {
      const response = await getSubscriptionPlans();
      setPlansLoading(false);
      // console.log(response);
      setSubscriptionPlans(response.products);
    } catch (error) {
      // console.log(error);
      setPlansLoading(false);
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  const { user } = useContext(AuthContext);

  async function handleNavigation(plan) {
    try {
      if (user) {
        const response = await checkSubscriptionNavigationStatus();

        if (response.has_active_subscription) {
          navigate(`/settings/billing`);
        } else {
          navigate(`/checkout?id=${getMonthlyOrYearly(plan.prices)?.id}`);
        }
      }
      else {
        window.location.href = "/login";
      }
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <>
      <NavBar />
      <div
        className="plans_and_comparison"
        style={{ backgroundColor: "#f9f9f9" }}
      >
        <div className="plans">
          <div className="plans__heading">
            Choose the plan that fits your needs
          </div>

          <div className="toggle">
            <div className="toggle__label">Monthly</div>
            <Switch
              checked={checked}
              onChange={handleChange}
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#126342",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "rgba(18, 99, 66, 0.6)",
                },
              }}
            />
            <div className="toggle__label">Yearly</div>
          </div>

          {/* <h6 style={{ textAlign: "center" }}>
            Save 20% on your yearly subscription when paid upfront
          </h6> */}

          <div className="plans__container">
            {
              plansLoading && <LoadingSkeleton width="20rem" height="25rem" />
            }
            {
              subscriptionPlans?.map((plan, index) => (
                <div key={plan.id} className="plan">
                  <div className="plan__content">
                    <div className="plan__info">
                      <div className="plan__info__name">{plan.name}</div>
                      <div className="plan__info__description">
                        {plan.description}
                      </div>
                    </div>
                    <div className="plan__features">
                      {plan.features?.map((feature, featureIndex) => (
                        checked && featureIndex === 3 ? <div>&nbsp;</div> :
                          <div
                            key={`feature-${index}-${featureIndex}`}
                            className="plan__feature"
                          >
                            <div className="plan__feature__icon">
                              <DoneOutlined
                                sx={{
                                  fontSize: "14px",
                                  color: "#126342",
                                }}
                              />
                            </div>
                            <div className="plan__feature__text">
                              {feature.name}
                            </div>
                          </div>
                      ))}
                    </div>
                  </div>
                  <div className="plan__price">
                    <div className="plan__price_heading">
                      <div className="plan__price_heading__amount">
                        ${getPrice(plan.prices)}
                      </div>
                      <div className="plan__price_heading__duration">{`/ ${checked ? "Year" : "Month"}`}</div>
                    </div>
                    {/* <div className="plan__price_detail">{plan.priceDetail}</div> */}
                  </div>
                  <button
                    onClick={() => handleNavigation(plan)}
                    className="plan__link"
                  >
                    Buy Now
                  </button>
                </div>
              ))}
          </div>
        </div>

        <div className="comparison comparison__container">
          <div className="comparison__heading">Compare features and plans</div>
          <div className="comparison__table">
            <div className="table__header">
              <div className="table__first_col"></div>
              {comparison.headings.map((heading, index) => (
                <div key={`heading-${index}`} className="table__cell">
                  {heading}
                </div>
              ))}
            </div>
            {comparison.details.map((detail, index) => (
              <div key={`row-${index}`} className="table__row">
                <div className="table__first_col">
                  {detail.item} &nbsp;
                  {detail.tooltip && (
                    <Tooltip title={detail.tooltip}>
                      <InfoOutlined sx={{ width: "1rem", height: "1rem" }} />
                    </Tooltip>
                  )}
                </div>
                <div className="table__cell">{getCellContent(detail.col1)}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlans;
