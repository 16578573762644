import IQLogo from '../../components/util_component/iq_logo/IQLogo'
import './MunicipalityDemoTab.css'
import IQTab from '../../components/util_component/iq_tab/IQTab'
import { Link, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import MunicipalityDemoTable from './table/MunicipalityDemoTable'
import { arborist, electrical, mechanical, plumbing, structural, zoning } from './table/DemoTableDummyData'
import { useEffect, useState } from 'react'
import IQButton from '../../components/util_component/iq_button/IQButton'
import ViewPlan from '../../components/view_plan/ViewPlan'
import EmailShare from '../../components/email_share/EmailShare'
import ImageModal from './ImageModal'
import GeniusPopup from './component/genius_popup/GeniusPopup'
import MunicipalityDemoNavBar from './MunicipalityDemoNavBar'

// Responsive set
// Formated code
// Share popup need to check the code
function MunicipalityDemoTab() {

    // it is for url
    const currentUrl = "/demo/municipalities?tab=";

    // get tab from url
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tab = query.get("tab");

    // navigate to specific tab
    const navigate = useNavigate();

    // switching table based on url
    const [tableData, setTableData] = useState(zoning);

    // view plan popup
    const [viewPlanPopup, setViewPlanPopup] = useState(false);

    // Share popup
    const [sharePopup, setSharePopup] = useState(false);

    // handle image popup
    const [imageModal, setImageModal] = useState(false);

    //handle image popup image change
    const [imageOnImageModal, setImageOnImageModal] = useState(null);

    //genius button on off
    const [geniusButton, setGeniusButton] = useState("/images/copilot/1x/genius_off.png");

    // handle image click on the record component
    const handleClickImageOnRecord = (image) => {
        setImageOnImageModal(image);
        setImageModal(true);
    }

    // handle genius input box
    const [geniusPopup, setGeniusPopup] = useState(false);


    useEffect(() => {

        if (tab === "zoning") {
            setTableData(zoning)
        }
        if (tab === "plumbing") {
            setTableData(plumbing)
        }
        if (tab === "electrical") {
            setTableData(electrical)
        }
        if (tab === "mechanical") {
            setTableData(mechanical)
        }
        if (tab === "arborist") {
            setTableData(arborist)
        }
        if (tab === "residential") {
            setTableData(structural)
        }

    }, [tab])


    return (
        <div className='municipality_demo_tab'>

            {/* iqland left corner logo */}
            <div className='municipality_demo_tab__corner_logo hide_in_small'><Link to="/"><img style={{ width: "48px" }} src="/iq_ic.png" alt='iq' /></Link></div>

            {/* other area */}
            <div className='municipality_demo_tab__content'>

                {/* Nav bar for small device */}
                <MunicipalityDemoNavBar display={"hide_in_big"}/>

                {/* atlanta and iqland logos */}
                <div className='municipality_demo_tab__muni_logo_area'>
                    <IQLogo width="15%" />
                    <img style={{ height: "50%", maxHeight: "50px" }} src='/images/copilot/gray_bar.png' alt='l' />
                    <img className='atlanta_logo' style={{ width: "5%", minWidth: "75px" }} src='/images/copilot/atlanta_logo.png' alt='Atlanta Logo' onClick={() => navigate("/demo/municipalities/new")} />
                </div>

                {/* Address */}
                <div className='municipality_demo_tab__address'><h6>2101 Verbena Dr. Atlanta, Ga. 30314 | Permit number: BB2024-3746574</h6></div>



                {/* Tabs */}
                <div className='municipality_demo_tab__tabs_container  hide_in_small'>

                    {/* first line of tabs */}
                    <div className='municipality_demo_tab__tabs'>
                        <div><IQTab text="Parcel subdivision" disable={true} /></div>
                        <div><IQTab text="Check zoning parameters" active={tab === "zoning"} handleClick={() => navigate(currentUrl + "zoning")} /></div>
                        <div><IQTab text="Analyze plumbing" active={tab === "plumbing"} handleClick={() => navigate(currentUrl + "plumbing")} /></div>
                        <div><IQTab text="Analyze electrical" active={tab === "electrical"} handleClick={() => navigate(currentUrl + "electrical")} /></div>
                    </div>

                    {/* Second line of tabs */}
                    <div className='municipality_demo_tab__tabs'>
                        <div><IQTab text="Analyze mechanical" active={tab === "mechanical"} handleClick={() => navigate(currentUrl + "mechanical")} /></div>
                        <div><IQTab text="Analyze Arborist" active={tab === "arborist"} handleClick={() => navigate(currentUrl + "arborist")} /></div>
                        <div><IQTab text="Analyze Fire Code" disable={true} /></div>
                        <div><IQTab text="Analyze Commercial Code" disable={true} /></div>
                        <div><IQTab text="Analyze Residential Code" active={tab === "residential"} handleClick={() => navigate(currentUrl + "residential")} /></div>
                    </div>
                </div>


                {/* Table show area */}
                <div className='municipality_demo_tab__table_area' style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                    <MunicipalityDemoTable bodyData={tableData} handleImageClick={handleClickImageOnRecord} />
                </div>


                {/* view plan and share email buttons */}
                <div className='municipality_demo_tab__buttons'>
                    <IQButton text="View Plan" handleClick={() => setViewPlanPopup(true)} />
                    <IQButton text="Share" handleClick={() => setSharePopup(true)} />
                </div>

                {/* View plan popup*/}
                {viewPlanPopup && <ViewPlan handleClose={() => setViewPlanPopup(false)} />}


                {/* Share popup */}
                {
                    sharePopup && <EmailShare handleClose={() => setSharePopup(false)} />
                }

                {/* image column image view popup */}
                {
                    imageModal && <ImageModal image={imageOnImageModal} handleImageClick={() => setImageModal(false)} />
                }

                {/* floting genius button */}
                <div className='genius_btn'>
                    <img src={geniusButton}
                        alt="genius button"
                        onMouseOver={() => setGeniusButton("/images/copilot/1x/genius_on.png")}
                        onMouseLeave={() => setGeniusButton("/images/copilot/1x/genius_off.png")}
                        onClick={() => setGeniusPopup(true)}
                    />
                </div>

                {/* Genius input box popup */}
                {
                    geniusPopup && <GeniusPopup handleClose={() => setGeniusPopup(false)} />
                }

            </div>
        </div>
    )
}

export default MunicipalityDemoTab