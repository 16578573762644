import React, { useEffect, useState } from 'react';
import UserNav from '../../components/user_nav/UserNav';
import './CopilotNew.css';
import CoSideBar from './component/CoSideBar';
import CoMainArea from './component/CoMainArea';
import { getUserChatHistory } from '../../components/user_service/UserService';
import IQInformationPop from '../../components/util_component/iq_information_pop/IQInformationPop';

function CopilotNew() {
  const [titles, setTitles] = useState([]);
  const [infoPop, setInfoPop] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");

  const [isActive, setIsActive] = useState(false);

  const [mobileSideBar, setMobileSidebar] = useState(false);

  const handleMobileSidebar = () => {
    setMobileSidebar(!mobileSideBar);
    setIsActive(!isActive);
  };


  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await getUserChatHistory();
        setTitles(response.data);
      } catch (error) {
        setInfoMsg(error);
        setInfoPop(true);
      }
    };

    fetchChatHistory();
  }, []);

  //hide mobile side bar for out side click
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (mobileSideBar && !event.target.closest('.copilot_new__mobile_sidebar')) {
        setMobileSidebar(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [mobileSideBar]);

  const handleNewChat = (newChat) => {
    setTitles((prevTitles) => [newChat, ...prevTitles]);
  };

  return (
    <div className='copilot_new'>
      {/* Navigation  */}
      <div className='copilot_new__navigation'>
        <UserNav tab={"c"} handleBurgerClick={handleMobileSidebar} />
      </div>

      {/* Main area */}
      <div className='copilot_new__main'>
        {/* Copilot side bar */}
        <div className='copilot_new__sidebar'>
          <CoSideBar titles={titles} />
        </div>


        <div className={`copilot_new__mobile_sidebar ${isActive ? "copilot_new__mobile_sidebar_active_mobile" : ""}`}>
          <CoSideBar titles={titles} handleClose={handleMobileSidebar} />
        </div>


        {/* Copilot chat area */}
        <div className='copilot_new__content'>
          <CoMainArea onNewChat={handleNewChat} />
        </div>
      </div>

      {
        infoPop && <IQInformationPop message={infoMsg} setVisibility={setInfoPop} />
      }
    </div>
  );
}

export default CopilotNew;
