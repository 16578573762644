import React from 'react'
import ChatSource from '../../components/chatbot_msg_components/ChatSource'
import TypeWriter from '../../components/type_writer/TypeWriter'

function TulsaDemoSingle({message}) {
    return (
        < div className='co_single_chat__bot_chat' style={{marginBottom:"1rem", padding:"1rem"}}>
            <div className='co_single_chat__bot_profile' style={{width:"2rem", height:"2rem"}}>
                <img style={{ borderRadius: "50%" }} src={message.sent_by === "Bot" ? '/images/copilot_new/iq_profile_image.svg' : '/images/default_profile.png'} alt='iqland profile' />
            </div>
            <div className='co_single_chat__bot_message'>
                <div className='co_single_chat__answer_area'>

                    <div className='co_single_chat__answer_text' style={{fontSize:"0.8rem"}}>
                        <TypeWriter text={message.text} isNewMessage={true} />
                        {/* <ChatFormat msg={message.text} /> */}
                    </div>
                </div>
                {message.sources.length > 0 && <ChatSource sources={message.sources} />}

            </div>
        </div>
    )
}

export default TulsaDemoSingle