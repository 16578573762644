import { Card, CardContent, Skeleton, Typography } from "@mui/material";

export const LoadingSkeleton = ({ width, height = "10rem" }) => (
  <Card style={{ margin: '20px auto', height: height, minWidth: "14rem", width: width }}>
    <Skeleton variant="rectangular" />
    <CardContent>
      <Typography variant="h5">
        <Skeleton />
      </Typography>
      <Typography variant="body2">
        <Skeleton width="60%" />
      </Typography>
    </CardContent>
  </Card>
);


export const LoadingSkeletonText = ({width, height}) => (
  <>
    <Skeleton variant="text" width={width} height={height}/>
  </>
)