import React, { useState } from 'react'
import './MuniForm.css'
import IQButton from '../../util_component/iq_button/IQButton';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import axios from 'axios';

//Formated Code
//Mobile Responsive
function MuniForm({ handleHide, setEmail, setToken}) {


    // validation schema
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const validationSchema = Yup.object({
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string().email("Invalid email format").required('Required'),
        phone: Yup.string().matches(phoneRegExp, "Invalid phone number").required('Required'),
        municipality: Yup.string().required('Required'),
        rpr: Yup.string().required('Required'),
        cpr: Yup.string().required('Required')
    });



    // formik implementation
    // handle the form
    const contactInformationForm = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            municipality: '',
            rpr: '',
            cpr: '',
            intrested: 'yes',
            agree: 'disagree'
        },

        onSubmit: null,
        validationSchema,

    });

    //Change the initial value of isValid to false
    if (contactInformationForm.values.firstName === '') {
        contactInformationForm.isValid = false;
    }

    // handle to change page
    const [pageNumber, setPageNumber] = useState(1);
    const handlePageChange = () => {
        if (pageNumber < 3) {
            setPageNumber(pageNumber + 1);
        }
    }
    const handlePageBack = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }

    const [error, setError] = useState("");
    const [jwt, setJwt] = useState("");


    const handleSubmitData = async () => {
        if (pageNumber === 1) {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BACK_END_END_POINT}/register_municipality_client/`,
                    {
                        first_name: contactInformationForm.values.firstName,
                        last_name: contactInformationForm.values.lastName,
                        email: contactInformationForm.values.email,
                        phone: contactInformationForm.values.phone,
                        municipality: contactInformationForm.values.municipality,
                        residential_permits_qty: contactInformationForm.values.rpr,
                        commercial_permits_qty: contactInformationForm.values.cpr,
                        approving_permit: contactInformationForm.values.intrested,
                        agree: contactInformationForm.values.agree
                    })
                if(response.data.token){
                    setJwt(response.data.token)
                    setEmail(contactInformationForm.values.email);
                    handlePageChange();
                    setError("");
                }
            }
            catch (error) {
                setError(error.response.data.error);
            }
        }
        if(pageNumber === 2){
            handlePageChange();
        }

    }


    return (
        <div>
            <div className='muni_form'>
                <div className='muni_form__header'>
                    {
                        pageNumber === 1 &&
                        <div>
                            <h2>Contact information</h2>
                            <p>Please provide your contact information so we can stay connected.</p>
                        </div>
                    }
                    {
                        pageNumber === 2 &&
                        <div>
                            <h2>Welcome to IQLAND's demonstration tailored for municipalities.</h2>
                            <p></p>
                        </div>
                    }
                    {
                        pageNumber === 3 &&
                        <div>
                            <h2>Agreement</h2>
                            <p></p>
                        </div>
                    }
                </div>
                <div className='muni_form__content_area'>
                    {
                        pageNumber === 1 &&
                        <form onSubmit={contactInformationForm.handleSubmit}>
                            <div className='muni_form__form_line'>
                                <div className='muni_form__input'>
                                    <input type="text" value={contactInformationForm.values.firstName} onChange={contactInformationForm.handleChange} name='firstName' required />
                                    <span className='place_holder'>First Name<span style={{ color: "red" }}>*</span></span>
                                </div>
                                <div className='muni_form__input'>
                                    <input type="text" value={contactInformationForm.values.lastName} onChange={contactInformationForm.handleChange} name='lastName' required />
                                    <span className='place_holder'>Last Name<span style={{ color: "red" }}>*</span></span>
                                </div>
                            </div>
                            <div className='muni_form__form_line'>
                                <div className='muni_form__input'>
                                    <input type="email" value={contactInformationForm.values.email} onChange={contactInformationForm.handleChange} name='email' required />
                                    <span className='place_holder'>Email<span style={{ color: "red" }}>*</span></span>
                                </div>
                                <div className='muni_form__input'>
                                    <input type="tel" value={contactInformationForm.values.phone} onChange={contactInformationForm.handleChange} name='phone' required />
                                    <span className='place_holder'>Phone<span style={{ color: "red" }}>*</span></span>
                                </div>
                            </div>
                            <div className='muni_form__form_line'>
                                <div className='muni_form__input'>
                                    <input type="text" value={contactInformationForm.values.municipality} onChange={contactInformationForm.handleChange} name='municipality' required />
                                    <span className='place_holder'>Municipality<span style={{ color: "red" }}>*</span></span>
                                </div>
                            </div>
                            <div className='muni_form__form_line'>
                                <div className='muni_form__input'>
                                    <span className='label'>How many residential permits requests did your department receive in 2023?<span style={{ color: "red" }}>*</span></span>
                                    <input type="text" value={contactInformationForm.values.rpr} onChange={contactInformationForm.handleChange} name='rpr' required />

                                </div>
                            </div>
                            <div className='muni_form__form_line'>
                                <div className='muni_form__input'>
                                    <span className='label'>How many commercial permit requests did your department receive in 2023?<span style={{ color: "red" }}>*</span></span>
                                    <input type="text" value={contactInformationForm.values.cpr} onChange={contactInformationForm.handleChange} name='cpr' required />

                                </div>
                            </div>
                            <div className='muni_form__form_line'>
                                <div className='muni_form__input_radio'>
                                    <span className='label'>Would you be interested approving more permits for your municipality?<span style={{ color: "red" }}>*</span></span>
                                    <div style={{ display: "flex", gap: "1em", marginTop: "0.5em" }}>
                                        <fieldset
                                            id='intrested'
                                            onChange={contactInformationForm.handleChange}
                                            value={contactInformationForm.values.intrested}
                                        >
                                            <input type="radio" name='intrested' value="yes" defaultChecked />&nbsp;Yes
                                            &nbsp;
                                            <input type="radio" name='intrested' value="no" />&nbsp;No

                                        </fieldset>
                                    </div>

                                </div>
                            </div>
                        </form>
                    }
                    {
                        pageNumber === 2 &&
                        <div className='muni_form__page2'>
                            <p>
                                Our platform harnesses advanced AI to streamline and enhance urban planning,
                                ensuring that projects align seamlessly with local zoning and building regulations.
                                IQLAND is engineered to facilitate rapid plan reviews, significantly reducing the
                                time required for approvals, and fostering economic growth.
                            </p>
                            <p>
                                By integrating our customized AI,
                                municipalities can achieve unprecedented precision, speed, and compliance in plan assessments,
                                allowing for smarter, faster urban development.
                                Discover how IQLAND can transform your municipality's planning processes
                                into a model of efficiency and innovation, making your city a more
                                attractive destination for developers and investors alike.
                            </p>

                        </div>
                    }

                    {/* Showing agreement page */}
                    {
                        pageNumber === 3 &&
                        <div className='muni_form__page3'>
                            <p>By clicking "Agree" and using this demo provided by IQLAND, you, the user, hereby certify and agree to the following terms:</p>
                            <ol>
                                <li><b>Understanding of Demo Purpose:</b> You acknowledge that the demo is provided for evaluative purposes only to demonstrate the capabilities and functionalities of the IQLAND platform. The demo is not intended for commercial use and no monetary transactions should be executed based on the demo’s outputs.</li>
                                <li><b>Data Integrity:</b> You understand that while IQLAND makes reasonable efforts to provide accurate and up-to-date information, the data and outputs provided in this demo may not always reflect the most current legal, financial, or technological developments. IQLAND assumes no responsibility for the accuracy or reliability of any information provided during the demo.</li>
                                <li><b>Limited Liability:</b> You agree that IQLAND shall not be liable for any damages, including but not limited to direct, indirect, special, incidental, or consequential damages arising out of the use or inability to use the demo, even if IQLAND has been advised of the possibility of such damages.</li>
                                <li><b>No Warranty:</b> The demo is provided "as is" without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness</li>
                                <li><b>Confidentiality:</b> You agree to maintain the confidentiality of any sensitive information or proprietary technology you may be exposed to during the use of the demo and not to disclose such information without the prior written consent of IQLAND.</li>
                                <li><b>Feedback and Communication:</b> You agree to provide feedback regarding your experience with the demo, which IQLAND may use to improve future services. Any feedback you provide will be deemed non-confidential and non-proprietary.</li>
                                <li><b>Compliance with Laws:</b> You will comply with all applicable laws and regulations while using the demo, including but not limited to those related to data privacy and intellectual property rights.</li>
                            </ol>
                        </div>
                    }
                </div>

                {/* Enable agree disagree on agreement page */}
                {pageNumber === 3 &&
                    <div style={{ display: "flex", gap: "1em", marginTop: "-1em", paddingLeft: "1em" }}>
                        <fieldset
                            id='interested'
                            onChange={contactInformationForm.handleChange}
                            value={contactInformationForm.values.agree}
                        >
                            <input type="radio" name='agree' value="agree" defaultChecked={contactInformationForm.values.agree === "agree"} />&nbsp;Agree
                            &nbsp;
                            <input type="radio" name='agree' value="disagree" defaultChecked={contactInformationForm.values.agree === "disagree"} />&nbsp;Disagree

                        </fieldset>
                    </div>
                }
                {pageNumber === 1 && <div style={{color:"red"}}>{error}</div>}
                <div className='muni_form__button_area'>

                    {/* Remove back button on form page */}
                    {pageNumber !== 1 && <IQButton text="Back" handleClick={handlePageBack} />}

                    {/* Remove next button on agreement page */}
                    {pageNumber !== 3 && <IQButton text="Next" handleClick={handleSubmitData} disable={!contactInformationForm.isValid} />}

                    {/* Enable button on agreement page */}
                    {pageNumber === 3 && <IQButton text="Start Demo" handleClick={() => { handleHide(); setEmail(contactInformationForm.values.email); setToken(jwt) }} disable={contactInformationForm.values.agree === "agree" ? "" : true} />}
                </div>
            </div>
        </div>
    )
}

export default MuniForm