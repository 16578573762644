export const preQueries = [
    {
      id: 1,
      title: "Parcel subdivision",
      content: "Check parcel dimension",
    },
    {
      id: 2,
      title: "Check zoning parameters",
      content: "FAR, lot coverage, setbacks, height"
    },
    {
      id: 3,
      title: "Analyze plumbing",
      content: "International building code GA. revisions"
    },
    {
      id: 4,
      title: "Analyze electrical",
      content: "International Building Code Georgia Revisions"
    },
    {
      id: 5,
      title: "Analyze mechanical",
      content: "International building code GA. revisions"
    },
    {
      id: 6,
      title: "Analyze Arborist",
      content: "Check tree removal allowance"
    },
    {
      id: 7,
      title: "Analyze Fire Code",
      content: "International building code GA. revisions"
    },
    {
      id: 8,
      title: "Analyze Commercial Code",
      content: "International building code GA. revisions "
    },
    {
      id: 9,
      title: "Analyze Residential Code",
      content: "International building code GA. revisions "
    },
  ]