import React, { useState } from 'react'
import './TulsaChatbotInputBar.css'
import { useRef } from 'react';
import { useEffect } from 'react';
import GoogleAddressInput from '../../../../components/google_map/GoogleAddressInput';
import IQSpinner from '../../../../components/util_component/iq_spinner/IQSpinner';

function TulsaChatbotInputBar({ onSendMessage, isLoading, message, setMessage, prePrompt, setPreprompt }) {
  // const [message, setMessage] = useState('');
  const textareaRef = useRef(null);

  const [address, setAddress] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      if (prePrompt !== "") {
        onSendMessage(prePrompt + " for " + address);
        setAddress("")
      }
      else {
        onSendMessage(message);
      }
      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };


  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset height to auto to shrink the textarea if necessary
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight to fit content
  }, [message]);

  return (
    <div>

      {
        prePrompt !== "" && <GoogleAddressInput address={address} setAddress={setAddress} handleKeyPress={handleKeyPress} />
      }
      <div className="tulsa_chat-input-container">
        <textarea
          ref={textareaRef}
          value={prePrompt !== "" ? prePrompt : message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter address or Parcel ID"
          rows="1"
          disabled={prePrompt !== ""}
        />

        <div className='tulsa_send_button_area'>
          <button onClick={handleSend} className='tulsa_send_button'>
            {
              !isLoading ? <img src="/images/copilot_new/send_icon.svg" alt='send' /> : <IQSpinner />
            }
          </button>
        </div>
      </div>
    </div>
  );
};
export default TulsaChatbotInputBar