import React from 'react'
import './ImageModal.css'

function ImageModal({handleImageClick, image}) {
    const image_path = "/images/copilot/"
    return (
        <div className='image_modal'>
            <div onClick={handleImageClick} className='overlay'>
                <div className='image_modal__content'>
                    <img src={image_path+image} alt='Pic' />
                </div>
            </div>
        </div>
    )
}

export default ImageModal