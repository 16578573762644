import React, { useState } from 'react'
import './MunicipalityDemoTutorial.css'
import IQButton from '../../../components/util_component/iq_button/IQButton'
import { Image } from 'react-bootstrap'
import { tutorialData } from './TutorialData';
import IQCloseButton from '../../../components/util_component/close_button/IQCloseButton';

function MunicipalityDemoTutorial({ handleFinish }) {

    // It is for slide postition
    const [slidePosition, setSlidePosition] = useState(0);
    const imagePath = '/images/demo/';


    //change slide next
    const nextSlide = () => {
        if (slidePosition !== tutorialData.length - 1) {
            setSlidePosition(slidePosition + 1);
        }
    }

    //previous slide
    const prevSlide = () => {
        if (slidePosition !== 0) {
            setSlidePosition(slidePosition - 1);
        }
    }

    return (
        <div className='municipality_demo_tutorial'>

            <IQCloseButton handleClose={handleFinish} />

            <div className='municipality_demo_tutorial__content'>

                <h4 className='text-center'>Interactive Software Demonstration for City of Atlanta Inspections</h4>

                <h3>{tutorialData[slidePosition].title}</h3>

                <Image style={{width:"90%"}} src={imagePath + tutorialData[slidePosition].image} alt='municipality_demo_tutorial' fluid />

                <p style={{minHeight:"64px"}}>
                    {tutorialData[slidePosition].content}
                </p>

            </div>


            <div className='municipality_demo_tutorial__buttons'>
                {
                    slidePosition !== 0 && <IQButton text='Previous' handleClick={prevSlide} />
                }

                <
                    IQButton handleClick={() => { slidePosition === tutorialData.length - 1 ? handleFinish() : nextSlide() }}
                    text={slidePosition === 0 ? "Start Tour" : slidePosition === tutorialData.length - 1 ? "Finish" : "Next"}
                />
            </div>
        </div>
    )
}

export default MunicipalityDemoTutorial