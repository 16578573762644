import React, { useState } from 'react'
import './PlanNew.css'
import { deleteFavoritePlan, setFavoritePlan } from '../../../components/user_service/UserService'
import IQSpinner from '../../../components/util_component/iq_spinner/IQSpinner';
import { IconButton } from '@mui/material';
import { Favorite, FavoriteBorder, RemoveRedEyeOutlined } from '@mui/icons-material';

function PlanNew({ plan, fav, selectedPlan, selectPlan, handleClick}) {

  const [favorite, setFavorite] = useState(fav);
  const [loading, setLoading] = useState(false);

  const handleHeartClick = async () => {
    try {
      setLoading(true)
      if (favorite) {
        await deleteFavoritePlan(plan.id)
        setLoading(false)
        setFavorite(false)
      }
      else {
        await setFavoritePlan(plan.id)
        setLoading(false)
        setFavorite(true)
      }
    }
    catch (err) {
      // console.log(err)
    }
  }


  return (
    <div className='plan_new' style={selectedPlan ? { border: "1px solid var(--primary-clr)"} : {}}>
      <div onClick={() => {selectPlan(plan); handleClick()}} className='plan_new_container' style={selectedPlan ? { backgroundColor: "#E7EFEC"} : {}}>
        <div className='plan_new__image'>
          <img src={plan.image_link} alt="plan" loading='lazy' />
        </div>
        <div className='plan_new__description_area'>
          <div className='plan_new__title_area'>
            <h6 style={selectedPlan ? {color:"black"}:{}}>{plan.title}</h6>
            <div style={{ width: "0.5rem", height: "0.5rem" }}>&nbsp;</div>
          </div>
          <div className='plan_new__features'>
            <div className='icon_area' >
              <img src="/images/dashboard_new/bed_icon.svg" alt="bed" />
              <div className='f_count' style={selectedPlan ? {color:"black"}:{}}>{`${plan.bedrooms_count} Bed`}</div>
            </div>
            <div className='icon_area'>
              <img src="/images/dashboard_new/area_icon.svg" alt="area" />
              <div className='f_count' style={selectedPlan ? {color:"black"}:{}}>{`${plan.area_total} Sq ft`}</div>
            </div>
            <div className='icon_area'>
              <img src="/images/dashboard_new/bath_icon.svg" alt="bath" />
              <div className='f_count' style={selectedPlan ? {color:"black"}:{}}>{`${plan.bathrooms_full_count} Bath`}</div>
            </div>
            <div className='icon_area'>
              <img src="/images/dashboard_new/car_icon.svg" alt="car" />
              <div className='f_count' style={selectedPlan ? {color:"black"}:{}}>{`${plan.cars_capacity} ${plan.bathrooms_count > 1 ? "Cars" : "Car"}`}</div>
            </div>
          </div>
        </div>

      </div>
      {
        loading ?
          <div className='plan_heart' style={{ width: "1rem" }}><IQSpinner /></div>
          :
          <div className='plan_heart' onClick={() => handleHeartClick()}>{favorite? <Favorite sx={{color:"var(--primary-clr)"}}/>:<FavoriteBorder sx={{color:"var(--primary-clr)"}}/>}</div>
          //<img className='plan_heart' src={favorite ? "/images/dashboard_new/heart_active.svg" : "/images/dashboard_new/heart_deactive.svg"} alt="heart" onClick={() => handleHeartClick()} />
      }
      
      <IconButton className='plan_cart' aria-label="Example" sx={{width:"2.2rem", height:"2.2rem"}} href={plan.buy_url} target="_blank" rel="noreferrer">
        <RemoveRedEyeOutlined sx={{color:"var(--primary-clr)"}}/>
      </IconButton>
    </div>
  )
}

export default PlanNew