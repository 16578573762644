export const msg = `
### 1. Introduction

Welcome to IQLAND. By accessing or using our platform and services, you agree to be bound by these Terms and Conditions. Please read these terms carefully before using our platform. If you do not agree to these terms, you must discontinue your use of the platform.  

### 2. Scope of Services

IQLAND provides a suite of services, including GIS mapping, property appraisals, and third-party plan sourcing. We also offer a platform powered by AI and Language Learning Models (LLMs) that facilitates decision-making and data analysis for urban development projects. By using IQLAND, you acknowledge that our services are provided on an "as is" and "as available" basis.

### 3. GIS Mapping and Parcel Information

IQLAND’s GIS mapping systems provide parcel information such as size, dimensions, and location. However, this information does not replace the services of a licensed land surveyor. Any data provided by our GIS tools, including setback and zoning information, is approximate and may not be fully accurate. You should consult a licensed surveyor to confirm exact parcel boundaries and legal descriptions.

### 4. Third-Party Plans and Vendors

Any building plans available through IQLAND are sourced from third-party vendors. IQLAND has no affiliation with these vendors, and all customer service requests or modifications regarding these plans must be made directly with the third party. IQLAND is not responsible for errors, modifications, or issues arising from the use of third-party plans.

### 5. Property Appraisals

IQLAND provides property appraisals using the Uniform Residential Appraisal Report (URAR) template, but these appraisals are not state-certified. These appraisals are provided for informational purposes only and should not be used as official documents for lenders, buyers, or legal purposes. Always consult a licensed appraiser for official, state-compliant appraisals.

### 6. Subscription and Cancellation Policy

- **Yearly Subscriptions**: All payments for yearly subscriptions are final and non-refundable. If you cancel your subscription, it will remain active until the end of the subscription period, but no refund will be issued for the remaining term.
  
- **Monthly Subscriptions**: If you cancel a monthly subscription, your access will remain active until the end of the current billing cycle. No refunds will be issued for partial months of service.

### 7. Ownership of Data Entered into IQLAND’s LLM

By using IQLAND’s platform, including any interactions with our Language Learning Models (LLMs), you agree that any data, inputs, or content you submit becomes the property of IQLAND. This data may be used to improve our services, train our models, or for other business purposes, in accordance with our Privacy Policy.

You acknowledge that you do not retain any proprietary rights or ownership over the data submitted into the platform, including suggestions, feedback, or improvements.

### 8. Account Usage and Sharing

Each IQLAND account is for individual use only. Account sharing is prohibited. You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account. IQLAND reserves the right to suspend or terminate any account that violates these terms, especially if account-sharing is detected.

### 9. Privacy Policy and Data Use

IQLAND values your privacy and is committed to protecting your personal information. By using our platform, you consent to the collection, storage, and use of your personal data as outlined in our Privacy Policy.

- **Data Collection**:
  - Personal Information: We collect data such as your name, contact information, and billing details.
  - Platform Usage: We may collect data about how you interact with our services, including pages viewed, actions taken, and time spent on the platform.
  
- **Data Use**:
  - Your personal information is used for providing services, processing payments, improving user experience, and customer support.
  - We do not share or sell your personal data with third parties except when necessary for payment processing or service fulfillment.

### 10. Disclaimer and Limitation of Liability

While we strive to provide accurate data and high-quality services, IQLAND makes no guarantees regarding the accuracy, reliability, or completeness of any information provided. You agree that your use of our platform and services is at your own risk. IQLAND shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising from:
- The use of or inability to use the platform.
- Inaccuracies in data provided by our systems, property appraisals, or third-party services.
- Interactions with third-party vendors or service providers.

### 11. Intellectual Property

All content, features, and functionalities (including information, software, text, displays, images, and video) provided by IQLAND are the exclusive property of IQLAND or its licensors. You may not copy, modify, distribute, or create derivative works based on any part of our platform without explicit written permission from IQLAND.

### 12. Service Changes and Termination

IQLAND reserves the right to modify, suspend, or discontinue any aspect of the platform at any time without notice. If your account is terminated due to violation of these Terms, you will not be entitled to any refund of fees paid.

### 13. Modifications to Terms

IQLAND reserves the right to revise or modify these Terms and Conditions at any time. If we make changes, we will notify users through our platform or by email. Your continued use of the platform after such modifications will constitute your acceptance of the updated terms.

### 14. Governing Law

These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which IQLAND operates, without regard to its conflict of law principles.

### 15. Contact Information

If you have any questions or concerns about these Terms and Conditions, please contact us at support@iqland.com.
`