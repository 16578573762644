import React from 'react'
import './LandArchitect.css'
import NavBar from '../../components/navbar/NavBar'
import Footer from '../../components/footer/Footer'
import SectionOne from '../../components/section/SectionOne'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'
import { useNavigate } from 'react-router-dom'

function LanArchitect() {
    const navigate = useNavigate();
    return (
        <div className='lan_architect'>
            <NavBar />
            <SectionOne
                sectionOneData={
                    {
                        title: "IQLAND: Monetizing Your Architectural Vision",
                        subtitle: "Transform Your Designs into Continuous Revenue Streams",
                        paragraph: "Architects craft visions into tangible plans, often with specific projects in mind. IQLAND offers a unique opportunity to give new life to those single-use designs, turning them into a source of ongoing income. Our platform not only allows you to resell your plans but also positions them as exclusive offerings, connecting you with a broader audience of real estate developers and investors.",
                        image: "/images/architects/architect.png",
                        alt: "Image of Architect"
                    }
                }
            />
            <div className='lan_architect__ex_marketplace'>
                <div className='iqland_motto' style={{textAlign:"center"}}>Exclusive Marketplace</div>
                <div className='lan_architect__cards'>
                    <div className='lan_architect__card'>
                        <div className='lan_card__title'>Earn Commission </div>
                        <p className='lan_normal_text'>List your architectural plans on IQLAND and earn a commission for every transaction. It's a hassle-free way to generate income from designs that would otherwise remain dormant</p>
                    </div>
                    <div className='lan_architect__card' style={{ backgroundColor: "var(--secondary-clr)" }}>
                        <div className='lan_card__title' style={{ color: "var(--primary-clr)" }}>Exclusive Plans</div>
                        <p className='lan_normal_text'>Your plans will be featured prominently on our website, marked as exclusive offerings. This priority visibility ensures that your work reaches a wide audience</p>
                    </div>
                    <div className='lan_architect__card'>
                        <div className='lan_card__title'>Reach Untapped Clients</div>
                        <p className='lan_normal_text'>By featuring your plans on IQLAND, you connect with real estate professionals, developers, and investors previously beyond your network. Our platform bridges the gap between your designs</p>
                    </div>
                </div>

            </div>
            <div className='lan_home__why_iqland'>
                <div className='iqland_motto' style={{textAlign:"center"}}>Why IQLAND for Architects?</div>
                <div className='lan_home__why_iqland_cards'>
                    <div className='lan_home__why_iqland_card lan_architect__why_iqland_card'>
                        <img src='/images/lan_home/why_ico_2.png' alt='why' />
                        <div className='lan_card__title'>Showcase your Portfolio</div>
                        <p className='lan_normal_text'>Our platform is not just a marketplace; it's a showcase for your creativity and expertise. Highlight your portfolio to potential clients, demonstrating your versatility and vision.</p>
                    </div>
                    <div className='lan_home__why_iqland_card lan_architect__why_iqland_card'>
                        <img src='/images/lan_home/why_ico_3.png' alt='why' />
                        <div className='lan_card__title'>Streamline Licensing & Transaction </div>
                        <p className='lan_normal_text'>IQLAND simplifies the process of licensing your designs, managing transactions securely and efficiently, ensuring you receive your commissions without hassle.</p>
                    </div>
                    <div className='lan_home__why_iqland_card lan_architect__why_iqland_card'>
                        <img src='/images/lan_home/why_ico_4.png' alt='why' />
                        <div className='lan_card__title'>Feedback & Trends </div>
                        <p className='lan_normal_text'>Gain insights into the popularity of different design styles and trends based on marketplace activity. This feedback can inform your future projects, aligning your work with market demand.</p>
                    </div>
                </div>
            </div>

            <div className='lan_partner_iq'>
                <div className='lan_partner_iq__left'>
                    <div className='iqland_motto' style={{ color: "white", marginBottom:"2rem" }}>Partner with IQLAND</div>
                    <div className='lan_partner_items'>
                        <div className='lan_partner_item'>
                            <img src='/images/lan_home/part_ico_1.png' alt='icon' />
                            <div className='lan_partner_item_texts'>
                                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>Easy Onboarding </div>
                                <p className='lan_normal_text' style={{ color: "white" }}>Getting started is simple. Upload your designs, set your terms, and start earning. Our team is here to assist you every step of the way.</p>
                            </div>
                        </div>
                    </div>
                    <div className='lan_partner_items'>
                        <div className='lan_partner_item'>
                            <img src='/images/lan_home/part_ico_2.png' alt='icon' />
                            <div className='lan_partner_item_texts'>
                                <div className='lan_card__title' style={{ color: "var(--secondary-clr)" }}>No upfront costs </div>
                                <p className='lan_normal_text' style={{ color: "white" }}>Listing your plans on IQLAND is free. We only earn when you do, ensuring our interests are perfectly aligned.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img className='lan_partner_iq__right' src="/images/lan_home/partner2.png" alt="partner" />
            </div>

            <div className='lan_contact_us lan_container' style={{ paddingBottom: "0" }} >
                <div className='lan_title' style={{ color: "var(--primary-clr)" }}>Contact Us</div>
                <p className='lan_normal_text'>Embrace the opportunity to monetize your architectural designs with IQLAND. Our platform offers a seamless way to supplement your income, gain exposure, and connect with new clients. Join our community of architects who are expanding their reach and impact in the real estate industry.</p>
                <div className='lan_btn_gold' style={{ color: "var(--primary-clr)" }} onClick={() => navigate("/contact")}>Contact Us</div>
                <img src='/images/lan_home/partner.png' alt='partner' />
            </div>

            <JoinIqlandToday />
            <Footer />
        </div>
    )
}

export default LanArchitect