import React from 'react'
import './PreMsgCard.css'

function PreMsgCard({title, content, active}) {
  return (
    <div className={active?"pre_msg_card__active":"pre_msg_card"}>
        <h5>{title}</h5>
        <p>{content}</p>
    </div>
  )
}

export default PreMsgCard