import React, { useState } from 'react'
import "./SolutionSlideShow.css"
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
function SolutionSlideShow() {

    const [slideIndex, setSlideIndex] = useState(0);
    const navigate = useNavigate();

    const SlidesData = [
        {
            subTitle: "Real Estate Developers",
            para: "IQLAND revolutionizes real estate development by leveraging AI to offer instant feasibility assessments, precise construction cost estimations, and dynamic profitability analysis, streamlining the path to project success. Discover a world where potential is clearly defined and your vision for development is realized with unmatched efficiency.",
            handleClick: "/solutions/developers",
            image: "/images/lan_home/developer_new.png"
        },
        {
            subTitle: "Real Estate Agents",
            para: "Real estate agents play a key role in turning visions into reality for buyers and investors. IQLAND gives you the tools to “paint the picture” of undeveloped lots, offering insights into their future value and potential.",
            handleClick: "/solutions/agents",
            image: "/images/lan_home/agent_new.png"
        },
        {
            subTitle: "Architects",
            para: "Architects craft visions into tangible plans, often with specific projects in mind. IQLAND offers a unique opportunity to give new life to those single-use designs, turning them into a source of ongoing income. Our platform not only allows you to resell your plans but also positions them as exclusive offerings, connecting you with a broader audience of real estate developers and investors.",
            handleClick: "/solutions/architects",
            image: "/images/lan_home/architect_new.png"
        }
    ]

    const handleNext = () => {
        if (slideIndex < 2) {
            setSlideIndex(slideIndex + 1);
        } else {
            setSlideIndex(0);
        }
    }

    const handlePrev = () => {
        if (slideIndex > 0) {
            setSlideIndex(slideIndex - 1);
        } else {
            setSlideIndex(2);
        }
    }

    return (
        <div className='solution_slide_show'>
            <div className='solution_slides' style={{ transform: `translateX(-${(slideIndex / 3) * 100}%)`, }}>
                {
                    SlidesData.map((item, index) => (
                        <div key={index} className='solution_slide'>
                            <div className='solution_texts'>
                                <div className='solution_title' data-aos="fade-up">Solution</div>
                                <div className='solution_sub_title' data-aos="fade-up">{item.subTitle}</div>
                                <div className='solution_para' data-aos="fade-up">{item.para}</div>
                                <div className='lan_btn_gold' data-aos="fade-up" onClick={() => window.location.href = item.handleClick}>Explore More</div>
                            </div>
                            <div className='solution_image' data-aos="fade-right">
                                <img src={item.image} alt='architect' />
                            </div>
                        </div>
                    ))
                }

            </div>
            <div className='solution_slide_show_controls'>
                <NavigateBefore className='solution_slide_show_arrow' onClick={handlePrev} />
                <NavigateNext className='solution_slide_show_arrow' onClick={handleNext} />
            </div>
        </div>
    )
}

export default SolutionSlideShow