import React from 'react'
import './IQButton.css'

function IQButton({text, handleClick, disable}) {
  return (
    <button onClick={disable?null:handleClick} className={disable?"iq_button__deactivate":"iq_button"} >
        {text}
    </button>
  )
}

export default IQButton