import React from 'react'
import './IQLoading.css'

function IQLoading({message}) {
  return (
    <div className='iq_loading'>
        <div className='iq_loading__container'>
            <img src='/iq_ic.png' alt='iqland icon'/>
            <h5>{message}</h5>
        </div>
    </div>
  )
}

export default IQLoading