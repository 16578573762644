import React, { useState } from 'react'
import './ChatbotInputBar.css'
import { useRef } from 'react';
import { useEffect } from 'react';
import IQSpinner from '../../util_component/iq_spinner/IQSpinner';
import GoogleAddressInput from '../../google_map/GoogleAddressInput';

function ChatbotInputBar({ onSendMessage, isLoading, message, setMessage, prePrompt, setPreprompt}) {
  // const [message, setMessage] = useState('');
  const textareaRef = useRef(null);

  const [address, setAddress] = useState("");

  const handleSend = () => {
    if (message.trim()) {
      if(prePrompt !== "") {
        onSendMessage(prePrompt + " for " + address);
        setAddress("")
      }
      else{
        onSendMessage(message);
      }
      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  useEffect(() => {
    setMessage(prePrompt)
  }, [prePrompt])

  useEffect(() => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset height to auto to shrink the textarea if necessary
    textarea.style.height = `${textarea.scrollHeight}px`; // Set height to scrollHeight to fit content
  }, [message]);

  // return (
  //   <div >

  //     {
  //       prePrompt !== "" && <GoogleAddressInput address={address} setAddress={setAddress} handleKeyPress={handleKeyPress}/>
  //     }
  //     <div className="chat-input-container" >
  //       <textarea
  //         ref={textareaRef}
  //         value={prePrompt !== "" ? prePrompt : message}
  //         onChange={(e) => setMessage(e.target.value)}
  //         onKeyPress={handleKeyPress}
  //         placeholder="Ask a question to Copilot..."
  //         rows="1"
  //         disabled={prePrompt !== ""}
  //       />
  //       <button onClick={handleSend} className='send_button'>
  //         {
  //           !isLoading? <img src="/images/copilot_new/send_icon.svg" alt='send'/>: <IQSpinner/>
  //         }
  //       </button>
        
  //       {prePrompt !== "" && <div className='preprompt_close' onClick={() => setPreprompt("")}>x</div>}
  //     </div>
  //     {/* <div className='chat-input-container-bottom'>
  //       <div className='chat-input-file-attach-area'>
  //         <div className='chat_input_attachment'>
  //           <img src="/images/copilot_new/attachment_icon.svg" alt='attachment' />
  //           <div className='attach_text'>Attach Document</div>
  //         </div>
  //         <div className='or'>or</div>
  //         <h6>Drag & Drop file here</h6>
  //       </div>
  //       <button onClick={handleSend} className='send_button' style={{alignSelf:'flex-end'}}>
  //         {
  //           !isLoading? <img src="/images/copilot_new/send_icon.svg" alt='send'/>: <IQSpinner/>
  //         }
  //       </button>

  //     </div> */}
  //   </div>
  // );

  return (
    <div className='chatbot_input_bar_new'>

      {
        prePrompt !== "" && <GoogleAddressInput address={address} setAddress={setAddress} handleKeyPress={handleKeyPress} />
      }
      <div className="tulsa_chat-input-container">
        <textarea
          ref={textareaRef}
          value={prePrompt !== "" ? prePrompt : message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Ask a question to Copilot..."
          rows="1"
          disabled={prePrompt !== ""}
        />

        <div className='tulsa_send_button_area'>
          <button onClick={handleSend} className='tulsa_send_button'>
            {
              !isLoading ? <img src="/images/copilot_new/send_icon.svg" alt='send' /> : <IQSpinner />
            }
          </button>
          {prePrompt !== "" && <div className='preprompt_close' onClick={() => setPreprompt("")}>x</div>}
        </div>
      </div>
    </div>
  );


};
export default ChatbotInputBar