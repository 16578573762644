import React, { useState } from 'react'
import IQButton from '../../../../components/util_component/iq_button/IQButton'
import './VerificationPopup.css'
import { EmailOutlined } from '@mui/icons-material'

function VerificationPopup({ handleClick, email, token}) {


    const [code, setCode] = useState("");
    const [error, setError] = useState("");
    const handleResend = () => {
        setError("Code sent!")
    }

    return (
        <div className='verification_popup'>
            <div className='verification_popup__container'>
                <EmailOutlined sx={{ fontSize: "8vw", color: "var(--primary-clr)" }} />
                <h4 style={{fontWeight:"bold"}}>Please check your email</h4>
                <p>We've sent a code to {email}</p>
                <p className='error'>{error}</p>
                <input type="text" value={code} placeholder='Enter verification code' onChange={(e) => {setCode(e.target.value); setError("")}} />
                <p>Didn't get the code? <span onClick={handleResend} className='resend'>Resend</span></p>
                <IQButton handleClick={() => code === "1122"?handleClick():setError("invalid verification code")} disable={code === ""} text="Verify" />
            </div>
        </div>
    )
}

export default VerificationPopup