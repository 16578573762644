import React from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { ArrowRightOutlined } from '@mui/icons-material';
import './IQTextView.css';
function IQTextView({ label, text, handleClick }) {
    return (
        <div onClick={handleClick} style={{ width: '10rem', cursor: 'pointer' }}>
            <FormControl sx={{ flexGrow: 1, width: '100%' }} variant="outlined">
                <InputLabel sx={{cursor:"pointer"}} htmlFor="outlined-adornment-email">{label}</InputLabel>
                <OutlinedInput
                    id="outlined-adornment-email-iq"
                    type="text"
                    readOnly
                    sx={{
                        height: '2.5rem',
                        width: '100%',
                        cursor: 'pointer',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                        },

                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'primary.main',
                        },
                    }}
                    endAdornment={
                        <InputAdornment position="end">
                            <ArrowRightOutlined />
                        </InputAdornment>
                    }
                    label={label}
                    name="iq_text_view_1"
                    value={text}
                />
            </FormControl>
        </div>
    );
}

export default IQTextView;
