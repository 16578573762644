import React, { useState } from 'react'
import NavBar from '../../components/navbar/NavBar';
import FormPopup from '../../components/modal/FormPopup';

function OnBoarding() {
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal(!showModal);
    }
    return (
        <div style={{ display: "flex", width: "100vw", height: "100vh", flexDirection:"column" }}>
            <NavBar handleVisiblity={handleModal} />
            <iframe
                id="JotFormIFrame-241703951844055"
                title="Onboarding Form "
                onload="window.parent.scrollTo(0,0)"
                allowtransparency="true"
                allow="geolocation; microphone; camera; fullscreen"
                src="https://form.jotform.com/241703951844055"
                frameborder="0"
                scrolling="yes"
                style={{ width: "100%", height: "100%", border: "none", flexGrow: "1" }}
            >
            </iframe>
            <FormPopup show={showModal} handleClose={handleModal} />
            <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
            <script>window.jotformEmbedHandler("iframe[id='JotFormIFrame-241703951844055']", "https://form.jotform.com/")</script>

        </div>
    )
}

export default OnBoarding