export const msg = [
    {
        id: "931dfdcd-2f77-4842-a978-a60533b3c9de",
        text: "what can i build?",
        sources: [],
        sent_by: "User",
    },
    {
        id: "98708f45-a8ed-4cd3-9de3-00145bbba1cf",
        text: "Based on the zoning regulations for 8226 E 34th St, Tulsa, OK 74145, USA, you can build the following residential structures:\n\n1. **Single Household**:\n    - **Detached House**:\n        - *Permit Type*: Permitted\n        - *Minimum Lot Area*: 6,900 square feet\n        - *Minimum Lot Width*: 60 feet\n\n    - **Patio House**:\n        - *Permit Type*: Permitted\n        - *Minimum Lot Area*: 6,900 square feet\n        - *Minimum Lot Width*: 60 feet\n        - *Additional Requirements*: Refer to the provided source for detailed regulations\n\n2. **Two Households on Single Lot**:\n    - **Duplex**:\n        - *Permit Type*: Special Exception. Approval Required\n        - *Minimum Lot Area*: 6,900 square feet\n        - *Minimum Lot Area per Unit*: 3,450 square feet\n        - *Minimum Lot Width*: 60 feet\n        - *Minimum Open Space per Unit*: 2,500 square feet\n\nFor more detailed information, you can refer to the provided sources for each building type.",
        sources: [
            {
                name: "minimum non-arte...",
                source: "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE"
            },
            {
                name: "minimum interior sid...",
                source: "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE"
            },
            {
                name: "minimum rear setb...",
                source: "https://library.municode.com/ok/tulsa/codes/code_of_ordinances?nodeId=CD_ORD_TIT42ZOCO_CH5REDI_S5.030LOBURE"
            }
        ],
        sent_by: "Bot",
    },

    {
        id: "931dfdcd-2f77-4842-a978-a60533b3c9de",
        text: "What Will It Cost?",
        sources: [],
        sent_by: "User",
    },
    {
        id: "f4335511-e045-43e7-af71-8f75f3254cb4",
        text: "Based on the provided details for the property at 8226 E 34th St, Tulsa, OK 74145, the cost estimation for the construction is as follows:\n\n- **Total Construction Cost**: $915,300.00\n- **Construction Cost per Square Feet**: $61.02\n\nSince the building area is 15,000 sqft and the construction cost per square feet is $61.02, the total cost for constructing a building with the specified features can be calculated as follows:\n\n| Description                  | Details       |\n|------------------------------|---------------|\n| Building Area                | 15,000 sqft   |\n| Build Quality                | BRONZE        |\n| Story Count                  | 1             |\n| Garage Area                  | 0 sqft        |\n| Basement Area                | 0 sqft        |\n| Basement Quality             | No            |\n| Bathrooms                    | 1             |\n| Kitchen                      | 1             |\n| Wall Type                    | Brick Veneer  |\n\nTherefore, the estimated cost for constructing the specified building with the given features would be:\n\n- **Estimated Total Cost**: $915,300.00\n- **Cost per Square Feet**: $61.02\n\nPlease note that the provided cost estimation is based on the specific details provided and may vary depending on additional factors or specific requirements not included in the initial information.",
        sources: [],
        sent_by: "Bot",
    },
    {
        id: "931dfdcd-2f77-4842-a978-a60533b3c9de",
        text: "What Will I Make?",
        sources: [],
        sent_by: "User",
    },
    {
        id: "f4335511-e045-43e7-af71-8f75f3254cb4",
        text: "Based on the property address and recent comparable sales, if you were to build a 2,500 square foot home of average quality, you could potentially make a profit of around $125,000. Please note, this estimate does not account for land acquisition costs or additional transactional expenses such as permits, taxes, and financing. If you'd like, I can help you include specific costs like architectural plans, zoning fees, or other project-specific expenses.",
        sources: [],
        sent_by: "Bot",
    },
    {
        id: "931dfdcd-2f77-4842-a978-a60533b3c9de",
        text: "Will the City Approve It?",
        sources: [],
        sent_by: "User",
    },
    
    {
        id: "f4335511-e045-43e7-af71-8f75f3254cb4",
        text: "Based on the zoning regulations for the property located at 8226 E 34th St, Tulsa, OK 74145, the construction of a **duplex** would require a **Special Exception** approval. The minimum lot area required for a duplex is **6,900 square feet**, with a minimum lot width of **60 feet**. Additionally, there is a minimum open space requirement of **2,500 square feet per unit** for a duplex in the RS-3 zoning district.",
        sources: [],
        sent_by: "Bot",
    },


]