import { useNavigate } from 'react-router-dom';
import './ResponsePage.css'


export const SuccessfullyPurchased = () => {

    const navigate = useNavigate();
    return (
        <div className="page success_purchase">
            <h1>Purchase Successful!</h1>
            <p>Thank you for your purchase.</p>
            <button onClick={() => navigate("/")}>Go to Home</button>
        </div>
    );
};


