import React from 'react'
import './SectionOne.css'

function SectionOne({ sectionOneData, right }) {

    const changeDirection = right ? 'section_one direction' : 'section_one';


    return (
        <section className='section-top'>
            <div className={changeDirection}>
                <div className='texts'>
                    <h1 className='title' data-aos="fade-right">{sectionOneData.title}</h1>
                    <h4 className='subtitle' data-aos="fade-right"  data-aos-duration="1500">{sectionOneData.subtitle}</h4>
                    <p className='paragraph' data-aos="fade-up"  data-aos-duration="1700">{sectionOneData.paragraph}</p>
                    {
                        sectionOneData.paragraph2 &&
                        <p className='paragraph' data-aos="fade-up"  data-aos-duration="1700">{sectionOneData.paragraph2}</p>
                    }
                </div>
                <div className='image' data-aos="fade-left"  data-aos-duration="1200">
                    <img src={sectionOneData.image} alt={sectionOneData.alt} />
                </div>
            </div>
        </section>
    )
}

export default SectionOne