import React from 'react'
import './IQTab.css'

function IQTab({text, disable, active, handleClick}) {

  return (
    <button onClick={disable?null:handleClick} className={`iq_tab ${active? "iq_tab__active": ""} ${disable? "iq_tab__disable": ""}`}>
        {text}
    </button>
  )
}

export default IQTab