import React from 'react'
import './LanAgent.css'
import NavBar from '../../components/navbar/NavBar'
import SectionOne from '../../components/section/SectionOne'
import Footer from '../../components/footer/Footer'

function LanAgent() {
    return (
        <div className='lan_agent'>
            <NavBar />
            <SectionOne
                sectionOneData={
                    {
                        title: "Empowering Real Estate Agents with Visionary Insights",
                        subtitle: "Unlocking the Potential of Every Parcel",
                        paragraph: "Real estate agents play a key role in turning visions into reality for buyers and investors. IQLAND gives you the tools to “paint the picture” of undeveloped lots, offering insights into their future value and potential.",
                        paragraph2: "Use IQLAND to uncover new listing opportunities with ease. Find property owners’ names, contact details, and key parcel information in one place, or ask our Copilot to highlight opportunities in your zip code. Simplify your search and grow your portfolio effortlessly.",
                        image: "/images/lan_home/agent.png",
                        alt: "Image of Architect"
                    }
                }
                right={true}
            />

            <div className='land_agent__vision'>
                <div className='land_agent__secion_title' data-aos="fade-up">Accurate Property Valuations for Confident Sales</div>
                <div className='land_agent__vision_points'>
                    {/* <div className='land_agent__vision_item'>
                        <div><span>Visualize Development Opportunities:</span> With IQLAND, you can illustrate the possibilities of any lot, answering questions like, "What can be built here?" Dive deep into zoning regulations, allowable uses, and potential development plans tailored to maximize ROI.</div>
                    </div> */}
                    <div className='land_agent__vision_item' data-aos="zoom-in"  data-aos-duration="1200">
                        <div>Stand out from the competition and close more deals with IQLAND. Provide clients with instant, accurate (non-certified) appraisal reports, including data on school districts, neighborhood trends, and how upgrades impact value. Gain insights in minutes and position yourself as the go-to agent with actionable knowledge at your fingertips.</div>
                    </div>
                </div>
            </div>

            <div className='land_agent__features'>
                <div className='land_agent__secion_title' data-aos="fade-up"  data-aos-duration="1000">Smart Investment Recommendations to Maximize ROI</div>
                <div className='land_agent__features_content '>
                    <img className='land_agent__features_image' src="/images/lan_agent/dashboard_agent.png" alt='dashboard' data-aos="fade-left"  data-aos-duration="1200"/>
                    <div className='land_agent__features_points'>
                        {/* <div className='land_agent__features_item'>
                            <span>Extensive Parcel Database Access: </span>
                            Our vast database covers an impressive 98% of the U.S., providing detailed information on zoning, property boundaries, and historical data essential for painting a comprehensive picture of each parcel.
                        </div>
                        <div className='land_agent__features_item'>
                            <span>Dynamic Feasibility Analysis: </span>
                            Instantly conduct feasibility assessments for parcels, assessing their development potential with high accuracy, empowering you to advise clients with confidence.
                        </div>
                        <div className='land_agent__features_item'>
                            <span>Accurate Construction Cost Estimations: </span>
                            Equip yourself with precise cost estimations, factoring in regional variations in materials and labor, to provide clients with realistic budgeting for their projects.
                        </div> */}
                        <div className='land_agent__features_item' data-aos="fade-down"  data-aos-duration="1500">
                            {/* <span>Value After Completion Metrics: </span> */}
                            IQLAND’s platform goes beyond valuations by offering tailored upgrade recommendations. Our data-backed suggestions indicate which renovations can boost property values, empowering your clients to invest strategically. Take the guesswork out of home improvement advice, helping clients see the highest returns on their investments.
                        </div>
                    </div>
                </div>
            </div>

            <div className='lan_agent__enhance'>
                <div className='lan_agent__enhance_content' data-aos="fade-right" >
                    <div className='land_agent__secion_title'>Comprehensive Property Information at Your Fingertips</div>
                    <div className='lan_agent__enhance_items'>
                        {/* <div className='lan_agent__enhance_item'><span>Tailored Architectural Plans: </span>
                            Access a library of architectural plans that align with specific parcels, enabling you to present clients with tangible development concepts that resonate with their goals.
                        </div> */}
                        <div className='lan_agent__enhance_item' style={{marginBottom:"1rem"}}>
                            {/* <span>AI-Enhanced Insights: </span> */}
                            Easily access detailed property data and neighborhood information to provide clients with a full understanding of potential investments. Our platform supports everything from historical property data to zoning and market trends, so you can offer insights that matter in real-time, making your expertise stand out.
                        </div>
                    </div>
                </div>
                <img className='lan_agent__enhance_img' src="/images/lan_agent/enhance.png" alt='iqland' data-aos="fade-left"  data-aos-duration="1200"/>
            </div>

            <div className='lan_agent__free_trial'>
                <div className='lan_agent__free_trial_top' data-aos="fade-up"  data-aos-duration="1000">
                    <div className='land_agent__secion_title' style={{ color: "var(--secondary-clr)", marginBottom: "1rem" }}>Enhanced Client Engagement and Visualization Tools</div>
                    <div className='lan_agent__free_trial_items'>
                        <div className='lan_agent__free_trial_item'>
                            {/* <span>Enhanced Client Engagement and Visualization Tools</span> */}
                        Help clients envision their future properties with IQLAND’s suite of visualization tools. From zoning overlays to predictive models for property modifications, our tools bring project possibilities to life, assisting you in communicating a property's full potential. This feature enables clients to make informed decisions, accelerating the buying process.
                        </div>
                        {/* <div className='lan_agent__free_trial_item'><span>Subscribe for Unmatched Insight: </span>
                            For just $395 per month, unlock the full suite of IQLAND's features, ensuring you have the tools to inspire and inform your clients with visionary project insights.
                        </div> */}
                    </div>
                </div>
                <div className='lan_agent__free_trial_bottom' data-aos="zoom-in"  data-aos-duration="1000">
                    <div className='land_agent__secion_title' style={{ color: "var(--secondary-clr)", marginBottom: "1rem" }}>Start your free 7-day trial</div>
                    <div className='lan_agent__free_trial_items'>
                        <div className='lan_agent__free_trial_item'>
                            Become the go-to real estate agent for clients looking to invest in development projects. With IQLAND, you can provide unmatched insights, from potential development visualizations to predictive valuations, setting the foundation for informed decisions and successful investments.
                        </div>
                    </div>
                    <div className='lan_secondary_button' onClick={() => {window.location.href = "/pricing"}} style={{backgroundColor:"var(--secondary-clr)", marginTop:"1rem"}}>Start Free Trial</div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default LanAgent