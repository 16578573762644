import { CloseOutlined } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import './OtherNav.css'

function OtherNav({isActive, setIsActive}) {
    const navigate = useNavigate();
    return (
        <div className={`other_nav ${isActive? "other_nav__mobile_active":""}`}>
            <div className='co_sidebar__mobile'>
                <ul>
                    <li className='co_sidebar__mobile__close' onClick={() => setIsActive(!isActive)}><CloseOutlined /></li>
                    <li onClick={() => navigate("/user/home")}>Home</li>
                    <li onClick={() => navigate("/copilot")}>Copilot</li>
                    <li onClick={() => navigate("/dashboard")}>Dashboard</li>
                    <li onClick={() => navigate("/reports")}>Reports</li>
                </ul>
            </div>
        </div>
    )
}

export default OtherNav