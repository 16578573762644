import { useState, useEffect } from "react";
import React from 'react';
import ChatFormat from "../chatbot_msg_components/ChatFormat";

function TypeWriter({ text, isNewMessage }) {
    const [displayText, setDisplayText] = useState('');
    const [wordIndex, setWordIndex] = useState(0);

    useEffect(() => {
        if (isNewMessage) {
            const words = text.split(' ');

            if (wordIndex < words.length) {
                const interval = setInterval(() => {
                    setDisplayText(prevText => prevText + (prevText ? ' ' : '') + words[wordIndex]);
                    setWordIndex(prevIndex => prevIndex + 1);
                }, 50); // Adjust the interval for speed

                return () => clearInterval(interval);
            }
        } else {
            setDisplayText(text);
        }

    }, [wordIndex, isNewMessage, text]);

    useEffect(() => {
        // Reset display text and word index when a new message comes in
        if (isNewMessage) {
            setDisplayText('');
            setWordIndex(0);
        }
    }, [isNewMessage, text]);

    return <span><ChatFormat msg={displayText} /></span>;
}

export default TypeWriter;
