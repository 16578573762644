export const customerReviewData = [
    {
        name: "Alex T.",
        review: "Thanks to IQLAND, we’re able to spot profitable opportunities in record time. It's truly revolutionized our workflow.",
        job: "Real Estate Developer",
        profile:"alex.jpg"
    },
    {
        name: "Sarah D.",
        review: "The Plan Matchmaking feature is a game changer. It’s saved us months of planning and thousands in design costs.",
        job: "Investor",
        profile:"sarah.jpg"
    },
    {
        name: "Michael C.",
        review: "IQLAND’s cost estimator has made budgeting a breeze. I can’t imagine working on a project without it now.",
        job: "Property Developer",
        profile:"michael.jpg"
    }
]