import React, { useState } from 'react'
import { sendReportToEmail } from '../../../components/user_service/UserService';
import { Alert, Snackbar } from '@mui/material';
import IQSpinner from '../../../components/util_component/iq_spinner/IQSpinner';

function ShareReport({reportID, setShareModal}) {
    const [email, setEmail] = useState("");

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState("");
    const [toastStatus, setToastStatus] = useState("success");
    const [loading, setLoading] = useState(false);

    const sendReportMail = async (report_id, email) => {
        try {
            if(email === ""){
                setToastStatus("warning")
                setToastText("Please enter email address")
                setToastShow(true)
                return;
            }
            if(email.indexOf("@") === -1 || email.indexOf(".") === -1 || email.indexOf("@") > email.lastIndexOf(".")){
                setToastStatus("warning")
                setToastText("Please enter valid email address")
                setToastShow(true)
                return;
            }
            
            setLoading(true);
            await sendReportToEmail(report_id, email);
            // console.log("mail sent");
            setLoading(false);
            setEmail("")

            setToastStatus("success")
            setToastText(`Report sent to ${email}`)
            setToastShow(true)

        }
        catch (err) {
            // console.log(err.message)
            setLoading(false);
            setToastStatus("warning")
            setToastText("Somthing went wrong!")
            setToastShow(true)
        }
    }

    return (
        <div className='share_modal__container' style={{zIndex:"9999999"}}>
            <div className='share_modal'>
                <div className='share_modal__title'>Share Your report</div>
                <input type="email" placeholder='Enter email address' value={email} onChange={(e) => setEmail(e.target.value)} required />
                <div className='share_modal__buttons'>
                    <div className='lan_primary_button' onClick={() => { sendReportMail(reportID, email);}}>
                        Share {
                            loading && <IQSpinner/>
                        }
                    </div>
                    <div className='lan_secondary_button' onClick={() => { setShareModal(false);}}>Cancel</div>
                </div>

            </div>

            <Snackbar
                open={toastShow}
                autoHideDuration={2000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ShareReport