import React, { useEffect, useState } from 'react';
import './CountdownTimer.css';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const timerComponents = [];

    // Ensure all time units are displayed even if their values are zero
    const timeUnits = ['days', 'hours', 'minutes', 'seconds'];

    timeUnits.forEach((interval, index) => {
        timerComponents.push(
            <div key={interval} className="countdown-timer__element">
                <div className="countdown-timer__time_and_text">
                    <div className="time">{String(timeLeft[interval]).padStart(2, '0')}</div>
                    <div className="time_text">{interval}</div>
                </div>
                {index < timeUnits.length - 1 && <div className="colon">:</div>}
            </div>
        );
    });

    return (
        <div className="countdown-timer">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
    );
};

export default CountdownTimer;
