import React from 'react';
import './CoSideBar.css';
import { CloseOutlined, MoreVertOutlined, ReviewsOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { format, isToday, isYesterday, subDays, subMonths, isAfter, isSameMonth, isSameYear } from 'date-fns';

function CoSideBar({ titles, handleClose }) {
  // get tab from url
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const tab = query.get("chat");

  const navigate = useNavigate();

  const today = new Date();
  const yesterday = subDays(today, 1);
  const sevenDaysAgo = subDays(today, 7);
  const thirtyDaysAgo = subDays(today, 30);

  const groupChatsByDate = (chats) => {
    const groupedChats = {
      today: [],
      yesterday: [],
      previousSevenDays: [],
      previousThirtyDays: [],
      months: {},
      years: {},
    };

    chats.forEach((chat) => {
      const chatDate = new Date(chat.updated_at);

      if (isToday(chatDate)) {
        groupedChats.today.push(chat);
      } else if (isYesterday(chatDate)) {
        groupedChats.yesterday.push(chat);
      } else if (isAfter(chatDate, sevenDaysAgo)) {
        groupedChats.previousSevenDays.push(chat);
      } else if (isAfter(chatDate, thirtyDaysAgo)) {
        groupedChats.previousThirtyDays.push(chat);
      } else {
        const month = format(chatDate, 'MMMM');
        const year = format(chatDate, 'yyyy');

        if (!groupedChats.months[year]) {
          groupedChats.months[year] = {};
        }

        if (!groupedChats.months[year][month]) {
          groupedChats.months[year][month] = [];
        }

        groupedChats.months[year][month].push(chat);

        if (!groupedChats.years[year]) {
          groupedChats.years[year] = [];
        }

        groupedChats.years[year].push(chat);
      }
    });

    return groupedChats;
  };

  const groupedChats = groupChatsByDate(titles);

  const renderChats = (chats) => (
    chats.map((chat) => (
      <li key={chat.chat_id} className={tab === chat.chat_id ? "active" : null} onClick={() => navigate(`/tulsa/copilot?chat=${chat.chat_id}`, { state: {} })}>
        <div>{`${chat.chat_title.substring(0, 25)} ${chat.chat_title.length > 27 ? "..." : ""}`}</div>
        {tab === chat.chat_id ? <MoreVertOutlined className='more_icon' /> : null}
      </li>
    ))
  );

  const renderGroupedChats = (groupedChats, label) => (
    Object.entries(groupedChats).map(([key, chats]) => (
      <React.Fragment key={key}>
        <h5>{label} {key}</h5>
        {renderChats(chats)}
      </React.Fragment>
    ))
  );

  return (
    <div className='co_sidebar'>
      {/* New chat click */}
      <div className='co_sidebar__mobile'>
        <ul>
          <li className='co_sidebar__mobile__close' onClick={() => handleClose()}><CloseOutlined/></li>
          <li onClick={() => navigate("/user/home")}>Home</li>
          <li onClick={() => navigate("/copilot")}>Copilot</li>
          <li onClick={() => navigate("/dashboard")}>Dashboard</li>
          <li onClick={() => navigate("/reports")}>Reports</li>
        </ul>
        <hr/>
      </div>
      <div className='co_sidebar__new_chat'>
        <h4>Start New Chat</h4>
        <ReviewsOutlined className='new_chat_icon' onClick={() => navigate("/tulsa/copilot")} />
      </div>

      {/* Chat history */}
      <div className='co_sidebar__chat_history_area'>
        <ul className='co_sidebar__chat_history'>
          {groupedChats.today.length > 0 && (
            <>
              <h5>Today</h5>
              {renderChats(groupedChats.today)}
            </>
          )}
          {groupedChats.yesterday.length > 0 && (
            <>
              <h5>Yesterday</h5>
              {renderChats(groupedChats.yesterday)}
            </>
          )}
          {groupedChats.previousSevenDays.length > 0 && (
            <>
              <h5>Previous 7 Days</h5>
              {renderChats(groupedChats.previousSevenDays)}
            </>
          )}
          {groupedChats.previousThirtyDays.length > 0 && (
            <>
              <h5>Previous 30 Days</h5>
              {renderChats(groupedChats.previousThirtyDays)}
            </>
          )}
          {Object.keys(groupedChats.months).map((year) =>
            Object.keys(groupedChats.months[year]).map((month) => (
              <React.Fragment key={`${year}-${month}`}>
                <h5>{month} {year}</h5>
                {renderChats(groupedChats.months[year][month])}
              </React.Fragment>
            ))
          )}
          {Object.keys(groupedChats.years).map((year) => (
            <React.Fragment key={year}>
              <h5>{year}</h5>
              {renderChats(groupedChats.years[year])}
            </React.Fragment>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default CoSideBar;
