import React from 'react'
import './MunicipalityDemoTable.css'
import { Table } from 'react-bootstrap'
import TableColumn from './TableColumn'
import TableRow from './TableRow'

//Responsive 90% (Table responsive, ui not good)
//Code Formated
//Dynamic table
function MunicipalityDemoTable({bodyData, handleImageClick}) {

    const headerData = Object.keys(bodyData[0] || {})

    return (
        <Table striped hover size='sm' className='municipality_demo_table'>
            <thead style={{position:"sticky", top:"0"}}>
                <tr>
                    {
                        //Setting the coloumns
                        headerData.map((item, index) => (
                            <TableColumn key={index} columnName={item} />
                        ))
                    }
                </tr>
            </thead>
            <tbody>
                {
                    //Setting the data
                    bodyData.map((item, index) => (
                        <TableRow data={item} key={index} handleImageClick={handleImageClick}/>
                    ))
                }
            </tbody>
        </Table>
    )
}

export default MunicipalityDemoTable