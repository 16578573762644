import React, { useEffect, useState } from 'react'
import './MunicipalityDemo.css'
import './DemoPopup.css'
import IQLogo from '../../components/util_component/iq_logo/IQLogo'
import PreMsgCard from '../../components/pre_msg_card/PreMsgCard'
import { preQueries } from './PreTabsData'
import IQButton from '../../components/util_component/iq_button/IQButton'
import IQChatBox from '../../components/util_component/iq_chat_box/IQChatBox'
import { Link, useNavigate } from 'react-router-dom'
import MuniForm from '../../components/muni_demo_pop/muni_form/MuniForm'
import MunicipalityDemoTutorial from './tutorial_popup/MunicipalityDemoTutorial'
import VerificationPopup from './component/verification_popup/VerificationPopup'

// Code formated
//Responsive
function MunicipalityDemo() {
  // navigate
  const navigate = useNavigate();

  // State of card click
  const [tabUrl, setTabUrl] = useState(null);
  const [clickedTab, setClickedTab] = useState(0);

  //Control the popup overlay
  const [overlay, setOverlay] = useState(true);

  // State of form
  const [contactform, setContactForm] = useState(false);

  // State of verification code popup
  const [verificationPopup, setVerificationPopup] = useState(false);

  // handle verified user
  const [verifiedUser, setVerifiedUser] = useState(false);

  //state of demo
  const [demo, setDemo] = useState(true);

  // capture the email
  const [email, setEmail] = useState("");

  // capture token for verification page
  const [token, setToken] = useState("");


  //changing tab url based on clicked tab
  useEffect(() => {
    switch (clickedTab) {
      case 2:
        setTabUrl('zoning');
        break;
      case 3:
        setTabUrl('plumbing');
        break;
      case 4:
        setTabUrl('electrical');
        break;
      case 5:
        setTabUrl('mechanical');
        break;
      case 6:
        setTabUrl('arborist');
        break;
      case 9:
        setTabUrl('residential');
        break;
      default:
        setTabUrl(null);
        break;
    }
  }, [clickedTab])

  // handle the verification click
  const handleVerifiedClick = () => {
    localStorage.setItem('token', token);
    setOverlay(false);
    setVerificationPopup(false);
  }

  // get verification details from local storage
  useEffect(() => {
    if (localStorage.getItem('token')) {
      setVerifiedUser(true);
    }
  }, [])

  // handle demo finish
  const handleDemoFinish = () => {
    setDemo(false);
    if (verifiedUser) {
      setOverlay(false);
    }
    else{
      setContactForm(true)
      setOverlay(true);
    }

  }


  return (
    <div className='municipality_demo'>

      {/* iqland left corner logo */}
      <div className='municipality_demo__corner_logo'><Link to="/"><img style={{width:"48px"}} src="/iq_ic.png" alt='iq'/></Link></div>

      {/* other area */}
      <div className='municipality_demo__content'>

        {/* atlanta and iqland logos */}
        <div className='municipality_demo__muni_logo_area'>
          <IQLogo width="15%" />
          <img style={{ height: "10%", maxHeight: "50px" }} src='/images/copilot/gray_bar.png' alt='l' />
          <img style={{ width: "10%", minWidth: "75px" }} src='/images/copilot/atlanta_logo.png' alt='Atlanta Logo' />
        </div>

        {/* let's get started text */}
        <h4 className='municipality_demo__lets_get'>Let's get started.</h4>

        {/* displaying pre cards to select */}
        <div className='municipality_demo__cards'>
          {
            preQueries.map((card) => {
              return (
                <div key={card.id} className='municipality_demo__card' onClick={() => setClickedTab(card.id)}>
                  <PreMsgCard key={card.id} title={card.title} content={card.content} active={clickedTab === card.id} />
                </div>
              )
            })
          }
        </div>

        {/* run demo button */}
        <div className='municipality_demo__run_btn'>
          <IQButton text="Run Demo" handleClick={() => navigate('/demo/municipalities?tab=' + tabUrl)} disable={tabUrl === null} />
        </div>

        {/* Primary chat bar */}
        <div className='municipality_demo__chat_box'>
          <IQChatBox placeholder="Enter variance if applicable" />
        </div>

      </div>

      {/* Contact form */}

      {
        overlay &&
        <div className='copilot_muni_form'>
          <div className='copilot_muni_form__container'>
            {/* Showing tutorial */}
            {demo && <MunicipalityDemoTutorial handleFinish={handleDemoFinish} />}

            {/* Showing contact form based on verified user */}
            {!verifiedUser && contactform && <MuniForm handleHide={() => { setContactForm(false); setVerificationPopup(true); setOverlay(false) }} setEmail={setEmail} setToken={setToken}/>}
          </div>
        </div>
      }

      {/* Verification popup */}
      {/* Showing verification popup based on verified user */}
      {!verifiedUser && verificationPopup && <VerificationPopup email={email} handleClick={handleVerifiedClick} token={token}/>}


    </div>
  )
}

export default MunicipalityDemo