import React from 'react'

function IQSpinner() {
  return (
    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <img src='/images/spinner.gif' style={{width:"48px"}} alt='spinner'/>
    </div>
  )
}

export default IQSpinner