import React, { useState } from 'react'
import './EmailShare.css'
import IQCloseButton from '../util_component/close_button/IQCloseButton';

function EmailShare({handleClose}) {

    const [showHideThankYou, setShowHideThankYou] = useState(false);

    const [checkAllStatus, setCheckAllStatus] = useState(false)

    const [shareCheckBox, setShareCheckBox] = useState({
        zoning: false,
        residential: false,
        mechanical: false,
        subdivision: false,
        fire: false,
        plumbing: false,
        commercial: false,
        electrical: false,
        arborist: false
    })

    const handleCheckAllToggle = () => {
        if (checkAllStatus === true) {
            setShareCheckBox({
                zoning: false,
                residential: false,
                mechanical: false,
                subdivision: false,
                fire: false,
                plumbing: false,
                commercial: false,
                electrical: false,
                arborist: false
            })
            setCheckAllStatus(false)
        } else {
            setShareCheckBox({
                zoning: true,
                residential: true,
                mechanical: true,
                subdivision: true,
                fire: true,
                plumbing: true,
                commercial: true,
                electrical: true,
                arborist: true
            })
            setCheckAllStatus(true)
        }


    }
    const handleShareClick = (e) => {
        e.preventDefault()
        setShowHideThankYou(!showHideThankYou)
    }

    const handleThankYouPage = () => {
        setShowHideThankYou(!showHideThankYou)        
        handleClose();
    }

    return (
        <div>
            <div className='email_share'>
                {

                    !showHideThankYou ?
                        <div className='email_share__container'>
                            <IQCloseButton handleClose={handleClose} />
                            <form className='email_share__form'>
                                <input type="text" placeholder='Enter email addresses. Separate multiple emails with a comma' required="true" />
                                <div className=''>
                                    <div className='check_all'><input type="checkbox" onChange={handleCheckAllToggle} /><label>Select all options</label></div>
                                    <div className='check_boxes'>
                                        <div><input type='checkbox' checked={shareCheckBox.zoning} onChange={() => setShareCheckBox({ ...shareCheckBox, zoning: !shareCheckBox.zoning })} /><label>Zoning</label></div>
                                        <div><input type='checkbox' checked={shareCheckBox.residential} onChange={() => setShareCheckBox({ ...shareCheckBox, residential: !shareCheckBox.residential })} /><label>Residential structural building code</label></div>
                                        <div><input type='checkbox' checked={shareCheckBox.mechanical} onChange={() => setShareCheckBox({ ...shareCheckBox, mechanical: !shareCheckBox.mechanical })} /><label>Mechanical</label></div>
                                        <div className='deactivated_box'><input disabled="true" type='checkbox' /><label>Parcel subdivision</label></div>
                                        <div className='deactivated_box'><input disabled="true" type='checkbox' /><label>Fire code</label></div>
                                        <div><input type='checkbox' checked={shareCheckBox.plumbing} onChange={() => setShareCheckBox({ ...shareCheckBox, plumbing: !shareCheckBox.plumbing })} /><label>Plumbing</label></div>
                                        <div className='deactivated_box'><input disabled="true" type='checkbox' /><label>Commercial structural building code</label></div>
                                        <div><input type='checkbox' checked={shareCheckBox.electrical} onChange={() => setShareCheckBox({ ...shareCheckBox, electrical: !shareCheckBox.electrical })} /><label>Electrical</label></div>
                                        <div><input type='checkbox' checked={shareCheckBox.arborist} onChange={() => setShareCheckBox({ ...shareCheckBox, arborist: !shareCheckBox.arborist })} /><label>Arborist</label></div>

                                    </div>

                                </div>
                                <button onClick={handleShareClick} className='btn-primary'>Share</button>
                            </form>



                        </div>
                        :
                        <div>
                            <div className='popup-new'>
                                <span className="close" onClick={handleThankYouPage}>&times;</span>
                                <div className='left-area'>
                                    <div className='ty-image' style={{display:"flex", justifyContent:"center"}}>
                                        <img src={process.env.PUBLIC_URL + "/thankyou.png"} alt='thank you' />
                                    </div>
                                    <h1 style={{ textAlign: 'center' }}>Report Sent!</h1>
                                    <p style={{ textAlign: 'center' }}>Please check your inbox, including spam or junk folders, for the report shortly.</p>
                                    <button onClick={handleThankYouPage}>Got it!</button>
                                </div>
                            </div>
                        </div>


                }
            </div>
        </div>
    )
}

export default EmailShare