// import {
//   PaymentElement,
//   LinkAuthenticationElement,
// } from "@stripe/react-stripe-js";
// import { useState } from "react";
// import { useStripe, useElements } from "@stripe/react-stripe-js";

// export default function CheckoutForm() {
//   const stripe = useStripe();
//   const elements = useElements();
//   const [message, setMessage] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!stripe || !elements) {
//       // Stripe.js has not yet loaded.
//       // Make sure to disable form submission until Stripe.js has loaded.
//       return;
//     }

//     setIsLoading(true);

//     const { error } = await stripe.confirmSetup({
//       elements,
//       confirmParams: {
//         // Make sure to change this to your payment completion page
//         return_url: `${window.location.origin}/completion`,
//       },
//     });

//     // This point will only be reached if there is an immediate error when
//     // confirming the payment. Otherwise, your customer will be redirected to
//     // your `return_url`. For some payment methods like iDEAL, your customer will
//     // be redirected to an intermediate site first to authorize the payment, then
//     // redirected to the `return_url`.
//     if (error.type === "card_error" || error.type === "validation_error") {
//       setMessage(error.message);
//     } else {
//       setMessage("An unexpected error occured.");
//     }

//     setIsLoading(false);
//   };

//   return (
//     <form id="payment-form" onSubmit={handleSubmit}>
//       {/* <LinkAuthenticationElement
//         id="link-authentication-element"
//         // Access the email value like so:
//         // onChange={(event) => {
//         //  setEmail(event.value.email);
//         // }}
//         //
//         // Prefill the email field like so:
//         // options={{defaultValues: {email: 'foo@bar.com'}}}
//       /> */}
//       <PaymentElement id="payment-element" />
//       <button disabled={isLoading || !stripe || !elements} id="submit">
//         <span id="button-text">
//           {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
//         </span>
//       </button>
//       {/* Show any error or success messages */}
//       {message && <div id="payment-message">{message}</div>}
//     </form>
//   );
// }

import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { getCsrfToken } from "../../components/user_service/UserService";
import { Alert, Snackbar } from "@mui/material";
const API_URL = process.env.REACT_APP_BACK_END_END_POINT;
const FRONTEND_URL = process.env.REACT_APP_FRONT_END_URL;

const CheckoutForm = ({ checkoutButtonRef, priceId, loading, setLoading }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();

  const [toastShow, setToastShow] = useState(false);
  const [toastText, setToastText] = useState("");
  const [toastStatus, setToastStatus] = useState("success");
  

  const handleError = (error) => {
    setLoading(false);
    setToastStatus("error");
    setToastText(error.message);
    setToastShow(true);
    // setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // Create the subscription
    try {
      const csrfToken = await getCsrfToken();
      axios.defaults.headers.post["X-CSRFToken"] = csrfToken;
      axios.defaults.withCredentials = true;
      const res = await axios.post(`${API_URL}/create/subscription/`, {
        price_id: priceId,
      });
      const { type, clientSecret, subscriptionId } = res.data;
      const confirmIntent =
        type === "setup" ? stripe.confirmSetup : stripe.confirmPayment;

      // Confirm the Intent using the details collected by the Payment Element
      let returnUrl = `${FRONTEND_URL}/settings/billing`;
      const { error } = await confirmIntent({
        elements,
        clientSecret,
        confirmParams: {
          return_url: returnUrl,
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when confirming the Intent.
        // Show the error to your customer (for example, "payment details incomplete").
        handleError(error);
      } else {
        // Your customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
    catch (error) {
      handleError(error);
      setLoading(false);
      return;
    }



  };

  
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || loading}
        ref={checkoutButtonRef}
        style={{ display: "none" }}
      >
        Submit Payment
      </button>
      {errorMessage && <div>{errorMessage}</div>}

      <Snackbar
                open={toastShow}
                autoHideDuration={3000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
    </form>
  );
};

export default CheckoutForm;
