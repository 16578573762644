import React from 'react'
import './GeniusPopup.css'
import IQButton from '../../../../components/util_component/iq_button/IQButton'
import IQCloseButton from '../../../../components/util_component/close_button/IQCloseButton'

function GeniusPopup({handleClose}) {

    return (
        <div className='genius_popup'>
            <div className='genius_popup__container'>
                <input type='text' placeholder='Enter here, What variations needed?' />
                <IQButton text="Submit" handleClick={handleClose}/>
            </div>
            <IQCloseButton handleClose={handleClose}/>
        </div>
    )
}

export default GeniusPopup