import React from 'react';
import { Typography, Container, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <Container sx={{ textAlign: 'center', marginTop: '5rem' }}>
            <Box sx={{ color: 'var(--primary-clr)' }}>
                <Typography variant="h1" component="div" gutterBottom>
                    404
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                    Page Not Found
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Sorry, the page you are looking for does not exist.
                </Typography>
                <Button
                    component={Link}
                    to="/"
                    variant="contained"
                    sx={{
                        backgroundColor: 'var(--primary-clr)',
                        color: '#ffffff',
                        '&:hover': {
                            backgroundColor: 'var(--primary-dark-clr)',
                        },
                        marginTop: '2rem'
                    }}
                >
                    Go to Home
                </Button>
            </Box>
        </Container>
    );
};

export default NotFoundPage;
