import React from 'react'
import ReactMarkdown from 'react-markdown'
import RemarkGfm from 'remark-gfm'
import './ChatFormat.css'

function ChatFormat({ msg }) {
    return (
        <div className='chat_format'>
            <ReactMarkdown
                remarkPlugins={[RemarkGfm]}>
                {msg}
            </ReactMarkdown>
        </div>
    )
}

export default ChatFormat