import React, { useState } from 'react'
import './FilterMsgPop.css'
import { Checkbox, FormControlLabel } from '@mui/material';

function FilterMsgPop({ setFilterMsgPop }) {

    const [checked, setChecked] = useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClickOk = () => {
        setFilterMsgPop();
        if (checked) {
            localStorage.setItem('filterMsgPop', 'true');
        }
    }

    return (
        <div className='filter_msg_pop_container'>
            <div className='filter_msg_pop'>
                <div className='filter_msg_pop_close' onClick={setFilterMsgPop}>X</div>
                <div className='filter_msg_pop_title'>Filters</div>
                {/* <img className='filter_msg_pop_img' src='/images/dashboard_new/filter_pop_screenshot.png' alt='filter'/> */}
                <div className='filter_msg_pop_msg'>Find additional plans by adjusting filters</div>
                <div className='filter_msg_pop_dont'>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleChange}
                                sx={{
                                    color: 'var(--primary-clr)', // Unchecked color
                                    '&.Mui-checked': {
                                        color: 'var(--primary-clr)', // Checked color
                                    },
                                }}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label="Don't show this again"
                    />

                </div>
                <div className='filter_msg_pop_button' onClick={handleClickOk}><div style={{ width: "100%" }} className='lan_primary_button'>OK</div></div>
            </div>
        </div>
    )
}

export default FilterMsgPop