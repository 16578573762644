import React from 'react';
import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';

function Footer() {

  const currentYear = new Date().getFullYear();

  return (
    <footer className='footer-section'>

      <div className='footer__top'>
        <div style={{ maxWidth: "10rem", flexShrink: "0" }}><img  height={48} src='/logo2.svg' alt='logo' /></div>
        <div className='footer__link_list'>
          <div>
            <ul className='footer__links'>
              <li className='title'>About Us</li>
              <li><a href='/pricing' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "none", color: "black" }}>Pricing</a></li>
              {/* <li>Enterprise Solutions</li>
              <li>Affiliate Program</li> */}
            </ul>
          </div>
          <div>
            <ul className='footer__links'>
              <li className='title'>Learn</li>
              <li><a href='/faqs' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "none", color: "black" }}>FAQs</a></li>
              {/* <li>Blogs</li> */}
            </ul>
          </div>
          <div>
            <ul className='footer__links'>
              <li className='title'>Products</li>
              <li><a href='/products/copilot' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "none", color: "black" }}>Copilot</a></li>
              <li><a href='/products/dashboard' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "none", color: "black" }}>SmartBuild</a></li>
              <li><a href='/products/appraiser' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "none", color: "black" }}>Appraiser</a></li>
            </ul>
          </div>
          <div>
            <ul className='footer__links'>
              <li className='title'>Customer Support</li>
              <li><a style={{ textDecoration: "none", color: "black" }} href="mailto:support@iqland.ai"> support@iqland.ai</a></li>
              <li><a href='/contact' target='_blank' rel='noopener noreferrer' style={{ textDecoration: "none", color: "black" }}>Contact</a></li>
            </ul>
          </div>
        </div>

      </div>

      <div className='footer__bottom'>
        <div className='footer__copyright_text'>
          © Copyright {currentYear}. All rights reserved.
        </div>

        <ul className='footer__social_links'>
          <a href='https://www.facebook.com/iqlandai' target='_blank' rel='noopener noreferrer'><li><FontAwesomeIcon icon={faFacebook} /> </li></a>
          <a href='https://www.x.com/IQLAND_AI' target='_blank' rel='nooperner noreferrer'  ><li><FontAwesomeIcon icon={faX} /> </li></a>
          <a href='https://www.linkedin.com/company/iqland-ai/' target='_blank' rel='nooperner noreferrer' ><li><FontAwesomeIcon icon={faLinkedin} /> </li></a>
          <a href='https://www.instagram.com/iqland.ai/' target='_blank' rel='nooperner noreferrer'  ><li><FontAwesomeIcon icon={faInstagram} /> </li></a>
          <a href='https://www.tiktok.com/@iqland_ai/' target='_blank' rel='nooperner noreferrer' ><li><FontAwesomeIcon icon={faTiktok} /> </li></a>
        </ul>

        {/* <div className='footer__site_map'>
          Site Map
        </div> */}
      </div>

    </footer>
  )
}

export default Footer