import React from 'react'
import './IQChatBox.css'

function IQChatBox({placeholder}) {
  return (
    <div className='iq_chat_box'>
        <input type='text' placeholder={placeholder} />
        <button><img src='/images/copilot/send_btn.png' alt='send' /></button>
    </div>
  )
}

export default IQChatBox