import React, { useState } from 'react'
import { viewPlanData } from './ViewPlanSampleData';
import './ViewPlan.css';
import IQCloseButton from '../util_component/close_button/IQCloseButton';

// Code Formated
// Mobile Responsive
function ViewPlan({ handleClose }) {


    // to get pop image on the plan
    const [partImagePop, setPartImagePop] = useState("");

    // to control part image popup
    const [partImagePopup, setPartImagePopup] = useState({ display: "none" });


    // to get part image title
    // const [partImageTitle, setPartImageTitle] = useState("");

    //Handle the slide change (next)
    // const handlePlusClick = () => {
    //     if (slideNumber < viewPlanData.length - 1) {
    //         setSlideNumber(slideNumber + 1);
    //     }
    // }

    //Handle the slide change (previous)
    // const handleMinusClick = () => {
    //     if (slideNumber > 0) {
    //         setSlideNumber(slideNumber - 1);
    //     }
    // }

    //Handle the part image popup
    const handlePartImagePopup = (title, image) => {
        // setPartImageTitle(title);
        setPartImagePopup({ display: "flex" });
        setPartImagePop(image);
    }

    //Handle the part image popup close
    const handlePartImagePopupClose = () => {
        setPartImagePopup({ display: "none" });
    }


    return (
        <div className='view_plan'>
            <div className='view_plan__container'>

                {/* close view plan */}
                <IQCloseButton handleClose={handleClose} />

                <div className='view_plan__slides_container'>
                    {/* Display slides*/}
                    {
                        viewPlanData.map((page) => (
                            <div key={page.id} className='view_plan__image_area'>
                                <img className='plan_image' src={"/images/view_plan/" + page.image} alt='Plan Map' />
                                {
                                    // Setting icons
                                    page.icons && page.icons.map((icon, index) => (
                                        <img key={index} onClick={() => handlePartImagePopup(icon.pop_text, icon.pop_image)} className='iqland_icon' src='/icon.ico' alt='IQLand' style={icon.icon_position} />
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>

            </div>

            {/* Handle slide change */}
            {/* <div className='view_plan__buttons'>
                <button onClick={handleMinusClick} style={{ width: "5%" }} className='btn-primary'>&lt;</button>
                <button onClick={handlePlusClick} style={{ width: "5%" }} className='btn-primary'>&gt;</button>
            </div> */}

            {/* pass/fail part image popup on view plan*/}
            <div className='part_image_popup' style={partImagePopup} onClick={handlePartImagePopupClose}>
                <div className='part_image_popup__container'>
                    {/* <h4>{partImageTitle}</h4> */}
                    <img src={'/images/view_plan/part_image/' + partImagePop} alt='Pass/Fail Status' />
                </div>
            </div>
        </div>
    )
}

export default ViewPlan