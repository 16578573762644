import React from 'react'
import './IQCloseButton.css'

//parent position css to be non-static
function IQCloseButton({handleClose}) {
  return (
    <button onClick={handleClose} className='iq_close_button'>&times;</button>
  )
}

export default IQCloseButton