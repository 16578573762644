export const faqData = [
    {
        "title": "General Questions",
        "values": [
            {
                "q": "Why choose IQLAND?",
                "a": "IQLAND simplifies residential real estate development by offering zoning interpretations, property appraisals, and GIS mapping. Designed for developers, real estate professionals, and investors, IQLAND helps you make informed decisions quickly, saving time and reducing project risks."
            },
            {
                "q": "Why doesn’t IQLAND offer official site plans?",
                "a": "IQLAND uses advanced GIS mapping to estimate parcel attributes such as lot size, dimensions, and zoning classification. For official documentation required for city submissions, licensed surveyors and architects should be consulted. This allows IQLAND to focus on delivering fast, accurate planning data while ensuring legal processes are handled by appropriate professionals."
            },
            {
                "q": "Why can’t I submit IQLAND’s findings directly to the city?",
                "a": "While IQLAND provides accurate zoning and parcel data, these are meant for planning purposes and not for official submissions. Certified documents should be used for city submissions to ensure compliance with local regulations and avoid project delays."
            },
            {
                "q": "Why should I use IQLAND for residential projects?",
                "a": "IQLAND provides the critical tools you need—GIS mapping, zoning interpretation, and property appraisals—so you can make confident decisions faster, whether you're working on small residential developments or larger-scale projects."
            }
        ]
    },
    {
        "title": "Pricing and Subscription",
        "values": [
            {
                "q": "Why is IQLAND subscription-based?",
                "a": "IQLAND’s subscription model ensures you have continuous access to the latest property data and zoning updates. At $99 per month, with discounts for yearly subscriptions, IQLAND is a cost-effective solution for real estate professionals who need reliable and up-to-date information."
            },
            {
                "q": "What’s included in the IQLAND subscription and why is it valuable?",
                "a": "Your subscription gives you full access to zoning interpretation tools, GIS mapping, residential parcel data, and appraisal estimates. This comprehensive package helps you avoid costly mistakes by providing essential insights into your projects right from the start."
            },
            {
                "q": "Why is flexibility in cancelling subscriptions important?",
                "a": "IQLAND allows you to cancel your subscription at any time, offering flexibility as your project needs evolve. This ensures you only pay for the service when it’s useful for your current work, giving you control over your expenses."
            },
            {
                "q": "Why should I opt for a yearly subscription?",
                "a": "Yearly subscriptions offer discounted rates, making it a more affordable option for those with ongoing projects. If you’re consistently developing or managing properties, a yearly subscription ensures you have uninterrupted access to IQLAND's full range of features at a lower cost."
            }
        ]
    },
    {
        "title": "Third-Party Architect Plans",
        "values": [
            {
                "q": "Why does IQLAND recommend third-party architect plans?",
                "a": "Based on the zoning and parcel data we provide, IQLAND can recommend third-party architect plans suited for your project. However, we strongly advise consulting directly with the plan vendor and a licensed surveyor before purchasing any plans to ensure they meet your project’s needs and local regulations."
            },
            {
                "q": "Are refunds available for third-party architect plans?",
                "a": "No, IQLAND does not offer refunds for third-party architect plans. This includes refunds for purchased plans, any requested modifications, or delivery issues. Once purchased, all matters related to these plans should be directed to the third-party vendor."
            },
            {
                "q": "Why is IQLAND indemnified regarding third-party architect plans?",
                "a": "IQLAND is indemnified from any issues arising from the purchase or use of third-party architect plans. This means that IQLAND is not responsible for any errors, modifications, or issues associated with these plans. Consult directly with the plan vendor and a surveyor to ensure the plans meet your project’s specifications."
            }
        ]
    },
    {
        "title": "Construction Costs",
        "values": [
            {
                "q": "Why are IQLAND’s construction cost estimates useful?",
                "a": "IQLAND provides construction cost estimates based on local building trends and national averages. These estimates give you a rough idea of the potential cost for your project, helping you budget early and assess project viability."
            },
            {
                "q": "Are construction costs guaranteed?",
                "a": "No, construction costs provided by IQLAND are estimates. The actual cost of construction will vary depending on individual contractor prices, material choices, labor costs, and other factors. We recommend consulting directly with your builder to get a precise estimate for your project."
            }
        ]
    },
    {
        "title": "Sharing Results",
        "values": [
            {
                "q": "Can I share my IQLAND results?",
                "a": "Yes, you can share property reports and zoning data with various stakeholders involved in your project. Whether it's banks, builders, real estate agents, or potential buyers, IQLAND allows you to easily send reports to keep all parties informed and aligned."
            }
        ]
    },
    {
        "title": "Login and Security",
        "values": [
            {
                "q": "Why does IQLAND require 2-factor authentication?",
                "a": "2-factor authentication adds an essential layer of security to protect your sensitive property data. This ensures that only authorized individuals can access your account, safeguarding your project information."
            },
            {
                "q": "Why are additional authentication methods planned?",
                "a": "IQLAND is expanding its security features by introducing additional 2-factor authentication methods, providing more options to help you better protect your account based on your preferences and needs."
            }
        ]
    },
    {
        "title": "Service Area",
        "values": [
            {
                "q": "Why is IQLAND expanding its service area?",
                "a": "IQLAND started by serving developers in Oklahoma, where accurate parcel and zoning information is crucial. We are rapidly expanding our services across the United States to assist more developers and real estate professionals in streamlining their projects. Sign up to stay informed about when we’ll be available in your area."
            }
        ]
    },
    {
        "title": "Features",
        "values": [
            {
                "q": "Why is the interactive dashboard essential for users?",
                "a": "The interactive dashboard centralizes all your parcel data, zoning information, and construction cost estimates in one easy-to-use location. This enables you to quickly analyze and assess project viability without needing to navigate through multiple systems."
            },
            {
                "q": "Why use IQLAND’s copilot chatbot?",
                "a": "The copilot chatbot offers real-time assistance, guiding you through zoning laws, project feasibility, and other development-related queries. Its AI-powered responses help you resolve questions quickly, keeping your project on schedule."
            },
            {
                "q": "Why are future premium features worth waiting for?",
                "a": "IQLAND is constantly evolving, with new premium features on the horizon. These features will provide advanced tools for residential development, improving your experience and offering even greater efficiency and accuracy in project planning."
            }
        ]
    },
    {
        "title": "Accuracy and Reliability",
        "values": [
            {
                "q": "Why trust IQLAND’s zoning information?",
                "a": "IQLAND provides zoning interpretations based on the latest GIS data. While reliable for planning, it's always recommended to consult with a local expert or legal professional for official decisions and submissions to avoid compliance issues."
            },
            {
                "q": "Why are IQLAND’s appraisals valuable in early project stages?",
                "a": "IQLAND’s appraisals give you a quick, reliable estimate of a property's value, helping you assess whether a project is financially viable. However, for legal and financial decisions, a state-certified appraisal is required."
            },
            {
                "q": "Why should I consult a professional for city submissions?",
                "a": "While IQLAND provides excellent preliminary data for planning, city submissions require official documentation from certified surveyors or architects. This ensures all legal and zoning requirements are met, reducing the risk of project delays or denials."
            }
        ]
    },
    {
        "title": "Terms and Conditions",
        "values": [
            {
                "q": "Why keep my IQLAND account secure?",
                "a": "Your IQLAND account contains important project data and sensitive information. Keeping it secure ensures that only authorized individuals can access it, protecting your project from potential risks."
            },
            {
                "q": "Why does IQLAND have a no-refund policy for plans and subscriptions?",
                "a": "IQLAND offers a range of services, including third-party architect plans and subscription access. All sales for plans and subscriptions are final, ensuring fairness and consistency. No refunds are provided for plans, modifications, or delivery issues, so always ensure the suitability of your purchase before committing."
            },
            {
                "q": "Why does IQLAND collect user data?",
                "a": "IQLAND collects user data to personalize your experience and improve our services. This data helps us refine our offerings and provide better insights. We ensure that all information is handled according to our Privacy Policy and is not shared without your consent."
            }
        ]
    },
    {
        "title": "Support and Contact",
        "values": [
            {
                "q": "Why contact IQLAND support?",
                "a": "Our support team is dedicated to helping you get the most out of IQLAND. Whether you need assistance with using the platform or have questions about your project, we’re here to ensure you have the support you need."
            },
            {
                "q": "How do I contact IQLAND support?",
                "a": "The  IQLAND help desk can be reached at support@iqland.ai.  Our help desk answers inquiries Monday through Rriday from 9am - 5pm CST.  We are ready to assist you with timely answers about your account."
            }
        ]
    }
];
