import React from 'react'
import './IQInformationPop.css'
import IQButton from '../iq_button/IQButton'

function IQInformationPop({message, setVisibility}) {
    return (
        <div className='iq_information'>
            <div className='iq_information__container'>
                {/* <img src='/iq_ic.png' alt='iqland icon' /> */}
                <h5>{message}</h5>
                <IQButton text="OK" handleClick={() => setVisibility(false)}/>
            </div>
        </div>
    )
}

export default IQInformationPop