import React, { useEffect, useState } from 'react'
import './GeoDetails.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import { useFormik } from 'formik'
import { getOtherProfileSetting, patchOtherProfileSetting } from '../../components/auth/AuthService'
import { Alert, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar } from '@mui/material'

import SettingMobileNav from './SettingMobileNav'

function GeoDetails() {
    const [mobileSideBar, setMobileSidebar] = useState(false);

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('Updated');
    const [toastStatus, setToastStatus] = useState('success');

    // usa states
    const states = [
        "None", "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ];
    const provinces = ["None", "Alberta", "British Columbia", "Manitoba", "New Brunswick", "Newfoundland and Labrador", "Nova Scotia", "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan"];

    const formik = useFormik({
        initialValues: {
            primary_service_area: "",
            state: "None",
            province: "None",
            zip_postal_code: ""
        },
        onSubmit: async(values) => {
            try{
                await patchOtherProfileSetting(values);
                
                setToastText("Updated");
                setToastStatus("success");
                setToastShow(true);
            }
            catch(error){
                // console.log(error)
                setToastText("Error");
                setToastStatus("warning");
                setToastShow(true);
            }
        },
    });

    useEffect(() => {
        try {
            getOtherProfileSetting()
                .then((response) => {
                    formik.setFieldValue('primary_service_area', response.data.primary_service_area);
                    response.data.state ? formik.setFieldValue('state', response.data.state) : formik.setFieldValue('state', states[0]);
                    response.data.province ? formik.setFieldValue('province', response.data.province) : formik.setFieldValue('province', provinces[0]);
                    formik.setFieldValue('zip_postal_code', response.data.zip_postal_code);
                }
                )
        }
        catch (error) {
            // console.log(error)
        }
    }, [])

    useEffect(() => {
        const handleOutsideClick = (event) => {
          if (mobileSideBar && !event.target.closest('.setting_other_nav__mobile_active')) {
            setMobileSidebar(false);
          }
        };
    
        document.addEventListener('mousedown', handleOutsideClick);
    
        return () => {
          document.removeEventListener('mousedown', handleOutsideClick);
        };
      }, [mobileSideBar]);
    

    return (
        <div className='geo_details'>
            <UserNav handleBurgerClick={()=>setMobileSidebar(!mobileSideBar)}/>
            <div className='geo_details__container'>
                <SettingSideBar tab={"3"} />
                <div className='geo_details__main'>
                    <div className='geo_details__part'>
                        <h5>Geographic Details</h5>

                        <div className='geo_details__inputs'>
                            <div className='geo_details__inputs_sets'>
                                <FormControl sx={{ flex:"1" }} variant="outlined" error={formik.touched.primary_service_area && Boolean(formik.errors.primary_service_area)}>
                                    <InputLabel htmlFor="outlined-adornment-first">Primary Service Area/City</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-first"
                                        type="text"
                                        label="Primary Service Area/City"
                                        name="primary_service_area"
                                        value={formik.values.primary_service_area? formik.values.primary_service_area: ""}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </FormControl>
                                <FormControl sx={{ flexShrink: 0, flexGrow: "1",flex:"1"  }}>
                                    <InputLabel sx={{
                                        '&.Mui-focused': {
                                            color: 'var(--primary-clr)',
                                        },
                                    }} id="demo-simple-select-label">State</InputLabel>
                                    <Select
                                        sx={{
                                            height: "3rem",
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'var(--primary-clr)',
                                            },
                                        }}

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formik.values.primary_service_area?.toLowerCase() === "canada" ? "None" : formik.values.state}
                                        label="State"
                                        onChange={(e) => formik.setFieldValue('state', e.target.value)}
                                        disabled={formik.values.primary_service_area?.toLowerCase() === "canada" ? true : false}
                                    >
                                        {
                                            states.map((value, index) => (
                                                <MenuItem key={index} value={value}>{value}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='geo_details__inputs_sets'>
                                <FormControl sx={{ flexShrink: 0, flexGrow: "1",flex:"1"  }}>
                                    <InputLabel sx={{
                                        '&.Mui-focused': {
                                            color: 'var(--primary-clr)',
                                        },
                                    }} id="demo-simple-select-label">Province</InputLabel>
                                    <Select
                                        sx={{
                                            height: "3rem",
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'var(--primary-clr)',
                                            },
                                        }}

                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={formik.values.primary_service_area?.toLowerCase() === "canada" ? formik.values.province: "None"}
                                        label="Province"
                                        onChange={(e) => formik.setFieldValue('province', e.target.value)}
                                        disabled={formik.values.primary_service_area?.toLowerCase() === "canada" ? false : true}
                                    >
                                        {
                                            provinces.map((value, index) => (
                                                <MenuItem key={index} value={value}>{value}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                                <FormControl sx={{ flex:"1"  }} variant="outlined" error={formik.touched.zip_postal_code && Boolean(formik.errors.zip_postal_code)}>
                                    <InputLabel htmlFor="outlined-adornment-first">Zip Postal code</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-first"
                                        type="text"
                                        label="Zip Postal code"
                                        name="zip_postal_code"
                                        value={formik.values.zip_postal_code? formik.values.zip_postal_code: ""}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <button type='button' className='geo_details__btn' onClick={() => formik.handleSubmit()}>Update</button>
                    </div>

                </div>
            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={1500}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>

            {
                mobileSideBar && <SettingMobileNav tab={"3"} isActive={mobileSideBar} setIsActive={setMobileSidebar}/>
            }
        </div>
    )
}

export default GeoDetails