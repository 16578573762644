import { GoogleMap, InfoWindowF, OverlayViewF, PolygonF, useJsApiLoader } from '@react-google-maps/api'
import React, { useState } from 'react'
import './MapWithPolygon.css'
import { LoadingSkeletonText } from '../skeleton_loading/SkeletonLoading';

const libraries = ['places']


function MapWithPolygon({ polygon }) {

  // convert polygon data to lat lng object
  const transformedData = polygon.parcel_polygon.map(([lng, lat]) => ({ lat, lng }));
  const transformSetBackData = polygon.setback_polygon.length !== 0 && polygon.setback_polygon.map(([lng, lat]) => ({ lat, lng }));

  const [mapCenter, setMapCenter] = useState(transformedData[0]);


  // Show popup when hovering over the polygon
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);


  const [infoWindowOpen2, setInfoWindowOpen2] = useState(false);
  const [infoWindowPosition2, setInfoWindowPosition2] = useState(null);

  const handleMouseOver = (e) => {
    setInfoWindowOpen(true);
    setInfoWindowPosition(e.latLng); // Set position of InfoWindow
  };

  const handleMouseOut = () => {
    setInfoWindowOpen(false);
  };

  const handleMouseOver2 = (e) => {
    setInfoWindowOpen2(true);
    setInfoWindowPosition2(e.latLng); // Set position of InfoWindow
  };

  const handleMouseOut2 = () => {
    setInfoWindowOpen2(false);
  };

  // set map size
  const mapContainerStyle = {
    height: '25rem',
    width: '100%'
  }

  // Needed to first call to render map
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: libraries,
  });

  if (!isLoaded) {
    return <LoadingSkeletonText width={"100%"} />
  }

  if (loadError) {
    return <div>{loadError.message}</div>
  }
  
  // Calculate the centroid of the setback polygon
  // const calculateCentroid = (polygon) => {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   polygon.forEach((point) => bounds.extend(point));
  //   return bounds.getCenter();
  // };
  // const setbackCentroid = transformSetBackData ? calculateCentroid(transformSetBackData) : null;

  return (
    <GoogleMap
      center={mapCenter}
      zoom={18}
      mapContainerStyle={mapContainerStyle}
      options={
        {
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          // styles: mapStyle
        }
      }
    >
      <PolygonF
        paths={transformedData}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        options={{
          fillColor: '#126342',
          fillOpacity: 0.8,
          strokeColor: '#0c462f',
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />

      {
        transformSetBackData && (<PolygonF
          paths={transformSetBackData}
          onMouseOver={handleMouseOver2}
          onMouseOut={handleMouseOut2}
          options={{
            fillColor: '#DFBB7F',
            fillOpacity: 0.8,
            strokeColor: 'black',
            strokeOpacity: 1,
            strokeWeight: 2,
          }}
        />)
      }

      {/* {setbackCentroid && (
        <OverlayViewF
          position={setbackCentroid}
          mapPaneName={OverlayViewF.OVERLAY_MOUSE_TARGET}
        >
          <div style={{ backgroundColor: "rgba(223, 187, 127, 0.8)", padding: '5px', borderRadius: '3px', color: 'black', fontWeight: 'bold' }}>
            Setback
          </div>
        </OverlayViewF>
      )} */}

      {infoWindowOpen && (
        <div style={{ backgroundColor: "var(--primary-clr)" }} className='info_parcel' options={{ disableAutoPan: true }}>
          <div>
            <h6>Parcel</h6>
            {/* <p>This is where you can display information about the polygon.</p> */}
          </div>
        </div>
      )}

      {infoWindowOpen2 && (
        <div style={{ backgroundColor: "var(--secondary-clr)" }} className='info_parcel' options={{ disableAutoPan: true }}>
          <div>
            <h6>Buildable area</h6>
            {/* <p>This is where you can display information about the polygon.</p> */}
          </div>
        </div>
      )}
    </GoogleMap>
  )
}

export default MapWithPolygon