import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { getSubscriptionPlans } from "../../components/auth/AuthService";
import CheckoutForm from "./CheckoutForm";
import NavBar from "../../components/navbar/NavBar";
import { DoneOutlined } from "@mui/icons-material";
import "./Payment.css";
import PaymentSchedule from "./PaymentSchedule";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import IQSpinner from "../../components/util_component/iq_spinner/IQSpinner";
import TermConditions from "../terms_conditions/component/TermConditions";

function Payment(props) {

  // convert to currency
  function formatCurrency(number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  }

  const [termsPop, setTermsPop] = useState(false);

  const [paymentLoading, setPaymentLoading] = useState(false);

  const [agree, setAgree] = useState(false);

  const location = useLocation();
  const checkoutButtonRef = useRef(null);
  const query = new URLSearchParams(location.search);
  const priceId = query.get("id");
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlanPrice, setSelectedPlanPrice] = useState();

  const { stripePromise } = props;

  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  const options = {
    mode: "subscription",
    amount: 0,
    currency: "usd",
  };

  const standardPlanFeatures = [
    "Copilot",
    "Interactive dashboards",
    "Residential appraisal estimations",
    "Parcel specific residential zoning interpretations",
    "Parcel specific construction-ready plan recommendations",
    "Feasibility reports for new construction projects",
    "Share reports with stakeholders",
    // "Residential site plan and construction plan compliance checks. (Coming soon)"
  ];


  async function getPlans() {
    try {
      const response = await getSubscriptionPlans();
      // console.log(response);
      const plans = response.products.find((item) =>
        item.prices.some((price) => price.id === priceId)
      );
      setSubscriptionPlans([plans]);
      setSelectedPlanPrice(plans.prices.find((item) => item.id === priceId));
      // console.log(plans);
    } catch (error) {
      // console.log(error);
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  const handleClick = () => {
    setPaymentLoading(true);
    try {
      if (checkoutButtonRef.current) {
        checkoutButtonRef.current.click();
      }
      else {
        setPaymentLoading(false);
      }
    }
    catch (err) {
      // console.log(err);
      setPaymentLoading(false);
    }
  };

  const calculatedDiscountedAmount = () => {
    if (selectedPlanPrice) {
      return (
        parseFloat(selectedPlanPrice?.unit_amount) -
        (parseFloat(selectedPlanPrice?.unit_amount) *
          parseInt(selectedPlanPrice?.discount_percentage?.replace("%", ""))) /
        100
      ).toFixed(2);
    } else {
      return "";
    }
  };

  return (
    <>
      <NavBar handleVisiblity={handleModal} />
      <div className="checkout_page">
        <div className="checkout_page__top">
          <div>
            <PaymentSchedule
              subscriptionPlans={subscriptionPlans}
              setSelectedPlanPrice={setSelectedPlanPrice}
              selectedPriceIdDefault={selectedPlanPrice?.id ?? priceId}
            />
            <div className="checkout_page__note">{`Start with a 7-day free trial. After the trial period, your card will be charged ${formatCurrency(selectedPlanPrice?.unit_amount)} per ${selectedPlanPrice?.interval}. You can cancel anytime before the trial ends to avoid charges.`}</div>
            <div className="checkout_page__stripe_area">
              <div className="checkout_page__stripe_title">Enter Payment Details</div>
              <div className="checkout_page__stripe_card">
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm
                    checkoutButtonRef={checkoutButtonRef}
                    priceId={priceId}
                    loading={paymentLoading}
                    setLoading={setPaymentLoading}
                  />
                </Elements>
              </div>
            </div>
          </div>
          <div className="checkout_page__summary">
            <div className="checkout_page__summary_top">
              <div className="checkout_page__summary_title">Summary</div>
              <div className="checkout_page__summary_items">
                <div className="checkout_page__summary_item">
                  <div className="checkout_page__summary_item_text">{subscriptionPlans[0]?.name} Plan</div>
                  <div className="checkout_page__summary_item_price">{formatCurrency(selectedPlanPrice?.unit_amount)}</div>
                </div>
                {/* <div className="checkout_page__summary_item">
                  <div className="checkout_page__summary_item_text">{selectedPlanPrice?.discount_percentage} Discount</div>
                  <div className="checkout_page__summary_item_price">
                    {selectedPlanPrice && formatCurrency(selectedPlanPrice?.unit_amount * parseFloat(selectedPlanPrice?.discount_percentage) / 100)
                     
                    }
                  </div>
                </div> */}
                <div className="checkout_page__summary_item">
                  <div className="">
                    {/* <div className="checkout_page__price">{formatCurrency(selectedPlanPrice?.unit_amount)}<span>/{selectedPlanPrice?.interval}</span></div> */}
                    <div className="" style={{marginBottom:"1rem", fontWeight:"bold", color:"var(--text-clr)"}}>{subscriptionPlans[0]?.name} Plan</div>
                    <div className="checkout_page__contents" style={{gap:"1rem"}}>
                      {standardPlanFeatures?.map((feature, index) => (
                        <div
                          key={`feature-${index}`}
                          className="checkout_page__contents_item"
                          style={{fontSize:"0.8rem"}}
                        >
                          <DoneOutlined
                            sx={{
                              fontSize: "14px",
                              color: "#126342",
                            }}
                          />
                          <div className="checkout_page__contents_text">{feature}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="checkout_page__summary_bottom">
              <div className="checkout_page__summary_item">
                <div className="checkout_page__summary_total">Total</div>
                <div className="checkout_page__summary_total_price">{formatCurrency(selectedPlanPrice?.unit_amount)}</div>
              </div>
              <hr style={{ background: "#CCC" }} />
              <FormGroup>
                <FormControlLabel
                  control={
                    <div className='checkout_page__summary_terms_area'>
                      <Checkbox
                        checked={agree}
                        onChange={() => { agree ? setAgree(false) : setTermsPop(true) }}
                        name='walltype_select_all'
                        sx={{
                          '&.Mui-checked': { color: "var(--primary-clr)" },
                          '& .MuiSvgIcon-root': { fontSize: 24 }
                        }}
                      />
                      <div className="checkout_page__summary_terms_text">I have read and agree to the <span style={{ color: "var(--primary-clr)", cursor: "pointer", fontWeight: "bold" }}>terms and conditions</span> of using IQLAND.</div>

                    </div>
                  }
                />
              </FormGroup>
              <div
                className={agree ? "lan_primary_button" : "lan_disabled_button"}
                style={{ width: "100%", marginTop: "3.5rem" }}
                onClick={agree ? handleClick : () => { }}
              >
                {!paymentLoading ? "Checkout" : <IQSpinner />}
              </div>
            </div>
          </div>
        </div>


        {/* <div className="checkout_page__plan" style={{ marginTop: "2rem" }}>
          <div className="checkout_page__price">{formatCurrency(selectedPlanPrice?.unit_amount)}<span>/{selectedPlanPrice?.interval}</span></div>
          <div className="checkout_page__plan_title">{subscriptionPlans[0]?.name} Plan</div>
          <div className="checkout_page__contents">
            {standardPlanFeatures?.map((feature, index) => (
              <div
                key={`feature-${index}`}
                className="checkout_page__contents_item"
              >
                <DoneOutlined
                  sx={{
                    fontSize: "14px",
                    color: "#126342",
                  }}
                />
                <div className="checkout_page__contents_text">{feature}</div>
              </div>
            ))}
          </div>
        </div> */}
      </div>

      {
        termsPop && <div className="terms_popup">
          <div className="terms_popup_content">
            <div className="terms_title">Terms and Conditions</div>
            <div className="term_popup_texts">
              <TermConditions />
            </div>
            <div className="term_popup_buttons">
              <div className="lan_btn_gold" onClick={() => { setTermsPop(false); setAgree(false) }}>Decline</div>
              <div className="lan_primary_button" onClick={() => { setTermsPop(false); setAgree(true); }}>Accept</div>
            </div>
            <div className="terms_popup_close" onClick={() => setTermsPop(false)}>
              X
            </div>
          </div>
        </div>
      }

    </>
  );
}

export default Payment;
