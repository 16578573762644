import React, { useEffect, useRef, useState } from 'react';
import './CoMainArea.css';
import ChatbotInputBar from '../../../components/chatbot/chatbot_input_bar/ChatbotInputBar';
import NewChat from './NewChat';
import CoSingleChat from './CoSingleChat';
import IQSpinner from '../../../components/util_component/iq_spinner/IQSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { llmRequest, getChatById, getFavoritePlans, getProfilePicture } from '../../../components/user_service/UserService';
import { preMessage } from '../data/PreMessage';
import AppraisalForm from './AppraisalForm';

function CoMainArea({ onNewChat }) {

    const [profile_img, setProfileImg] = useState("/images/default_profile.png");

    useEffect(() => {
        try {
            getProfilePicture()
                .then((response) => {
                    setProfileImg(response.presigned_url);
                    // setPreProfileImg(response.presigned_url)
                })
        }
        catch (error) {
            setProfileImg('/images/default_profile.png');
            // setPreProfileImg('/images/default_profile.png')
        }
    }, [])

    // get tab from url
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tab = query.get("chat");


    // get pre prompt
    const [prePrompt, setPrePrompt] = useState('');

    // favoirte plans
    const [favoirtPlans, setFavoritePlans] = useState();

    // get favorite plans
    async function getFavoritePlansOfUser() {
        try {
            const response = await getFavoritePlans();
            setFavoritePlans(response.data);
        }
        catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        try {
            getFavoritePlansOfUser();
        }
        catch (err) {
            // console.log(err);
        }
    }, [])

    // message input to handle pre cards click
    // share message between new chat area to input bar
    const [message, setMessage] = useState('');

    // loading
    const [loading, setLoading] = useState(false);


    // screen loading
    const [screenLoading, setScreenLoading] = useState(true);

    // full chat
    const [fullChat, setFullChat] = useState([]);

    //get previous message
    const [previousMsg, setPreviousMsg] = useState();

    // move to chat
    const navigate = useNavigate();

    // get previous llm response
    const preLLM = location.state?.llm;

    useEffect(() => {
        const fetchChat = async () => {
            setScreenLoading(true);
            try {
                if (!tab) {
                    setScreenLoading(false);
                    setFullChat([]);
                    sessionStorage.removeItem("chatID");

                } else {
                    setPrePrompt("")
                    // getting chats by id
                    const response = await getChatById(tab);

                    // console.log("full chat", response)

                    // set chat for ui
                    setFullChat(response.data.messages);

                    // if prev message has missing input
                    // console.log("pre msg", previousMsg)
                    previousMsg && preLLM && preLLM.missing_inputs.missing_inputs
                        &&
                        setFullChat(prevChats => [...prevChats, { sent_by: 'User', text: "Sure, I'll fill out the form with the additional details.", missingInputs: preLLM.missing_inputs, address: preLLM.address }]);


                    // show loading when page start
                    setScreenLoading(false);

                    // set chat id in session storage for remember last chat
                    sessionStorage.setItem("chatID", tab)

                    // set address for populate dashboard and remeber address
                    if (response.data.address) {
                        sessionStorage.setItem("address", response.data.address)
                    }
                    else {
                        sessionStorage.setItem("address", "NA")
                    }
                }
            } catch (error) {
                // console.error(error);
                setScreenLoading(false);
            }
        };
        fetchChat();
    }, [tab]); // Only run when `tab` changes

    // scroll to bottom
    const endRef = useRef(null);
    const scrollToBottom = () => {
        endRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [fullChat]);

    // handling send button click
    const handleSendButtonClick = async (msg) => {
        setLoading(true);

        // Update UI with user's message immediately
        setFullChat(prevChats => [...prevChats, { sent_by: 'User', text: msg }]);
        try {

            //getting missing input details to passs chat
            // setMissingInput(llm_response.data.missing_inputs);

            // Save the conversation if a tab is present
            if (tab) {

                const llm_response = await llmRequest({ user_message: msg, chat_id: tab });
                const bot_response = llm_response.llm_response;
                // console.log("llm response", llm_response);
                // console.log("chat_id", llm_response.chat_id);
                // console.log("tab", tab);

                setPreviousMsg(llm_response.user_prompt);

                if (tab !== llm_response.chat_id) {

                    // console.log(llm_response);
                    if (llm_response.new_chat === true) {
                        const date = new Date();
                        const newChat = { chat_id: llm_response.chat_id, chat_title: llm_response.address, updated_at: date.toISOString() };
                        onNewChat(newChat);
                    }

                    navigate(`/copilot?chat=${llm_response.chat_id}`, { state: { llm: llm_response } });


                }
                else {

                    sessionStorage.setItem("address", llm_response.address)

                    // Update UI with bot's response
                    setFullChat(prevChats => [...prevChats, { sent_by: 'Bot', text: bot_response, map: llm_response.map, missingInputs: llm_response.missing_inputs, address: llm_response.address, plans: llm_response.plans, sources: llm_response.sources }]);

                    llm_response.missing_inputs.missing_inputs
                        &&
                        setFullChat(prevChats => [...prevChats, { sent_by: 'User', text: "Sure, I'll fill out the form with the additional details.", map: llm_response.map, missingInputs: llm_response.missing_inputs, address: llm_response.address }]);
                }

            } else {
                const llm_response = await llmRequest({ user_message: msg });
                setPreviousMsg(llm_response.user_prompt);
                if (llm_response.new_chat === true) {
                    let chatTitle = llm_response.address ? llm_response.address !== "NA" ? llm_response.address : msg.substring(0, 25) : msg.substring(0, 25);
                    // console.log(chatTitle);
                    const date = new Date();
                    const newChat = { chat_id: llm_response.chat_id, chat_title: chatTitle, updated_at: date.toISOString() };
                    onNewChat(newChat);
                }

                navigate(`/copilot?chat=${llm_response.chat_id}`, { state: { llm: llm_response } });



            }

        } catch (error) {
            // console.error("Error sending message:", error);
            setFullChat(prevChats => [...prevChats, { sent_by: 'Bot', text: "Oops! Something went wrong on our end. Please give it another try." }]);

        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='co_main_area'>
            <div className='co_main_area_chat_container'>
                <div className='co_main_area__chat_area'>
                    {/* Chat messages */}
                    {fullChat.length !== 0 ? (
                        fullChat.map((chat, index) => (
                            // <CoSingleChat key={index} chat={chat} isLastMessage={fullChat.length - 1 === index} preMsg={previousMsg} handleSubmit={handleSendButtonClick}/>
                            <CoSingleChat key={index} chat={chat} preMsg={previousMsg} handleSubmit={handleSendButtonClick} setFullChat={setFullChat} favoirtPlans={favoirtPlans} profile_image={profile_img} />
                        ))
                    ) : (
                        !screenLoading && <NewChat setInput={setPrePrompt} selectedPrompt={prePrompt} />
                    )}

                    {(loading || screenLoading) && <IQSpinner />}

                    {fullChat.length !== 0 && (
                        <div style={{ height: "64px" }} ref={endRef}>
                            {/* This div represents the bottom of the chat area */}
                            &nbsp;
                        </div>
                    )}
                </div>
            </div>

            <div className='co_main_area__input_bar'>
                <ChatbotInputBar onSendMessage={handleSendButtonClick} isLoading={loading || screenLoading} message={message} setMessage={setMessage} prePrompt={prePrompt} setPreprompt={setPrePrompt} />
            </div>

            {/* {screenLoading && <IQLoading message="Fetching data..." />} */}
            {/* {
                message.includes(preMessage[1].content) && <AppraisalForm setPrePrompt={setPrePrompt}/>
            } */}
        </div>
    );
}

export default CoMainArea;
