import React from 'react'
import TableRecord from './TableRecord'

// setting the row of data
function TableRow({data, handleImageClick}) {

    //getting keys from object as a list
    const keys = Object.keys(data || {})
  return (
    <tr>
        {
            keys.map((key, index) => (
                <TableRecord key={index} attribute={key} value={data[key]} handleImageClick={handleImageClick}/>
            ))
        }
    </tr>
  )
}

export default TableRow