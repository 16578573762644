import React from 'react'
import './IQList.css'


function IQList({ items }) {
    return (
        <ul className='IQList'>
            {
                items.map((i, index) => (

                    <li onClick={i.action ? () => i.action() : null} key={index}>
                        {i.icon && <img src={`/images/co_reports/${i.icon}`} alt='a' />}
                        {i.text}
                    </li>

                ))
            }
        </ul>
    )
}

export default IQList