import axios from "axios";
import { useEffect, useState, createContext } from "react";
import { logout } from "./AuthService";

const API_URL = process.env.REACT_APP_BACK_END_END_POINT;

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`${API_URL}/session/`, { withCredentials: true })
      .then(response => {
        setUser(response.data.username);
        setLoading(false);
      })
      .catch(() => {
        setUser(null);
        setLoading(false);
      });
  }, []);

  const logOut = async () => {
    try {
      await logout();
      setUser(null);
    } catch (error) {
      // console.error("Logout failed:", error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, setUser, logOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
