import React, { useEffect, useRef, useState } from 'react'
import './CoReports.css'
import UserNav from '../../components/user_nav/UserNav'
import { Alert, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { MoreVertOutlined } from '@mui/icons-material';
import { getReports, sendReportToEmail } from '../../components/user_service/UserService';
import { LoadingSkeleton } from '../../components/skeleton_loading/SkeletonLoading';
import OtherNav from '../../components/other_nav/OtherNav';
import { useLocation, useNavigate } from 'react-router-dom';
import ReportPage from './ReportPage';
import ReportAction from './ReportAction';

function CoReports() {

    const [shareModal, setShareModal] = useState(false);
    const [reportID, setReportID] = useState();
    const [email, setEmail] = useState("");

    // get tab from url
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const tab = query.get("id");

    // const [keyword, setKeyword] = useState("");

    const [mobileSideBar, setMobileSidebar] = useState(false);

    const handleViewReport = (reportId) => {
        navigate(`/reports?id=${reportId}`);
    };

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState("");
    const [toastStatus, setToastStatus] = useState("success");

    const sendReportMail = (report_id, email) => {
        try {
            sendReportToEmail(report_id, email);
            // console.log("mail sent");

            setToastStatus("success")
            setToastText("Report sent")
            setToastShow(true)

        }
        catch (err) {
            // console.log(err.message)
            setToastStatus("warning")
            setToastText("Somthing went wrong!")
            setToastShow(true)
        }
    }


    // actions list
    const action = [
        {
            icon: "view_icon.svg",
            text: "View",
            action: (report_id) => handleViewReport(report_id)
        },
        // {
        //     icon: "download.svg",
        //     text: "Download",
        //     action: null
        // },
        {
            icon: "share_icon.svg",
            text: "Share",
            action: (report_id) => {
                // sendReportMail(report_id)
                setShareModal(true)
                setActionVisibleIndex(null);
                setReportID(report_id);
            }
        },
        // {
        //     icon: "delete_icon.svg",
        //     text: "Delete",
        //     action: null
        // }

    ]

    const [actionVisibleIndex, setActionVisibleIndex] = useState(null);

    // handle outside click
    const actionsRef = useRef(null);
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setActionVisibleIndex(null);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        }).format(number);
    }
    const header_style = {
        backgroundColor: "var(--primary-clr)",
        borderRadius: "0.75rem 0.75rem 0 0",
        color: "white",
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        textTransform: "uppercase"
    }

    const body_style = {
        fontSize: "0.875rem",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.00938em",
        textAlign: "left",
        backgroundColor: "#F9F9F9",
        color: "#4C495A"
    }

    const [reports, setReports] = useState();

    async function getAllReports() {
        const response = await getReports();
        // console.log(response.data);
        return setReports(response.data);
    }

    useEffect(() => {
        getAllReports();
    }, [])


    //hide mobile other nav bar out side click
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (mobileSideBar && !event.target.closest('.other_nav')) {
                setMobileSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [mobileSideBar]);

    return (
        <div className='co_reports'>
            <UserNav tab="r" handleBurgerClick={()=> setMobileSidebar(!mobileSideBar)} />
            {
                tab ?
                    <ReportPage tab={tab} />
                    :
                    <div>
                        <div className='co_reports__search_area'>
                            <h6>Reports</h6>
                            {/* <div className='co_reports__input_with_icon'>
                                <div className='co_reports__input_with_button'>
                                    <input type='text' placeholder='Search' value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                                    <div className='co_reports__keyword_search_btn'><img src='/images/dashboard_new/search_icon.svg' alt='filter' /></div>
                                </div>
                            </div> */}
                        </div>

                        <div>
                            {
                                reports ? <TableContainer component={Paper} sx={{ padding: "1.38rem 3rem 1rem 3rem", height: "100%", width: "100%" }}>
                                    <Table sx={{ width: "100%", height: "100%" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow sx={header_style}>
                                                <TableCell className='co_reports__head_cell' >Address </TableCell>
                                                <TableCell className='co_reports__head_cell' align="center">Project cost</TableCell>
                                                <TableCell className='co_reports__head_cell' align="center">Equity</TableCell>
                                                <TableCell className='co_reports__head_cell' sx={{ width: "4rem" }} align="center">Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody sx={{ width: "100%" }}>
                                            {reports.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={body_style}
                                                >
                                                    <TableCell className='co_reports__body_cell' component="th" scope="row">
                                                        {row.address}
                                                    </TableCell>
                                                    <TableCell className='co_reports__body_cell' align="center">{row.kpis ? formatCurrency(row.kpis.total_project_cost) : 'NA'}</TableCell>
                                                    <TableCell className='co_reports__body_cell' align="center">{row.kpis ? formatCurrency(row.kpis.equity) : 'NA'}</TableCell>
                                                    <TableCell className='co_reports__body_cell' align="center">
                                                        <div style={{ position: "relative" }} onClick={() => setActionVisibleIndex(index)}><MoreVertOutlined className='co_reports__more_actions' /></div>
                                                        {actionVisibleIndex === index && <div ref={actionsRef} ><ReportAction items={action} report_id={row.id} /></div>}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                                    : <div style={{ width: "100%", padding: "1rem 3rem" }}><LoadingSkeleton width={"100%"} /></div>
                            }
                        </div>
                    </div>
            }

                 <OtherNav isActive={mobileSideBar} setIsActive={setMobileSidebar}/>

            {
                shareModal &&
                <div className='share_modal__container'>
                    <div className='share_modal'>
                        <div className='share_modal__title'>Share Your report</div>
                        <input type="email" placeholder='Enter email address' value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <div className='share_modal__buttons'>
                            <div className='lan_primary_button' onClick={() => { sendReportMail(reportID, email); setShareModal(false); setReportID(undefined); setEmail("") }}>Share</div>
                            <div className='lan_secondary_button' onClick={() => { setShareModal(false); setReportID(undefined) }}>Cancel</div>
                        </div>

                    </div>
                </div>
            }

            <Snackbar
                open={toastShow}
                autoHideDuration={6000}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>
        </div>
    )
}

export default CoReports