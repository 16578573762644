import React, { useEffect, useState } from 'react'
import './ProfInfo.css'
import UserNav from '../../components/user_nav/UserNav'
import SettingSideBar from './SettingSideBar'
import { useFormik } from 'formik'
import { Alert, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Snackbar } from '@mui/material'
import { getOtherProfileSetting, patchOtherProfileSetting } from '../../components/auth/AuthService'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'
import * as Yup from 'yup';
import SettingMobileNav from './SettingMobileNav'

function ProfInfo() {
    const [mobileSideBar, setMobileSidebar] = useState(false);

    const [toastShow, setToastShow] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastStatus, setToastStatus] = useState('success');

    const [loading, setLoading] = useState(true);


    const job_titles = [
        "None",
        "Accountant",
        "Actor",
        "Actuary",
        "Architect",
        "Assistant",
        "Associate",
        "Auditor",
        "Banker",
        "Barista",
        "Sales",
        "Waiter",
        "Writer",
        "Other"]

    const prof_formik = useFormik({
        initialValues: {
            industry: '',
            company: '',
            job_title: 'None',
            years_of_experience: '',
            home_built_per_year: '',
            license_number: '',
        },

        validationSchema: Yup.object({
            years_of_experience: Yup.number()
                .integer('Years of experience must be an integer'),
            home_built_per_year: Yup.number()
                .integer('Homes built per year must be an integer'),
            license_number: Yup.number()
                .integer('License number must be an integer'),
        }),

        onSubmit: async (values) => {

            await patchOtherProfileSetting(values);
            setToastText("Updated");
            setToastStatus("success");
            setToastShow(true);
        }


    });

    const [bio, setBio] = useState("");

    const handleBioButtonClick = async () => {
        try {
            await patchOtherProfileSetting({ bio: bio })
        }
        catch (error) {
            setToastText(error.message);
            setToastStatus("warning");
            setToastShow(true);
            // console.log(error);
        }
    };

    useEffect(() => {
        getOtherProfileSetting()
            .then((response) => {
                // console.log(response);
                prof_formik.setFieldValue('industry', response.data.industry);
                prof_formik.setFieldValue('company', response.data.company);
                if (response.data.job_title === null) {
                    prof_formik.setFieldValue('job_title', job_titles[0]);
                }
                else {

                    prof_formik.setFieldValue('job_title', response.data.job_title);
                }

                prof_formik.setFieldValue('years_of_experience', response.data.years_of_experience);
                prof_formik.setFieldValue('home_built_per_year', response.data.home_built_per_year);
                prof_formik.setFieldValue('license_number', response.data.license_number);
                setBio(response.data.bio);
                setLoading(false);
            })
    }, [])

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (mobileSideBar && !event.target.closest('.setting_other_nav__mobile_active')) {
                setMobileSidebar(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [mobileSideBar]);


    return (
        <div className='prof_info'>
            <UserNav handleBurgerClick={() => setMobileSidebar(!mobileSideBar)} />
            <div className='prof_info__container'>
                <SettingSideBar tab={"2"} />
                <div className='prof_info__main'>
                    <div className='prof_info__part'>
                        <h5>Professional Information</h5>
                        {
                            !loading ?
                                <div className='prof_info__inputs'>
                                    <div className='prof_info__inputs_sets'>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={prof_formik.touched.industry && Boolean(prof_formik.errors.industry)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Industry</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Industry"
                                                name="industry"
                                                value={prof_formik.values.industry? prof_formik.values.industry :""}
                                                onChange={prof_formik.handleChange}
                                                onBlur={prof_formik.handleBlur}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={prof_formik.touched.company && Boolean(prof_formik.errors.company)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Company</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Company"
                                                name="company"
                                                value={prof_formik.values.company? prof_formik.values.company : ""}
                                                onChange={prof_formik.handleChange}
                                                onBlur={prof_formik.handleBlur}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='prof_info__inputs_sets'>
                                        <FormControl sx={{ flexShrink: 0, flexGrow: "1", flex: "1" }}>
                                            <InputLabel sx={{
                                                '&.Mui-focused': {
                                                    color: 'var(--primary-clr)',
                                                },
                                            }} id="demo-simple-select-label">Job Title</InputLabel>
                                            <Select
                                                sx={{
                                                    height: "3rem",
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'var(--primary-clr)',
                                                    },
                                                }}

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={prof_formik.values.job_title? prof_formik.values.job_title : job_titles[0]}
                                                label="Job Title"
                                                onChange={(e) => prof_formik.setFieldValue('job_title', e.target.value)}
                                            >
                                                {
                                                    job_titles.map((value, index) => (
                                                        <MenuItem key={index} value={value}>{value}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        <FormControl sx={{ flex: "1" }} variant="outlined" error={prof_formik.touched.years_of_experience && Boolean(prof_formik.errors.years_of_experience)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Years of expreience</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Years of expreience"
                                                name="years_of_experience"
                                                value={prof_formik.values.years_of_experience? prof_formik.values.years_of_experience : ""}
                                                onChange={prof_formik.handleChange}
                                                onBlur={prof_formik.handleBlur}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className='prof_info__inputs_sets'>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={prof_formik.touched.home_built_per_year && Boolean(prof_formik.errors.home_built_per_year)}>
                                            <InputLabel htmlFor="outlined-adornment-first">Development projects per year</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="text"
                                                label="Development projects per year"
                                                name="home_built_per_year"
                                                value={prof_formik.values.home_built_per_year? prof_formik.values.home_built_per_year : "0"}
                                                onChange={prof_formik.handleChange}
                                                onBlur={prof_formik.handleBlur}
                                            />
                                        </FormControl>
                                        <FormControl sx={{ width: "100%" }} variant="outlined" error={prof_formik.touched.license_number && Boolean(prof_formik.errors.license_number)}>
                                            <InputLabel htmlFor="outlined-adornment-first">License Number</InputLabel>
                                            <OutlinedInput
                                                id="outlined-adornment-first"
                                                type="number"
                                                label="License Number"
                                                name="license_number"
                                                value={prof_formik.values.license_number? prof_formik.values.license_number : ""}
                                                onChange={prof_formik.handleChange}
                                                onBlur={prof_formik.handleBlur}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                :
                                <LoadingSkeletonText width={"100%"} height={"5rem"} />
                        }
                        <button className='prof_info__btn' onClick={() => prof_formik.handleSubmit()}>Update</button>
                    </div>


                    <div className='prof_info__part'>
                        <h5>About Me / Company</h5>
                        <div className='prof_info__inputs'>
                            <div className='prof_info__inputs_sets'>

                                {
                                    !loading ?
                                        <textarea
                                            className='text_area_style'
                                            placeholder="Short Bio or Company Description"
                                            value={bio? bio : ""}
                                            onChange={(e) => setBio(e.target.value)}
                                        />
                                        :
                                        <LoadingSkeletonText width={"100%"} height={"5rem"} />
                                }
                            </div>
                        </div>
                        <button className='prof_info__btn' onClick={() => handleBioButtonClick()}>Update</button>
                    </div>



                </div>
            </div>
            <Snackbar
                open={toastShow}
                autoHideDuration={1500}
                onClose={() => setToastShow(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    severity={toastStatus}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {toastText}
                </Alert>
            </Snackbar>

            <SettingMobileNav tab={"2"} isActive={mobileSideBar} setIsActive={setMobileSidebar} />

        </div>
    )
}

export default ProfInfo