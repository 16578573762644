export const viewPlanData = [
    {
        id: 1,
        image: "view_plan_1.jpg",
        icons: [
            {
                icon_position: {
                    top: "15%",
                    left: "45%"
                },
                pop_image: "Structure_type.png",
                pop_text: "Structure Type"

            },
        ],


    },
    {
        id: 2,
        image: "view_plan_2.jpg",
        icons: [
        ],
    },
    {
        id: 3,
        image: "view_plan_3.jpg",
        icons: [
            {
                icon_position: {
                    top: "37%",
                    left: "80%"
                },
                pop_image: "Lot_size.png",
                pop_text: "Lot Size"


            },
            {
                icon_position: {
                    top: "60%",
                    left: "55%"
                },
                pop_image: "Frontage.png",
                pop_text: "Frontage"

            },
            {
                icon_position: {
                    top: "29%",
                    left: "86%"
                },
                pop_image: "Front_setback.png",
                pop_text: "Front Setback"

            },
            {
                icon_position: {
                    top: "29%",
                    left: "90%"
                },
                pop_image: "Rear_setback.png",
                pop_text: "Rear Setback"

            },
            {
                icon_position: {
                    top: "29%",
                    left: "94%"
                },
                pop_image: "Side_setback.png",
                pop_text: "Side Setback"

            },
            {
                icon_position: {
                    top: "60%",
                    left: "90%"
                },
                pop_image: "Floor_Area_Ratio.png",
                pop_text: "Floor Area Ratio"

            },
            {
                icon_position: {
                    top: "53%",
                    left: "89%"
                },
                pop_image: "Lot_coverage.png",
                pop_text: "Lot Coverage"

            }
        ],
    },
    {
        id: 4,
        image: "view_plan_4.jpg",
        icons: [
        ],
    },

    {
        id: 5,
        image: "view_plan_5.jpg",
        icons: [
        ],
    },
    {
        id: 6,
        image: "view_plan_6.jpg",
        icons: [
            
        ],
    },
    {
        id: 7,
        image: "view_plan_7.jpg",
        icons: [
            {
                icon_position: {
                    top: "15%",
                    left: "22%"
                },
                pop_image: "Height.png",
                pop_text: "Height"

            },
            {
                icon_position: {
                    top: "52%",
                    left: "30%"
                },
                pop_image: "Height.png",
                pop_text: "Height"

            },
        ],
    },
    {
        id: 8,
        image: "view_plan_8.jpg",
        icons: [
        ],
    },
    {
        id: 9,
        image: "view_plan_9.jpg",
        icons: [
        ],
    }
    ,
    {
        id: 10,
        image: "view_plan_10.jpg",
        icons: [
        ],
    }
    ,
    {
        id: 11,
        image: "view_plan_11.jpg",
        icons: [
        ],
    }
    ,
    {
        id: 12,
        image: "view_plan_12.jpg",
        icons: [
        ],
    }
    ,
    {
        id: 13,
        image: "view_plan_13.jpg",
        icons: [
        ],
    }


    
];