import React, { useContext } from 'react'
import './LanHome.css'
import NavBar from '../../components/navbar/NavBar'
import { ArrowRightAltRounded, AttachMoneyOutlined, BoltOutlined, CheckOutlined, SearchOutlined } from '@mui/icons-material'
import LanReviews from './components/lan_reviews/LanReviews'
import ActionSection from './components/action_section/ActionSection'
import JoinIqlandToday from './components/join_iqland_today/JoinIqlandToday'
import Footer from '../../components/footer/Footer'
import { useNavigate } from 'react-router-dom'
import FaqComp from './components/faq_comp/FaqComp'
import { faqData } from './data/FaqData'
import { AuthContext } from '../../components/auth/AuthenticationContext'
import { LoadingSkeletonText } from '../../components/skeleton_loading/SkeletonLoading'
import { customerReviewData } from './data/CustomerReviewsData'
import SolutionSlideShow from './components/slide_show/SolutionSlideShow'

function LanHome() {

  const navigate = useNavigate();

  const { user, loading } = useContext(AuthContext);

  return (
    <div className='lan_home'>
      <NavBar />
      <div className='land_home__hero_new' style={{ paddingTop: "5rem" }}>
        <div className='land_home__hero_left_new'>
          <div data-aos="fade-up">
            <h1 className='lan_title'>Building a home is hard.</h1>
            <h1 className='lan_title'>IQLAND makes it easy.</h1>
          </div>
          <p data-aos="fade-up" className='lan_sub_title'>IQLAND is the essential research tool for residential real estate developers, agents, builders, and homeowners.</p>
          {
            !loading ?
              <div
                data-aos="fade-up"
                className='lan_btn_white'
                onClick={() => user ? navigate("/copilot") : navigate("/register")}>
                Get Started
              </div>
              :
              <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
          }
        </div>
        <div data-aos="fade-left" className='land_home__hero_right_new'>
          <img src='/images/lan_home/build_home.png' alt='home' />
        </div>
      </div>
      {/* <div className='lan_logo_list'> */}
      {/* {
          [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
            <div className='lan_logo_list_item' key={index}>
              <img src='/images/lan_home/la_gg_circle.svg' alt='logo' />
              <div>BUILDING</div>
            </div>
          ))
        } */}
      {/* </div> */}

      <div className='lan_home__motto_area' style={{ backgroundColor: "rgb(233, 255, 246)" }}>
        <h1 className='iqland_motto' data-aos="fade-right">Land intelligence done. <span>Fast.</span></h1>
        <p className='lan_normal_text' data-aos="fade-left">We make property development simple and quick. Whether you're a builder or investor, our tools help you navigate zoning, secure permits, and plan smarter—all in record time. Plus, our AI-powered copilot is always ready to answer your questions and guide you every step of the way. IQLAND turns complexity into clarity, saving you time and money.</p>
        <div className='lan_home__motto_area_action'>
          <div className='lan_free_trial_text' data-aos="fade-right">Start your 7-day free trial</div>
          <ArrowRightAltRounded/>
          {
            !loading ?
              <div
                data-aos="fade-right"
                className='lan_primary_button'
                onClick={() => user ? navigate("/copilot") : navigate("/register")}>
                Get Started
              </div>
              :
              <LoadingSkeletonText width={"10rem"} height={"2.5rem"} />
          }
        </div>
      </div>

      <div className='land_home__copilot' style={{ padding: "2rem 3rem", marginTop: "2rem" }}>
        <div className='iqland_motto__left' data-aos="fade-right">
          <img src='/images/lan_home/copilot_1.png' alt='copilot' />
        </div>
        <div className='iqland_motto__right' data-aos="fade-left">
          <h1 className='iqland_motto'>Copilot</h1>
          <p className='lan_normal_text'>IQLAND’s Copilot is your smart AI guide, making land development easier and faster:</p>
          <div className='lan_check_list'>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Instant Construction Cost Estimates</b> <br />Quickly calculate what it will take to build.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Project Value Assessments</b> <br />Know the potential worth of your project.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Optimal Land Use Suggestions</b> <br />Get recommendations to maximize your property’s potential.</p></div>
          </div>
          <a href='/products/copilot' style={{ textDecoration: "none" }}><div className='lan_btn_green'>Learn More</div></a>
        </div>
      </div>

      <div className='land_home__dashboard' style={{ padding: "2rem 3rem", marginTop: "2rem", backgroundColor: "#F2FFFA" }}>

        <div className='iqland_motto__right' data-aos="fade-left">
          <h1 className='iqland_motto'>SmartBuild</h1>
          <p className='lan_normal_text'>SmartBuild transforms land development with tools that provide precision and insights:</p>
          <div className='lan_check_list'>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>30K+ Architectural Plans</b> <br /> Access plans selected to match your parcel’s specific zoning requirements.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Visualize Key Metrics</b> <br /> Compare critical indicators like cost, profitability, and potential across multiple plans.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Comparable Properties Table</b> <br /> Explore a table with links to similar projects and properties for better-informed decisions.</p></div>
          </div>
          <a href='/products/dashboard' style={{ textDecoration: "none" }}><div className='lan_btn_green'>Learn More</div></a>
        </div>
        <div className='iqland_motto__left' data-aos="fade-right">
          <img src='/images/lan_home/desktop_dashboard.png' alt='smart build' />
        </div>
      </div>

      <div className='land_home__copilot' style={{ padding: "2rem 3rem", marginTop: "2rem" }}>
        <div className='iqland_motto__left' data-aos="fade-down">
          <img src='/images/lan_home/appraisal_tablet.png' alt='appraisal' />
        </div>
        <div className='iqland_motto__right' data-aos="fade-left">
          <h1 className='iqland_motto'>Appraisal IQ</h1>
          <p className='lan_normal_text'>Get fast, data-driven property insights with our Instant Appraisal Report—the smarter way to understand your land’s value:</p>
          <div className='lan_check_list'>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Non-Certified Appraisal in Minutes</b> <br /> Receive a comprehensive report instantly, without the wait.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Comparable Sales Analysis</b> <br /> See how your property stacks up against similar ones in your area.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Cost Approach Valuation</b> <br /> Understand your property’s value based on construction and replacement costs.</p></div>
            <div className='lan_check_list_item'><CheckOutlined style={{ color: "var(--primary-clr)" }} /><p><b>Income Approach Insights</b> <br /> Evaluate potential income and ROI for investment properties.</p></div>
          </div>

          <p className='lan_normal_text'>Whether you're a developer, investor, or property owner, our Instant Appraisal Report equips you with reliable data to make confident decisions—faster than ever.</p>
          <a href='/products/appraisar' style={{ textDecoration: "none" }}><div className='lan_btn_green'>Learn More</div></a>
        </div>
      </div>

      <div className='lan_home__why_iqland' style={{ backgroundColor: "#F2FFFA" }}>
        <div className='lan_home__why_iqland_top'>
          <div className='lan_sub_title_2' data-aos="fade-right">Why <span>IQLAND?</span></div>
          {/* <p className='lan_normal_text'>IQLAND's Copilot is your AI-enhanced guide, simplifying land development by answering key questions</p> */}
        </div>
        <div className='lan_home__why_iqland_cards'>
          <div className='lan_home__why_iqland_card' data-aos="fade-up">
            <BoltOutlined sx={{ color: "var(--secondary-clr)", backgroundColor: "var(--primary-clr)", borderRadius: "50%", width: "4rem", height: "4rem", padding: "1rem" }} />
            <div className='lan_card__title'>Instant Feasibility</div>
            <p className='lan_normal_text'>Get a complete picture of your project’s potential with instant insights into costs, profitability, and viability—all powered by AI. Make informed decisions faster and more confidently.</p>
          </div>
          <div className='lan_home__why_iqland_card' data-aos="fade-down">
            <AttachMoneyOutlined sx={{ color: "var(--secondary-clr)", backgroundColor: "var(--primary-clr)", borderRadius: "50%", width: "4rem", height: "4rem", padding: "1rem" }} />
            <div className='lan_card__title'>Real Estate Copilot</div>
            <p className='lan_normal_text'>Think of our Copilot as having a residential real estate expert by your side 24/7. Get quick answers, tailored recommendations, and the confidence to move forward with your project.</p>
          </div>
          <div className='lan_home__why_iqland_card' data-aos="fade-up">
            <SearchOutlined sx={{ color: "var(--secondary-clr)", backgroundColor: "var(--primary-clr)", borderRadius: "50%", width: "4rem", height: "4rem", padding: "1rem" }} />
            <div className='lan_card__title'>Save Weeks on Due Diligence</div>
            <p className='lan_normal_text'>What used to take weeks, we reduce to minutes. IQLAND’s tools streamline due diligence, giving you the information you need faster than ever before.</p>
          </div>
        </div>
      </div>

      {/* <div className='lan_home__solution'>
        <div className='lan_home__solution__left' data-aos="fade-right">
          <div className='lan_title' style={{ color: "white" }}>Solution</div>
          <div className='lan_gold_subtitle'>Real Estate Developers</div>
          <p className='lan_normal_text' style={{ color: "white" }}>IQLAND revolutionizes real estate development by leveraging AI to offer instant feasibility assessments, precise construction cost estimations, and dynamic profitability analysis, streamlining the path to project success. Discover a world where potential is clearly defined and your vision for development is realized with unmatched efficiency.</p>
          <a href='/solutions/developers' style={{ textDecoration: "none" }}><div className='lan_btn_gold' onClick={() => navigate("/solutions/developers")}>Explore More</div></a>
        </div>
        <div className='lan_home__solution__right' data-aos="fade-left">
          <img src='/images/lan_home/architect_1.png' alt='architect' />
        </div>
      </div> */}

      <div className='lan_solutions_section'>
        <SolutionSlideShow/>
      </div>

      <div className='lan_faqs'>
        <div className='lan_title' style={{ color: "var(--primary-clr)" }} data-aos="fade-up">FAQs</div>
        <FaqComp data={faqData} />
      </div>

      <div className='lan_reviews' style={{ marginBottom: "2rem" }}>
        <div className='iqland_motto' style={{ textAlign: "center" }} data-aos="fade-right">What our customers <span>say</span></div>
        <div className='lan_review_cards' data-aos="fade-left">
          {
            customerReviewData.map((item, index) => (
              <LanReviews key={index} comments={item.review} cusName={item.name} job={item.job} image={item.profile} />
            ))
          }
        </div>
      </div>

      <ActionSection />
      <JoinIqlandToday />
      <Footer />

    </div>
  )
}

export default LanHome