export const zoning_column = ["zoning class", "item", "value", "code requirement", "status", "image"];

export const zoning = [

    {
        "Zoning Class": "R4",
        "Item": "Structure Type",
        "Value": "Single Family Dwelling",
        "Code Requirement": "Pass",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE"
        },
        "Image": "z1.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Minimum Lot Size",
        "Value": "18116",
        "Code Requirement": "9000",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.007MILORE"
        },
        "Image": "Lot_Size.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Minimum Frontage",
        "Value": "85 ft",
        "Code Requirement": "70 ft",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.007MILORE"
        },
        "Image": "Frontage.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Minimum Front Setback",
        "Value": "35 ft",
        "Code Requirement": "35 ft",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.008MIYARE"
        },
        "Image": "Front_Setback.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Minimum Rear Setback",
        "Value": "15 ft",
        "Code Requirement": "15 ft",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.008MIYARE"
        },
        "Image": "Rear_Setback.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Minimum Side Setback",
        "Value": "7 ft",
        "Code Requirement": "7 ft",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.008MIYARE"
        },
        "Image": "Side_Setback.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Maximum Height",
        "Value": "18 ft",
        "Code Requirement": "35 ft",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.009MAHE"
        },
        "Image": "Height.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Minimum Off Street Parking",
        "Value": "NA",
        "Code Requirement": "1",
        "Status": {
            value: "NA",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.010MIOREPARE"
        },
        "Image": "Parking.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Maximum Floor to Area Ratio",
        "Value": "8.9%",
        "Code Requirement": "40%",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.008MIYARE"
        },
        "Image": "Floor_To_Area_Ratio_FAR.png"
    },
    {
        "Zoning Class": "R4",
        "Item": "Maximum Lot Coverage",
        "Value": "12.4%",
        "Code Requirement": "35%",
        "Status": {
            value: "Pass",
            url: "https://library.municode.com/ga/atlanta/codes/code_of_ordinances?nodeId=PTIIICOORANDECO_PT16ZO_CH6SIMIREDIRE_S16-06.008MIYARE"
        },
        "Image": "Lot_Coverage.png"
    },
]


export const plumbing = [
    {
        "Type of Check": "Water Supply System",
        "Item": "Compliance with minimum pipe diameter:",
        "Value": "3 inches",
        "Code Requirement": "2 inches",
        "Status": {
            "value": "Compliant",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution"
        }
    },
    {
        "Type of Check": "Water Supply System",
        "Item": "Backflow prevention: [Required],",
        "Value": "Yes",
        "Code Requirement": "Provided",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution"
        }
    },
    {
        "Type of Check": "Water Supply System",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution"
        }
    },
    {
        "Type of Check": "Drainage System",
        "Item": "Compliance with minimum slope for horizontal drainage pipes:",
        "Value": "0.25",
        "Code Requirement": "0.2",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-11-storm-drainage"
        }
    },
    {
        "Type of Check": "Drainage System",
        "Item": "Proper venting of drainage systems:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-11-storm-drainage"
        }
    },
    {
        "Type of Check": "Drainage System",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-11-storm-drainage"
        }
    },
    {
        "Type of Check": "Vent System",
        "Item": "Vent pipe sizes and locations meet requirements:",
        "Value": "32 mm",
        "Code Requirement": "32 mm",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-9-vents"
        }
    },
    {
        "Type of Check": "Vent System",
        "Item": "Venting for traps:",
        "Value": "Properly Vented",
        "Code Requirement": "Properly Vented",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-9-vents"
        }
    },
    {
        "Type of Check": "Vent System",
        "Item": "Venting for traps:",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-9-vents"
        }
    },
    {
        "Type of Check": "Traps and Interceptors",
        "Item": "Presence and proper installation of traps at each fixture:",
        "Value": "Present",
        "Code Requirement": "Present",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-10-traps-interceptors-and-separators"
        }
    },
    {
        "Type of Check": "Traps and Interceptors",
        "Item": "Grease interceptor requirements for applicable fixtures:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-10-traps-interceptors-and-separators"
        }
    },
    {
        "Type of Check": "Traps and Interceptors",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-10-traps-interceptors-and-separators"
        }
    },
    {
        "Type of Check": "Fixture Count and Types",
        "Item": "Compliance with minimum required fixtures for the type of occupancy:",
        "Value": "Yes",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-4-fixtures-faucets-and-fixture-fittings"
        }
    },
    {
        "Type of Check": "Fixture Count and Types",
        "Item": "Accessibility of fixtures per ADA standards:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-4-fixtures-faucets-and-fixture-fittings"
        }
    },
    {
        "Type of Check": "Fixture Count and Types",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-4-fixtures-faucets-and-fixture-fittings"
        }
    },
    {
        "Type of Check": "Hot Water Supply",
        "Item": "Adequacy of hot water supply in compliance with IBC:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution"
        }
    },
    {
        "Type of Check": "Hot Water Supply",
        "Item": "Temperature control mechanisms in place:",
        "Value": "Note Present",
        "Code Requirement": "Present",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution"
        }
    },
    {
        "Type of Check": "Hot Water Supply",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Fail",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution"
        }
    },
    {
        "Type of Check": "Building Sewer System",
        "Item": "Connection to public sewer or private disposal system:",
        "Value": "Connected",
        "Code Requirement": "Connected",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec604"
        }
    },
    {
        "Type of Check": "Building Sewer System",
        "Item": "Compliance with IBC for sewer size and slope:",
        "Value": "Compliant",
        "Code Requirement": "Complieant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec604"
        }
    },
    {
        "Type of Check": "Building Sewer System",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec604"
        }
    },
    {
        "Type of Check": "Water Heater Installation",
        "Item": "Compliance with installation and venting requirements:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec607"
        }
    },
    {
        "Type of Check": "Water Heater Installation",
        "Item": "Temperature and pressure relief valve installation:",
        "Value": "Installed",
        "Code Requirement": "Installed",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec607"
        }
    },
    {
        "Type of Check": "Water Heater Installation",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec607"
        }
    },
    {
        "Type of Check": "Plumbing Materials and Joints",
        "Item": "Use of approved materials for pipes and fittings:",
        "Value": "Approved",
        "Code Requirement": "Approved",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec605"
        }
    },
    {
        "Type of Check": "Plumbing Materials and Joints",
        "Item": "Compliance with joint standards (e.g., soldering, solvent cement):",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec605"
        }
    },
    {
        "Type of Check": "Plumbing Materials and Joints",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/chapter-6-water-supply-and-distribution#IPC2018P5_Ch06_Sec605"
        }
    },
    {
        "Type of Check": "Testing and System Integrity",
        "Item": "Pressure test for water and drainage systems",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/appendix-c-structural-safety"
        }
    },
    {
        "Type of Check": "Testing and System Integrity",
        "Item": "Leakage or cross-connection issues:",
        "Value": "Indentified",
        "Code Requirement": "NA",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/appendix-c-structural-safety"
        }
    },
    {
        "Type of Check": "Testing and System Integrity",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IPC2018P5/appendix-c-structural-safety"
        }
    }
]


export const electrical = [
    {
        "Type of Check": "Electrical Service and Distribution",
        "Item": "Compliance with service size and capacity requirements for the building's demands:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "Compliant",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Electrical Service and Distribution",
        "Item": "Adequacy of electrical panel location and access:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Electrical Service and Distribution",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Branch Circuits, Feeders, and Conductors",
        "Item": "Compliance with requirements for branch circuits, feeders, and conductor sizing and protection:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "Compliant",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Branch Circuits, Feeders, and Conductors",
        "Item": "Use of appropriate conductor materials and types:",
        "Value": "Used",
        "Code Requirement": "Used",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Branch Circuits, Feeders, and Conductors",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Grounding and Bonding",
        "Item": "Adequacy of grounding and bonding of electrical systems and equipment:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Grounding and Bonding",
        "Item": "Compliance with NEC grounding requirements:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Grounding and Bonding",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Grounding and Bonding",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Lighting Design and Controls",
        "Item": "Compliance with IBC/IECC lighting efficiency and control requirements:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Lighting Design and Controls",
        "Item": "Provision of emergency and egress lighting as required:",
        "Value": "Not Provided",
        "Code Requirement": "Not Provided",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Lighting Design and Controls",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Overcurrent Protection",
        "Item": "Proper sizing and installation of overcurrent protection devices:",
        "Value": "Properly Sized",
        "Code Requirement": "Properly Sized",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Overcurrent Protection",
        "Item": "Coordination with electrical design and loads:",
        "Value": "Coordinated",
        "Code Requirement": "Coordinated",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Overcurrent Protection",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Electrical Outlets and Receptacles",
        "Item": "Adequacy and accessibility of outlets in accordance with IBC/ADA:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Electrical Outlets and Receptacles",
        "Item": "Compliance with spacing and placement requirements:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Electrical Outlets and Receptacles",
        "Item": "Connection to public sewer or private disposal system:",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Electrical Safety Measures",
        "Item": "Installation of GFCI protection where required:",
        "Value": "Installed",
        "Code Requirement": "Installed",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Electrical Safety Measures",
        "Item": "Arc-fault circuit interrupter (AFCI) protection in residential dwellings:",
        "Value": "Provided",
        "Code Requirement": "Provided",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Electrical Safety Measures",
        "Item": "Compliance with installation and venting requirements:",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Renewable Energy Systems (If Applicable)",
        "Item": "Integration of renewable energy systems (solar, wind, etc.) within electrical design:",
        "Value": "Integrated",
        "Code Requirement": "Integrated",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Renewable Energy Systems (If Applicable)",
        "Item": "Compliance with IBC and NEC for renewable energy installations:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Renewable Energy Systems (If Applicable)",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Emergency and Standby Power Systems",
        "Item": "Provision and design of emergency and standby power systems as required:",
        "Value": "Provided",
        "Code Requirement": "Provided",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Emergency and Standby Power Systems",
        "Item": "Compliance with IBC/NEC for emergency systems:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Emergency and Standby Power Systems",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Testing and Verification",
        "Item": "Performance of required electrical system testing:",
        "Value": "Performed",
        "Code Requirement": "Performed",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Testing and Verification",
        "Item": "Documentation and verification of testing results:",
        "Value": "Documented",
        "Code Requirement": "Documented",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    },
    {
        "Type of Check": "Testing and Verification",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-27-electrical"
        }
    }

]

export const structural = [
    {
        "Type of Check": "Foundation Design",
        "Item": "Compliance with IBC requirements for soil bearing capacity:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Foundation Design",
        "Item": "Adequacy of foundation design for load-bearing:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Foundation Design",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Structural Frame and Load Bearing Walls",
        "Item": "Compliance with design loads (dead, live, roof, wind, seismic, etc.):",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Structural Frame and Load Bearing Walls",
        "Item": "Proper use of materials and connection details:",
        "Value": "Proper",
        "Code Requirement": "Proper",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Structural Frame and Load Bearing Walls",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Seismic Design Requirements",
        "Item": "Adequacy of seismic design and detailing per IBC and ASCE 7:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Seismic Design Requirements",
        "Item": "Incorporation of seismic force-resisting systems:",
        "Value": "Incorporate",
        "Code Requirement": "Incorporated",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Seismic Design Requirements",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Wind Resistance",
        "Item": "Compliance with IBC wind load requirements and proper design for wind resistance:",
        "Value": "Used",
        "Code Requirement": "Used",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Wind Resistance",
        "Item": "Use of wind-resisting systems and components:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Wind Resistance",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Floor Systems",
        "Item": "Adequacy of floor systems for live load and deflection criteria:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Floor Systems",
        "Item": "Compliance with material specifications and spans:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Floor Systems",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Roof Structures",
        "Item": "Compliance with design requirements for roof loads, including snow and drainage:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Roof Structures",
        "Item": "Proper detailing for trusses, rafters, and sheathing:",
        "Value": "Proper",
        "Code Requirement": "Proper",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Roof Structures",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Lateral Load Resisting Systems",
        "Item": "Adequacy and compliance of lateral systems for wind and seismic forces:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Lateral Load Resisting Systems",
        "Item": "Integration of shear walls, bracing, and other lateral force-resisting elements:",
        "Value": "Integrated",
        "Code Requirement": "Integrated",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Lateral Load Resisting Systems",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Material Quality and Testing",
        "Item": "Use of specified materials and compliance with quality standards:",
        "Value": "Compliant",
        "Code Requirement": "Compliant",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Material Quality and Testing",
        "Item": "Performance of required material testing (concrete, steel, etc.):",
        "Value": "Performed",
        "Code Requirement": "Performed",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Material Quality and Testing",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Construction Details and Notes",
        "Item": "Clarity and completeness of construction details and notes:",
        "Value": "Clear",
        "Code Requirement": "Clear",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Construction Details and Notes",
        "Item": "Adequacy of detailing for connections, expansions, and contractions:",
        "Value": "Adequate",
        "Code Requirement": "Adequate",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Construction Details and Notes",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    },
    {
        "Type of Check": "Code Compliance and References",
        "Item": "Reference to applicable codes (IBC, ACI, AISC, etc.) and compliance with their requirements:",
        "Value": "Referenced",
        "Code Requirement": "Referenced",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Code Compliance and References",
        "Item": "Incorporation of code-mandated design and construction practices:",
        "Value": "Incorporated",
        "Code Requirement": "Incorporated",
        "Status": {
            "value": "NA",
            "url": "NA"
        }
    },
    {
        "Type of Check": "Code Compliance and References",
        "Item": "NA",
        "Value": "NA",
        "Code Requirement": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IBC2018P6/chapter-16-structural-design"
        }
    }
]


export const mechanical = [
    {
        "Type of Check": "System Design and Equipment",
        "Item": "Compliance with IBC for system design and equipment selection",
        "Value": "NA",
        "Status": {
            "value": "Compliant",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-3-general-regulations#IMC2018P4_Ch03_Sec303"
        }
    },
    {
        "Type of Check": "System Design and Equipment",
        "Item": "Type of HVAC system installed (e.g., central, split, VRF)",
        "Value": "Central",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-3-general-regulations#IMC2018P4_Ch03_Sec303"
        }
    },
    {
        "Type of Check": "System Design and Equipment",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-3-general-regulations#IMC2018P4_Ch03_Sec303"
        }
    },
    {
        "Type of Check": "Ductwork Design",
        "Item": "Adequacy of duct sizing and design for the building's needs",
        "Value": "Adequate",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-6-duct-systems"
        }
    },
    {
        "Type of Check": "Ductwork Design",
        "Item": "Compliance with required insulation levels for ductwork",
        "Value": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-6-duct-systems"
        }
    },
    {
        "Type of Check": "Ductwork Design",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-6-duct-systems"
        }
    },
    {
        "Type of Check": "Ventilation Requirements",
        "Item": "Compliance with minimum ventilation rates per IBC/ASHRAE standards",
        "Value": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-4-ventilation"
        }
    },
    {
        "Type of Check": "Ventilation Requirements",
        "Item": "Provision of outdoor air intake and exhaust systems",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-4-ventilation"
        }
    },
    {
        "Type of Check": "Ventilation Requirements",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-4-ventilation"
        }
    },
    {
        "Type of Check": "Energy Efficiency",
        "Item": "Compliance with energy conservation code or standards (e.g., IECC)",
        "Value": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-14-solar-thermal-systems"
        }
    },
    {
        "Type of Check": "Energy Efficiency",
        "Item": "Use of energy recovery systems (if applicable)",
        "Value": "Not Used",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-14-solar-thermal-systems"
        }
    },
    {
        "Type of Check": "Energy Efficiency",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-14-solar-thermal-systems"
        }
    },
    {
        "Type of Check": "Equipment Installation",
        "Item": "Proper installation of HVAC equipment according to manufacturer's instructions",
        "Value": "Properly Installed",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-11-refrigeration#IMC2018P4_Ch11_Sec1102"
        }
    },
    {
        "Type of Check": "Equipment Installation",
        "Item": "Access for maintenance and repair",
        "Value": "Accessible",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-11-refrigeration#IMC2018P4_Ch11_Sec1102"
        }
    },
    {
        "Type of Check": "Equipment Installation",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-11-refrigeration#IMC2018P4_Ch11_Sec1102"
        }
    },
    {
        "Type of Check": "Refrigerant Piping",
        "Item": "Compliance with standards for refrigerant piping design and installation",
        "Value": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-11-refrigeration"
        }
    },
    {
        "Type of Check": "Refrigerant Piping",
        "Item": "Leak detection and protection systems in place (if applicable)",
        "Value": "Installed",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-11-refrigeration"
        }
    },
    {
        "Type of Check": "Refrigerant Piping",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-11-refrigeration"
        }
    },
    {
        "Type of Check": "Controls and Thermostats",
        "Item": "Proper installation and calibration of control systems and thermostats",
        "Value": "Properly Installed",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-9-specific-appliances-fireplaces-and-solid-fuel-burning-equipment"
        }
    },
    {
        "Type of Check": "Controls and Thermostats",
        "Item": "Integration with building management systems (if applicable)",
        "Value": "Integrated",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-9-specific-appliances-fireplaces-and-solid-fuel-burning-equipment"
        }
    },
    {
        "Type of Check": "Controls and Thermostats",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-9-specific-appliances-fireplaces-and-solid-fuel-burning-equipment"
        }
    },
    {
        "Type of Check": "Fire and Smoke Control",
        "Item": "Integration of HVAC system with fire alarm and suppression systems",
        "Value": "Integrated",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-9-specific-appliances-fireplaces-and-solid-fuel-burning-equipment"
        }
    },
    {
        "Type of Check": "Fire and Smoke Control",
        "Item": "Compliance with IBC for fire and smoke dampers in ductwork",
        "Value": "Compliant",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-9-specific-appliances-fireplaces-and-solid-fuel-burning-equipment"
        }
    },
    {
        "Type of Check": "Fire and Smoke Control",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-9-specific-appliances-fireplaces-and-solid-fuel-burning-equipment"
        }
    },
    {
        "Type of Check": "Sound and Vibration Control",
        "Item": "Adequate measures for sound and vibration control in HVAC design",
        "Value": "Adequate",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-12-hydronic-piping"
        }
    },
    {
        "Type of Check": "Sound and Vibration Control",
        "Item": "Use of noise-reducing equipment and installation techniques",
        "Value": "Used",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-12-hydronic-piping"
        }
    },
    {
        "Type of Check": "Sound and Vibration Control",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-12-hydronic-piping"
        }
    },
    {
        "Type of Check": "Testing and Commissioning",
        "Item": "Performance of required system testing and commissioning",
        "Value": "Performed",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-3-general-regulations"
        }
    },
    {
        "Type of Check": "Testing and Commissioning",
        "Item": "Documentation of testing and commissioning results",
        "Value": "Documented",
        "Status": {
            "value": "NA",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-3-general-regulations"
        }
    },
    {
        "Type of Check": "Testing and Commissioning",
        "Item": "NA",
        "Value": "NA",
        "Status": {
            "value": "Pass",
            "url": "https://codes.iccsafe.org/content/IMC2018P4/chapter-3-general-regulations"
        }
    }
]


export const arborist = [
    {
      "Type": "Tree",
      "Item": "Trees within 50 feet",
      "Value": "No"
    },
    {
      "Type": "Tree",
      "Item": "Age of Tree survey less than two years",
      "Value": "Yes"
    },
    {
      "Type": "Tree",
      "Item": "Are tree locations accurate?",
      "Value": "Yes"
    },
    {
      "Type": "Tree",
      "Item": "Site plan shows impact to CRZ?",
      "Value": "No"
    },
    {
      "Type": "Tree",
      "Item": "Trees marked to be destroyed?",
      "Value": "No"
    },
    {
      "Type": "Tree",
      "Item": "Trees in setback to be destroyed?",
      "Value": "No"
    },
    {
      "Type": "Tree",
      "Item": "Any trees that impact the CRZ by more than 33%?",
      "Value": "No"
    }
  ]