import React from 'react'

function IQLogo({width, url}) {
  return (
    <>
        <a href={url} style={{width:width, minWidth:"90px"}}><img src='/images/logo.png' style={{minWidth:"75px"}} alt='logo'/></a>
    </>
  )
}

export default IQLogo