import React, { useState } from "react";
import "./PaymentSchedule.css";

const PaymentSchedule = ({
  subscriptionPlans,
  setSelectedPlanPrice,
  selectedPriceIdDefault,
}) => {
  const [selectedPriceId, setSelectedPriceId] = useState(
    selectedPriceIdDefault
  );

  // const getPrice = (price, interval) => {
  //   // if (interval === "year") {
  //   //   return Math.ceil(price / 12);
  //   // } else {
  //   //   return price;
  //   // }
  //   return price;
  // };

  return (
    <div className="ps_payment-schedule">
      <h2 className="ps_title">Choose Your Payment Schedule</h2>
      <div className="ps_plans">
        {subscriptionPlans[0]?.prices.map((item) => (
          <div
            key={item.id}
            className={`ps_plan ${selectedPriceId === item.id ? "selected" : ""}`}
            onClick={() => {
              setSelectedPriceId(item.id);
              setSelectedPlanPrice(item);
            }}
          >
            <div className="ps_radio-button">
              <input
                type="radio"
                id={item.interval}
                name="plan"
                checked={selectedPriceId === item.id}
                readOnly
              />
              <label htmlFor={item.interval}>
                {item.interval === "year" ? "Yearly" : "Monthly"}
              </label>
            </div>
            <div className="ps_price_area">
              <div className="ps_price">
                {/* ${item?.interval === "year" ? item.unit_amount * (1 - parseFloat(item.discount_percentage)/100) : item.unit_amount}<span>/{item.interval}</span> */}
                ${item.unit_amount}<span> / {item.interval}</span>
              </div>
              {/* {item.interval === "year" && (
                <div className="ps_save">SAVE {item.discount_percentage}</div>
              )} */}
            </div>
            <div className="ps_description">
              
              {
                item.interval === "year" ?
                "Our standard yearly subscription rate"
                :
                "Our standard monthly subscription rate"
              }
            </div>
          </div>
        ))}
        {/* <div
          className={`plan ${selectedPlan === "monthly" ? "selected" : ""}`}
          onClick={() => setSelectedPlan("monthly")}
        >
          <div className="ps_radio-button">
            <input
              type="radio"
              id="monthly"
              name="plan"
              checked={selectedPlan === "monthly"}
              readOnly
            />
            <label htmlFor="monthly">Monthly</label>
          </div>
          <div className="ps_price">$234/Monthly</div>
          <div className="ps_description">$19.50/month, billed annually</div>
        </div> */}
      </div>
    </div>
  );
};

export default PaymentSchedule;
