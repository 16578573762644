import React from 'react'
import TermConditions from './component/TermConditions'
import NavBar from '../../components/navbar/NavBar'

function Terms() {
    return (
        <div className='terms'>
            <NavBar />
            <div className="terms_title">Terms and Conditions</div>
            <TermConditions />
        </div>
    )
}

export default Terms