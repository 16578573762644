import React, { useEffect, useState } from 'react';
import './CopilotTulsa.css';
import CoSideBar from './component/CoSideBar';
import CoMainArea from './component/CoMainArea';
import { getUserChatHistory } from '../../components/user_service/UserService';
import IQInformationPop from '../../components/util_component/iq_information_pop/IQInformationPop';
import UserNavTulsa from '../../components/user_nav_tulsa/UserNavTulsa';

function CopilotTulsa() {
  const [titles, setTitles] = useState([]);

  const [infoPop, setInfoPop] = useState(false);
  const [infoMsg, setInfoMsg] = useState("");

  const [mobileSideBar, setMobileSidebar] = useState(false);

  const handleMobileSidebar = () => {
    setMobileSidebar(!mobileSideBar);
  };


  useEffect(() => {
    const fetchChatHistory = async () => {
      try {
        const response = await getUserChatHistory();
        setTitles(response.data);
      } catch (error) {
        setInfoMsg(error);
        setInfoPop(true);
      }
    };
    
    fetchChatHistory();
  }, []); 

  const handleNewChat = (newChat) => {
    setTitles((prevTitles) => [newChat, ...prevTitles]);
  };

    //hide mobile side bar for out side click
    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (mobileSideBar && !event.target.closest('.copilot_new__mobile_sidebar')) {
          setMobileSidebar(false);
        }
      };
  
      document.addEventListener('mousedown', handleOutsideClick);
  
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [mobileSideBar]);

  return (
    <div className='copilot_new'>
      {/* Navigation  */}
      <div className='copilot_new__navigation'>
        <UserNavTulsa tab={"c"} handleBurgerClick={handleMobileSidebar}/>
      </div>

      {/* Main area */}
      <div className='copilot_new__main'>
        {/* Copilot side bar */}
        {/* <div className='copilot_new__sidebar'>
          <CoSideBar titles={titles} />
        </div> */}

        {
          mobileSideBar && <div className='copilot_new__mobile_sidebar'>
            <CoSideBar titles={titles} handleClose={handleMobileSidebar}/>
          </div>
        }

        {/* Copilot chat area */}
        <div className='copilot_new__content'>
          <CoMainArea onNewChat={handleNewChat}/>
        </div>
      </div>

      {
        infoPop && <IQInformationPop message={infoMsg} setVisibility={setInfoPop}/>
      }
    </div>
  );
}

export default CopilotTulsa;
