import React, { useEffect, useState } from 'react'
import "./NewFilter.css"
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, FormGroup, Slider } from '@mui/material'
import { ExpandMoreOutlined } from '@mui/icons-material';
import { newStyles } from '../data/StylesData';

function NewFilter({ handleClose, handleFilterButton, filterData, setFilterData }) {

    // active button style
    const activeButtonStyle = {
        backgroundColor: "var(--primary-clr)",
        color: "#fff"
    }


    // list of constant values
    // const list_plan_type = ["Single-Family Homes", "Stand-Alone Garages", "Multi-Family Homes", "Other"]
    const list_plan_type = ["Single Family", "Duplex", "Multi Family", "Other"]

    const list_garage = ["Attached", "Detached", "Carport", "Drive Under", "RV Garage", "None"]
    const list_foundation = ["Basement", "Crawl", "Daylight", "Monolithic Slab", "Pier", "Piling", "Post Beam", "Stem Wall", "Walk out"]
    const list_wall = ['2x6', '2x4', '2x8', 'Block', 'Metal', 'ICF', 'Log', 'Block / CMU (main floor)', '2x4 or 2x6', '2x4 and 2x6', 'Block / CMU (main floor) or 2x6', '2x6 or Block / CMU (main floor)']
    const list_bedrooms = ["1", "2", "3", "4", "5", "6", "7", "8+"]
    const list_bathrooms = ["1", "1.5", "2", "2.5", "3", "3.5", "4", "5+"]
    const list_floors = ["1", "2", "3"]
    const list_vehicles = ["0", "1", "2", "3", "4", "5", "6", "7", "8"];



    // Clear all filter
    const handleClearAll = () => setFilterData({
        "title": "",
        "address": filterData.address,
        "architectural_style": [
            "Bungalow",
            "Craftsman"
        ],
        "area_total_min": 10,
        "area_total_max": 10000,
        "width_min": 10,
        "width_max": 500,
        "height_min": 10,
        "height_max": 100,
        "depth_min": 10,
        "depth_max": 500,
        "stories": ["1", "2", "3"],
        "cars_capacity": ["1", "2", "3"],
        "foundation": ["Basement", "Crawl", "Daylight", "Monolithic Slab", "Pier", "Piling", "Post Beam", "Stem Wall", "Walk out"],
        "exterior_wall_type": ["Block", "2x4"],
        "garage_type": ["Attached", "Detached", "Carport", "Drive Under", "RV Garage", "None"],
        "units": ["Single Family", "Duplex", "Multi Family", "Other"],
        "bedrooms": ["1", "2", "3", "4", "5", "6", "7", "8+"],
        "bathrooms": ["1", "1.5", "2", "2.5", "3", "3.5", "4", "5+"]
    })


    // common slide data change function
    const handleSliderChange = (field) => (event, newValue) => {
        setFilterData(prevState => ({
            ...prevState,
            [`${field}_min`]: newValue[0],
            [`${field}_max`]: newValue[1]
        }));
    };

    // common checkbox changes
    const handleCheckboxChange = (event, arrayName) => {
        const { name, checked } = event.target;
        setFilterData(prevState => {
            const newArray = checked
                ? [...prevState[arrayName], name]
                : prevState[arrayName].filter(item => item !== name);

            return {
                ...prevState,
                [arrayName]: newArray
            };
        });
    };


    const min_max_slider_style = {
        width: "calc(100% - 1.22rem)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "0.66rem",
        color: '#126342', // Custom color
        '& .MuiSlider-thumb': {
            backgroundColor: '#126342', // Thumb color
        },
        '& .MuiSlider-track': {
            backgroundColor: 'var(primary-clr)', // Track color
        },
        '& .MuiSlider-rail': {
            backgroundColor: '#F1F1F', // Rail color
        },
    }


    // handling select all unselect all
    // const [styleSelect, setStyleSelect] = useState(false);
    const [foundationSelect, setFoundationSelect] = useState(false);
    const [garageSelect, setGarageSelect] = useState(false);
    const [walltypeSelect, setWalltypeSelect] = useState(false);
    const [bedroomSelect, setBedroomSelect] = useState(false);
    const [bathroomSelect, setBathroomSelect] = useState(false);


    // handle select all
    const handleSelectAll = (attribute, value, setValue, labels) => {
        if (value) {
            setFilterData(prevState => ({
                ...prevState,
                [attribute]: []
            }));
        } else {
            setFilterData(prevState => ({
                ...prevState,
                [attribute]: labels
            }));
        }
        setValue(!value);
    }

    // Handle checkbox change for individual styles
    const handleCheckboxChangeStyle = (event, style) => {
        const { name, checked } = event.target;
        setFilterData(prevState => {
            const newArray = checked
                ? [...prevState.architectural_style, name]
                : prevState.architectural_style.filter(item => item !== name);

            return {
                ...prevState,
                architectural_style: newArray
            };
        });
    };

    // Handle select all for a group
    const handleSelectAllStyle = (title, styles, isSelected) => {
        setFilterData(prevState => {
            const newArray = isSelected
                ? prevState.architectural_style.filter(item => !styles.includes(item))
                : [...prevState.architectural_style, ...styles.filter(item => !prevState.architectural_style.includes(item))];

            return {
                ...prevState,
                architectural_style: newArray
            };
        });
        setSelectAllState(prevState => ({
            ...prevState,
            [title]: !isSelected
        }));
    };

    const [selectAllState, setSelectAllState] = useState({});

    // Check if all styles in a group are selected
    useEffect(() => {
        newStyles.forEach(st => {
            const allSelected = st.styles.every(style => filterData.architectural_style.includes(style));
            setSelectAllState(prevState => ({
                ...prevState,
                [st.title]: allSelected
            }));
        });
    }, [filterData.architectural_style]);

    useEffect(() => {
        if (filterData.foundation.length === list_foundation.length) {
            setFoundationSelect(true);
        } else {
            setFoundationSelect(false);
        }
    }, [filterData.foundation])

    useEffect(() => {
        if (filterData.garage_type.length === list_garage.length) {
            setGarageSelect(true);
        } else {
            setGarageSelect(false);
        }
    }, [filterData.garage_type])

    useEffect(() => {
        if (filterData.exterior_wall_type.length === list_wall.length) {
            setWalltypeSelect(true);
        } else {
            setWalltypeSelect(false);
        }
    }, [filterData.exterior_wall_type])

    useEffect(() => {
        if (filterData.bedrooms.length === list_bedrooms.length) {
            setBedroomSelect(true);
        } else {
            setBedroomSelect(false);
        }
    }, [filterData.bedrooms])

    useEffect(() => {
        if (filterData.bathrooms.length === list_bathrooms.length) {
            setBathroomSelect(true);
        } else {
            setBathroomSelect(false);
        }
    }, [filterData.bathrooms])

    return (
        <div className='new_filter'>

            <div className='new_filter__title_area'>
                <h3>All Filters</h3>
                <img src='/images/dashboard_new/close_icon.svg' onClick={() => handleClose()} alt='x' />
            </div>
            <div className='new_filter__filters'>

                <div className='filter_input_with_icon'>
                    <h6>Enter Keyword</h6>
                    <div className='filter_input_with_button'>
                        <input type='text' placeholder='Search' value={filterData.title} onChange={(e) => setFilterData({ ...filterData, title: e.target.value })} />
                        {/* <div className='keyword_search_btn'><img src='/images/dashboard_new/search_icon.svg' alt='filter' /></div> */}
                    </div>
                </div>

                {/* <div className='filter_normal_input'>
                    <h6>Land Acquisition Cost</h6>
                    <input type='text' placeholder='Acquisition Cost' value={cost} onChange={(e) => setCost(e.target.value)} />
                </div> */}

                {/* <div className='filter_dropdown'>
                    <h6>Building Quality</h6>
                    <select value={quality} onChange={(e) => setQuality(e.target.value)}>
                        <option value='BRONZE'>Economy</option>
                        <option value='SILVER'>Standard</option>
                        <option value='GOLD'>Premium</option>
                    </select>
                </div> */}



                <div className='filter_min_max'>
                    <h6>Total Area (sqft)</h6>
                    <div>
                        <Slider
                            sx={min_max_slider_style}
                            value={[filterData.area_total_min, filterData.area_total_max]}
                            onChange={handleSliderChange('area_total')}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            disableSwap
                            min={10}
                            max={10000}
                        />
                        <div className='filter_min_max__values'>
                            <span>10 sqft</span>
                            <span>10000 sqft</span>
                        </div>
                    </div>
                    <div className='filter_min_max__inputs'>
                        <div className='filter_min_max__label'>Min:</div>
                        <input
                            type='text'
                            placeholder='Min'
                            value={filterData.area_total_min}
                            onChange={(e) => setFilterData({ ...filterData, area_total_min: e.target.value })}
                        />
                        <div className='filter_min_max__label'>Max:</div>
                        <input
                            type='text'
                            placeholder='Max'
                            value={filterData.area_total_max}
                            onChange={(e) => setFilterData({ ...filterData, area_total_max: e.target.value })}
                        />
                    </div>
                </div>

                <div className='filter_with_count_list'>
                    <h6>Bedrooms</h6>
                    <ul>
                        <li
                            style={bedroomSelect ? activeButtonStyle : {}}
                            onClick={() => handleSelectAll("bedrooms", bedroomSelect, setBedroomSelect, list_bedrooms)}
                        >All</li>
                        {
                            list_bedrooms.map((c, index) => {
                                const isActive = filterData.bedrooms.includes(c);
                                return (
                                    <li
                                        key={index}
                                        style={isActive ? activeButtonStyle : {}}
                                        onClick={() => {
                                            const newBedrooms = isActive
                                                ? filterData.bedrooms.filter(bedroom => bedroom !== c)
                                                : [...filterData.bedrooms, c];
                                            setFilterData({ ...filterData, bedrooms: newBedrooms });
                                        }}
                                    >{c}</li>
                                );
                            })
                        }
                    </ul>


                </div>

                <div className='filter_with_count_list'>
                    <h6>Bathrooms</h6>
                    <ul>
                        <li
                            onClick={() => handleSelectAll("bathrooms", bathroomSelect, setBathroomSelect, list_bathrooms)}
                            style={bathroomSelect ? activeButtonStyle : {}}
                        >All</li>
                        {
                            list_bathrooms.map((c, index) => {
                                const isActive = filterData.bathrooms.includes(c);
                                return (
                                    <li
                                        key={index}
                                        style={isActive ? activeButtonStyle : {}}
                                        onClick={() => {
                                            const newBathrooms = isActive
                                                ? filterData.bathrooms.filter(bathroom => bathroom !== c)
                                                : [...filterData.bathrooms, c];
                                            setFilterData({ ...filterData, bathrooms: newBathrooms });
                                        }}
                                    >{c}</li>
                                );
                            })
                        }
                    </ul>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_plan_type.svg' alt='foot print' />
                                <h5>Plan Type</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {<FormGroup>
                                    {
                                        list_plan_type.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.units.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "units")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))
                                    }
                                </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_foot_print.svg' alt='foot print' />
                                <h5>Footprint</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "1rem"
                            }}
                        >
                            <div className='filter_min_max'>
                                <h6>Width (ft)</h6>
                                <div>
                                    <Slider
                                        sx={min_max_slider_style}
                                        value={[filterData.width_min, filterData.width_max]}
                                        onChange={handleSliderChange('width')}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        disableSwap
                                        min={10}
                                        max={500}
                                    />
                                    <div className='filter_min_max__values'>
                                        <span>10 ft</span>
                                        <span>500 ft</span>
                                    </div>
                                </div>
                                <div className='filter_min_max__inputs'>
                                    <div className='filter_min_max__label'>Min:</div>
                                    <input
                                        type='text'
                                        placeholder='Min'
                                        value={filterData.width_min}
                                        onChange={(e) => setFilterData({ ...filterData, width_min: e.target.value })}
                                    />
                                    <div className='filter_min_max__label'>Max:</div>
                                    <input
                                        type='text'
                                        placeholder='Max'
                                        value={filterData.width_max}
                                        onChange={(e) => setFilterData({ ...filterData, width_max: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='filter_min_max'>
                                <h6>Depth (ft)</h6>
                                <div>
                                    <Slider
                                        sx={min_max_slider_style}
                                        value={[filterData.depth_min, filterData.depth_max]}
                                        onChange={handleSliderChange('depth')}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        disableSwap
                                        min={10}
                                        max={500}
                                    />
                                    <div className='filter_min_max__values'>
                                        <span>10 ft</span>
                                        <span>500 ft</span>
                                    </div>
                                </div>
                                <div className='filter_min_max__inputs'>
                                    <div className='filter_min_max__label'>Min:</div>
                                    <input
                                        type='text'
                                        placeholder='Min'
                                        value={filterData.depth_min}
                                        onChange={(e) => setFilterData({ ...filterData, depth_min: e.target.value })}
                                    />
                                    <div className='filter_min_max__label'>Max:</div>
                                    <input
                                        type='text'
                                        placeholder='Max'
                                        value={filterData.depth_max}
                                        onChange={(e) => setFilterData({ ...filterData, depth_max: e.target.value })}
                                    />
                                </div>
                            </div>


                        </AccordionDetails>
                    </Accordion>
                </div>


                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_floors_icon.svg' alt='foot print' />
                                <h5>Floors</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                <h6>Stories</h6>
                                {
                                    <FormGroup>

                                        {list_floors.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.stories.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "stories")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                            <div className='filter_min_max' style={{ marginTop: "1rem" }}>
                                <h6>Height (ft)</h6>
                                <div>
                                    <Slider
                                        sx={min_max_slider_style}
                                        value={[filterData.height_min, filterData.height_max]}
                                        onChange={handleSliderChange('height')}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                        disableSwap
                                        min={10}
                                        max={100}
                                    />
                                    <div className='filter_min_max__values'>
                                        <span>10 ft</span>
                                        <span>100 ft</span>
                                    </div>
                                </div>
                                <div className='filter_min_max__inputs'>
                                    <div className='filter_min_max__label'>Min:</div>
                                    <input
                                        type='text'
                                        placeholder='Min'
                                        value={filterData.height_min}
                                        onChange={(e) => setFilterData({ ...filterData, height_min: e.target.value })}
                                    />
                                    <div className='filter_min_max__label'>Max:</div>
                                    <input
                                        type='text'
                                        placeholder='Max'
                                        value={filterData.height_max}
                                        onChange={(e) => setFilterData({ ...filterData, height_max: e.target.value })}
                                    />
                                </div>
                            </div>


                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: '0', minHeight: '2.25rem' }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_home_styles.svg' alt='foot print' />
                                <h5>Styles</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {newStyles.map((st, index) => (
                                <Accordion key={index}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreOutlined />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <div>{st.title}</div>
                                            <FormGroup>
                                                <FormControlLabel
                                                    sx={{ marginLeft: '0', display: 'block' }}
                                                    control={
                                                        <div className='acc_data_item'>
                                                            <Checkbox
                                                                checked={selectAllState[st.title] || false}
                                                                onChange={() => handleSelectAllStyle(st.title, st.styles, selectAllState[st.title])}
                                                                name='Select All'
                                                                sx={{
                                                                    '&.Mui-checked': { color: 'var(--primary-clr)' },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup>
                                            {st.styles.map(style => (
                                                <FormControlLabel
                                                    sx={{ marginLeft: '0', display: 'block' }}
                                                    key={style}
                                                    control={
                                                        <div className='acc_data_item'>
                                                            <div className='text_with_count'>
                                                                <div className='text'>{style}</div>
                                                                {/* <div className='count'>(23)</div> */}
                                                            </div>
                                                            <div>
                                                                <Checkbox
                                                                    checked={filterData.architectural_style.includes(style)}
                                                                    onChange={(e) => handleCheckboxChangeStyle(e, style)}
                                                                    name={style}
                                                                    sx={{
                                                                        '&.Mui-checked': { color: 'var(--primary-clr)' },
                                                                        '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            ))}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_cars_icon.svg' alt='foot print' />
                                <h5>No of Vehicles</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                {
                                    <FormGroup>
                                        {list_vehicles.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style === "0" ? "None" : style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.cars_capacity.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "cars_capacity")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_garage_icon.svg' alt='foot print' />
                                <h5>Garage Type</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ marginLeft: "0", display: "block" }}
                                        control={
                                            <div className='acc_data_item'>
                                                <div className='text_with_count'>
                                                    <div className='text'>Select All</div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        checked={garageSelect}
                                                        onChange={() => handleSelectAll("garage_type", garageSelect, setGarageSelect, list_garage)}
                                                        name='garage_select_all'
                                                        sx={{
                                                            '&.Mui-checked': { color: "var(--primary-clr)" },
                                                            '& .MuiSvgIcon-root': { fontSize: 24 }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />


                                </FormGroup>
                                {
                                    <FormGroup>
                                        {list_garage.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.garage_type.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "garage_type")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_foundation_icon.svg' alt='foot print' />
                                <h5>Foundation</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ marginLeft: "0", display: "block" }}
                                        control={
                                            <div className='acc_data_item'>
                                                <div className='text_with_count'>
                                                    <div className='text'>Select All</div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        checked={foundationSelect}
                                                        onChange={() => handleSelectAll("foundation", foundationSelect, setFoundationSelect, list_foundation)}
                                                        name='foundation_select_all'
                                                        sx={{
                                                            '&.Mui-checked': { color: "var(--primary-clr)" },
                                                            '& .MuiSvgIcon-root': { fontSize: 24 }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />


                                </FormGroup>
                                {
                                    list_foundation.map(style => (
                                        <FormControlLabel
                                            sx={{ marginLeft: "0", display: "block" }}
                                            key={style}
                                            control={
                                                <div className='acc_data_item'>
                                                    <div className='text_with_count'>
                                                        <div className='text'>{style}</div>
                                                        {/* <div className='count'>(23)</div> */}
                                                    </div>
                                                    <div>
                                                        <Checkbox
                                                            checked={filterData.foundation.includes(style)}
                                                            onChange={(e) => handleCheckboxChange(e, "foundation")}
                                                            name={style}
                                                            sx={{
                                                                '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                '& .MuiSvgIcon-root': { fontSize: 24 }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            }
                                        />
                                    ))
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>

                <div className='filter_with_accordion'>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreOutlined />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ padding: "0", minHeight: "2.25rem" }}
                        >
                            <div className='acc_title_area'>
                                <img src='/images/dashboard_new/acc_walls_icon.svg' alt='foot print' />
                                <h5>Exterior Walls</h5>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='acc_data_list'>
                                <FormGroup>
                                    <FormControlLabel
                                        sx={{ marginLeft: "0", display: "block" }}
                                        control={
                                            <div className='acc_data_item'>
                                                <div className='text_with_count'>
                                                    <div className='text'>Select All</div>
                                                </div>
                                                <div>
                                                    <Checkbox
                                                        checked={walltypeSelect}
                                                        onChange={() => handleSelectAll("exterior_wall_type", walltypeSelect, setWalltypeSelect, list_wall)}
                                                        name='walltype_select_all'
                                                        sx={{
                                                            '&.Mui-checked': { color: "var(--primary-clr)" },
                                                            '& .MuiSvgIcon-root': { fontSize: 24 }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    />


                                </FormGroup>
                                {<FormGroup>
                                    {
                                        list_wall.map(style => (
                                            <FormControlLabel
                                                sx={{ marginLeft: "0", display: "block" }}
                                                key={style}
                                                control={
                                                    <div className='acc_data_item'>
                                                        <div className='text_with_count'>
                                                            <div className='text'>{style}</div>
                                                            {/* <div className='count'>(23)</div> */}
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={filterData.exterior_wall_type.includes(style)}
                                                                onChange={(e) => handleCheckboxChange(e, "exterior_wall_type")}
                                                                name={style}
                                                                sx={{
                                                                    '&.Mui-checked': { color: "var(--primary-clr)" },
                                                                    '& .MuiSvgIcon-root': { fontSize: 24 }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        ))
                                    }
                                </FormGroup>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>



            </div>
            <div className='new_filter_buttons_area'>
                <button className='secondary_btn' onClick={() => { handleClearAll() }}>Clear All</button>
                <button className='primary_btn' onClick={() => handleFilterButton(filterData)}>Apply</button>

            </div>
        </div>
    )
}

export default NewFilter