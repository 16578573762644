
export const tutorialData = [{
    id: 1,
    image: "1.gif",
    alt: "Demo",
    title: "How It Works",
    content: "This demonstration, built specifically for the City of Atlanta, showcases our software's capabilities with a pre-loaded residential home case study. Discover how our system enhances the inspection process through an intuitive and instructional interface."
  },

  {
    id: 2,
    image: "2.gif",
    alt: "Demo",
    title: "Select a Tab",
    content: "Begin by clicking on one of the pre-prompt tabs such as 'Check zoning parameters', 'Analyze electrical', or 'Analyze residential code'."
  },
  {
    id: 3,
    image: "3.gif",
    alt: "Demo",
    title: "Run the Demo",
    content: "After selecting a tab, click 'Run Demo' to process the information and generate results."
  },
  {
    id: 4,
    image: "4.gif",
    alt: "Demo",
    title: "Review Results",
    content: "Click on the 'Pass/Fail Grade' to access specific zoning code references related to your results."
  },
  {
    id: 5,
    image: "5.gif",
    alt: "Demo",
    title: "Visual Inspection",
    content: "Click on an image to view the corresponding pass or fail item directly on the construction plan PDF."
  },
  {
    id: 6,
    image: "6.gif",
    alt: "Demo",
    title: "Unavailable Tabs",
    content: "Tabs that do not apply to this residential home case study are included as dummies for demonstration purposes."
  },

  ]