import React, { useEffect } from 'react';

const MetaPixel = () => {
  useEffect(() => {
    // Define fbq function
    window.fbq = function() {
      window.fbq.callMethod ? 
      window.fbq.callMethod.apply(window.fbq, arguments) : 
      window.fbq.queue.push(arguments)
    };

    // Initialize fbq if not already initialized
    if (!window._fbq) window._fbq = window.fbq;
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    // Load the script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);

    // Initialize your pixel
    window.fbq('init', '2383512208650633');
    window.fbq('track', 'PageView');

    // Cleanup function
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <noscript>
      <img 
        height="1" 
        width="1" 
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=2383512208650633&ev=PageView&noscript=1"
        alt=""
      />
    </noscript>
  );
};

export default MetaPixel;