import React, { useEffect, useRef, useState } from 'react'
import './MetricCard.css'
import { IconButton, Tooltip } from '@mui/material'
import { CloseOutlined, DoneOutlined, EditOutlined, InfoOutlined } from '@mui/icons-material'
import { LoadingSkeletonText } from '../../../components/skeleton_loading/SkeletonLoading'

function MetricCardAcc({ icon, icon_back, icon_alt, amount, setAmount, adcAmount, setAdcAmount, fetchKpi, color, loading }) {
    // Convert to currency
    function formatCurrency(number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(number);
    }

    const [accText, setAccText] = useState(amount);
    const [editAcc, setEditAcc] = useState(false);

    const [adcText, setAdcText] = useState(adcAmount);
    const [editAdc, setEditAdc] = useState(false);

    const handleAccOkButton = () => {
        setEditAcc(false);
        setEditAdc(false)
        setAmount(accText);
        setAdcAmount(adcText);
        // sessionStorage.setItem("acquisitionCost", accText);
        fetchKpi(accText, adcText);
    }

    // const handleAdcOkButton = (v) => {
    //     setEditAdc(false);
    //     setAdcAmount(adcText);
    //     // sessionStorage.setItem("additional", adcText)
    //     fetchKpi(v);
    // }

    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipRef = useRef(null);

    const editValueRef = useRef(null);


    // Close the tooltip on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
                setShowTooltip(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // useEffect(() => {
    //     const handleClickEditValue = (event) => {
    //         if (tooltipRef.current && tooltipRef.current.contains(event.target)) {
    //             setShowTooltip(true);
    //         }
    //     };

    //     document.addEventListener("mouseup", handleClickEditValue);
    //     return () => {
    //         document.removeEventListener("mouseup", handleClickEditValue);
    //     };
    // }, []);

    const tt = "Land acquisition costs are expenses for purchasing land. They include the land price, legal fees, survey costs, title insurance, and related expenditures to secure and transfer ownership. Other key land development costs include site preparation (clearing, grading, leveling), environmental assessments, permitting and zoning, utility installation (water, sewer, electricity, gas), infrastructure (roads, sidewalks, drainage), and landscaping."

    return (
        <div className='metric_card' style={{ backgroundColor: color === "second" ? "var(--primary-clr)" : "white", color: color === "second" ? "#fff" : "#2E2C34", gap: "1rem" }} ref={tooltipRef} onClick={()=> setShowTooltip(!showTooltip)}>
            <div className='metric_card__text_area'>
                <div className='metric_card__icon' style={{ backgroundColor: icon_back }}>
                    <img src={`/images/dashboard_new/${icon}.svg`} alt={icon_alt} style={{ borderRadius: "50%" }} />
                </div>
                <h5 style={{ color: color === "second" ? "#fff" : "#626262" }}>{"Other Costs"}</h5>
                <Tooltip title={tt} arrow open={showTooltip}>
                    <IconButton>
                        <InfoOutlined sx={{ width: "1rem", height: "1rem" }} />
                    </IconButton>
                </Tooltip>
            </div>

            <div className='metric_card__text_area' ref={editValueRef}>
                <h5 style={{ color: color === "second" ? "#fff" : "#626262", maxWidth: "10ch" }}>{"Acquisition"}</h5>
                {
                    editAcc ?
                        <div className='metric_card_acc__edit_text_area'>
                            <input type="text" value={accText} onChange={(e) => setAccText(e.target.value)} onClick={(e) => e.stopPropagation()}/>
                            <IconButton onClick={(e) => { handleAccOkButton(parseFloat(accText) + parseFloat(adcAmount)); e.stopPropagation() }} aria-label="Confirm">
                                <DoneOutlined className='ico_btn' />
                            </IconButton>
                            <IconButton onClick={(e) => { setEditAdc(false); setEditAcc(false); setAdcText(adcAmount); setAccText(amount); e.stopPropagation() }} aria-label="Cancel">
                                <CloseOutlined className='ico_btn' />
                            </IconButton>
                        </div>
                        :
                        <>
                            <div className='metric_card__amount' style={{ fontSize: "1rem" }} ref={editValueRef}>
                                {loading ? <LoadingSkeletonText width={"6ch"} /> : formatCurrency(amount)}
                            </div>
                            {!loading && <div onClick={(e) => { setEditAdc(true); setEditAcc(true); setAdcText(adcAmount); setAccText(amount); e.stopPropagation() }}><EditOutlined className='metric_card_cc__pen' /></div>}
                        </>
                }
            </div>
            <div className='metric_card__text_area' ref={editValueRef}>
                <h5 style={{ color: color === "second" ? "#fff" : "#626262", maxWidth: "10ch" }}>{"Other"}</h5>
                {
                    editAdc ?
                        <div className='metric_card_acc__edit_text_area'>
                            <input type="text" value={adcText} onChange={(e) => setAdcText(e.target.value)} onClick={(e) => e.stopPropagation()}/>
                            {/* <IconButton onClick={(e) => { handleAccOkButton(parseFloat(adcText) + parseFloat(amount)); e.stopPropagation() }} aria-label="Confirm">
                                <DoneOutlined className='ico_btn' />
                            </IconButton>
                            <IconButton onClick={(e) => { setEditAdc(false); setEditAcc(false); setAdcAmount(adcAmount); e.stopPropagation() }} aria-label="Cancel">
                                <CloseOutlined className='ico_btn' />
                            </IconButton> */}
                        </div>
                        :
                        <>
                            <div className='metric_card__amount' style={{ fontSize: "1rem" }} ref={editValueRef}>
                                {loading ? <LoadingSkeletonText width={"6ch"} /> : formatCurrency(adcAmount)}
                            </div>
                            {!loading && <div onClick={(e) => { setEditAdc(true); setEditAcc(true); e.stopPropagation() }}><EditOutlined className='metric_card_cc__pen' /></div>}
                        </>
                }
            </div>
        </div>
    )
}

export default MetricCardAcc
