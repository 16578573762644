import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import React from 'react'

// Predict record type
function TableRecord({attribute, value, handleImageClick}) {

    // this is define record type
    let data = value;

    //it can be image column data
    if(attribute.toLowerCase() === "image") {
        data = attribute && <img src='/images/copilot/image_icon.png' alt='Pic' onClick={()=> handleImageClick(value)}/>
    }

    //it can be a pass/fail column data
    else if(attribute.toLowerCase() === "status") {
        data = setStatus(value)
    }
    //any other column data
    else{
        data = value;
    }

// It is to make pass or fail status
function setStatus(status) {
    if(status.value.toLowerCase() === "pass") {
        return <a href={status.url} target='_blank' rel="noreferrer" style={{textDecoration:"none", color:"green"}}><CheckCircleOutline sx={{color: "green"}}/> Pass</a>;
    }
    else if(status.value.toLowerCase() === "fail") {
        return <a href={status.url} target='_blank' rel="noreferrer" style={{textDecoration:"none", color:"red"}}><CancelOutlined sx={{color: "red"}}/> Fail</a>;
    }
    else{
        return <a href={status.url} target='_blank' rel="noreferrer" style={{textDecoration:"none", color:"var(--text-clr"}}>{status.value}</a>;
    }
}

  return (
    <td style={{maxWidth:"50%"}}>
        {data}
    </td>
  )
}

export default TableRecord