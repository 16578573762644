import React from 'react'
import "./SettingSidebar.css"
import { useNavigate } from 'react-router-dom'

function SettingSideBar({tab}) {

    const navigate = useNavigate();

    return (
        <ul className='setting_sidebar'>
            <li onClick={() => navigate("/settings/personal")} style={tab === "1"? {backgroundColor:"var(--secondary-clr)"}:{}}>Personal Information</li>
            <li onClick={() => navigate("/settings/professional")} style={tab === "2"? {backgroundColor:"var(--secondary-clr)"}:{}}>Professional Information</li>
            <li onClick={() => navigate("/settings/geographic")} style={tab === "3"? {backgroundColor:"var(--secondary-clr)"}:{}}>Geographic Details</li>
            <li onClick={() => navigate("/settings/security")} style={tab === "4"? {backgroundColor:"var(--secondary-clr)"}:{}}>Security & Password</li>
            <li onClick={() => navigate("/settings/billing")} style={tab === "5"? {backgroundColor:"var(--secondary-clr)"}:{}}>Billing & Payment details</li>
            <li onClick={() => navigate("/settings/social")} style={tab === "6"? {backgroundColor:"var(--secondary-clr)"}:{}}>Social Media & Website</li>
        </ul>
    )
}

export default SettingSideBar